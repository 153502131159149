import React from "react";
import "./index.scss";

import rpcClientModule from "../../modules/rpcClientModule";

const fbGroups = {
    "BA": "",
    "ME": "",
    "RS": "",
    "HR": ""
}


let promjenama = 'promjenama';
let promjene = 'promjene';
let gdje = 'gdje';
let vježbe = 'vježbe';
let Razmjena = 'Razmjena';
let unaprijediti = 'unaprijediti';
let Savjeti = 'Savjeti';
let unaprjeđenje = 'unaprjeđenje';
let djeleći = 'djeleći';
let savjete = 'savjete';
let rješenja = 'rješenja';
let vrijeme = 'vrijeme';
let cijeloj = 'cijeloj';
let ime1 = '';
let comment1 = "";
let comment2 = "";
let comment3 = "";
let tijelo = "";
let razmijeniti = "";
let mjesto = "";
let savjeti = "";
let dijele = "";
let cjelini = "";
let promijenio = "";
let koljenima = "";
let uspjeti = "";


switch (rpcClientModule.country) {
    case 'BA':
        promjenama = 'promjenama';
        promjene = 'promjene';
        gdje = 'gdje';
        vježbe = 'vježbe';
        Razmjena = 'Razmjena';
        unaprijediti = 'unaprijediti';
        Savjeti = 'Savjeti';
        unaprjeđenje = 'unaprjeđenje';
        djeleći = 'djeleći';
        savjete = 'savjete';
        rješenja = 'rješenja';
        vrijeme = 'vrijeme';
        cijeloj = 'cijeloj';
        ime1 = 'Haris';
        comment1 = "Amir Hodžić";
        comment2 = "Adna Mehić";
        comment3 = "Almir Pašić";
        tijelo = "tijelo";
        razmijeniti = "razmijeniti";
        mjesto = "mjesto";
        savjeti = "savjeti";
        dijele = "dijele";
        cjelini = "cjelini";
        promijenio = "promijenio";
        koljenima = "koljenima";
        uspjeti = "uspjeti";
        break;
    case 'RS':
        promjenama = 'promenama';
        promjene = 'promene';
        gdje = 'gde';
        vježbe = 'vežbe';
        Razmjena = 'Razmena';
        unaprijediti = 'unaprediti';
        Savjeti = 'Saveti';
        unaprjeđenje = 'unapređenje';
        djeleći = 'deleći';
        savjete = 'savete';
        rješenja = 'rešenja';
        vrijeme = 'vreme';
        cijeloj = 'celoj';
        ime1 = 'Marko';
        comment1 = "Nikola Petrović";
        comment2 = "Milana Jovanović";
        comment3 = "Petar Simić";
        tijelo = "telo";
        razmijeniti = "razmeniti";
        mjesto = "mesto";
        savjeti = "saveti";
        dijele = "dele";
        cjelini = "celini";
        promijenio = "promenio";
        koljenima = "kolenima";
        uspjeti = "uspeti";
        break;
    case 'HR':
        promjenama = 'promjenama';
        promjene = 'promjene';
        gdje = 'gdje';
        vježbe = 'vježbe';
        Razmjena = 'Razmjena';
        unaprijediti = 'unaprijediti';
        Savjeti = 'Savjeti';
        unaprjeđenje = 'unaprjeđenje';
        djeleći = 'djeleći';
        savjete = 'savjete';
        rješenja = 'rješenja';
        vrijeme = 'vrijeme';
        cijeloj = 'cijeloj';
        ime1 = 'Ivan';
        comment1 = "Luka Horvat";
        comment2 = "Ivana Marić";
        comment3 = "Karlo Novak";
        tijelo = "tijelo";
        razmijeniti = "razmijeniti";
        mjesto = "mjesto";
        savjeti = "savjeti";
        dijele = "dijele";
        cjelini = "cjelini";
        promijenio = "promijenio";
        koljenima = "koljenima";
        uspjeti = "uspjeti";
        break;
    case 'ME':
        promjenama = 'promjenama';
        promjene = 'promjene';
        gdje = 'gdje';
        vježbe = 'vježbe';
        Razmjena = 'Razmjena';
        unaprijediti = 'unaprijediti';
        Savjeti = 'Savjeti';
        unaprjeđenje = 'unaprjeđenje';
        djeleći = 'djeleći';
        savjete = 'savjete';
        rješenja = 'rješenja';
        vrijeme = 'vrijeme';
        cijeloj = 'cijeloj';
        ime1 = 'Petar';
        comment1 = "Nemanja Vuković";
        comment2 = "Ivana Marić";
        comment3 = "Luka Petrović";
        tijelo = "tijelo";
        razmijeniti = "razmijeniti";
        mjesto = "mjesto";
        savjeti = "savjeti";
        dijele = "dijele";
        cjelini = "cjelini";
        promijenio = "promijenio";
        koljenima = "koljenima";
        uspjeti = "uspjeti";
        break;
    default:
        break;
}


const Community = () => {
    const openGroupPage = () => {
        if (!fbGroups[rpcClientModule.country]) return;

        window.open(fbGroups[rpcClientModule.country], "_blank");
    };

    return <div className="route__community">
        <div className="route__community__banner">
            <img src="/images/Community/banner.png" />
            <div className="route__community__banner__content">
                <img src="/images/logo.svg" className="route__community__banner__content__logo" />
                <p className="route__community__banner__content__text">Zajedno pravimo razliku.</p>
                <p className="route__community__banner__content__head">
                    PRVI KORAK KA 
                    <br></br>
                    <span>BOLJOJ VERZIJI TEBE!</span></p>
                <p className="route__community__banner__content__text">Pridruži se zajednici od preko 45.000 muškaraca koji podižu svoju moć, osnažuju {tijelo} i zdravlje – od potencije do energije, od zdravlja kostiju do poboljšanja svakodnevnog života, zajedno radimo na {promjenama} životnog stila i još mnogo toga</p>

                <button className="route__community__banner__content__button" onClick={openGroupPage}>
                    <img src="/images/Social/facebook_white.png" />
                    <span>PRIDRUŽI SE NAŠOJ ZAJEDNICI</span>
                </button>
            </div>
        </div>

        <div className="route__community__box1">
            <div className="route__community__box1__head">Zajedno kroz zdrave {promjene}. Niste same!</div>
            <div className="route__community__box1__subhead">Za stvaranje zdravih navika potrebna je zajednica.</div>
            <div className="route__community__box1__text">Valnor zajednica stvorena je kao moćan i siguran prostor za muškarce koji žele unaprijediti svaki aspekt svog zdravlja i života. Ovdje možeš pronaći podršku i {razmijeniti} iskustva o ključnim temama poput potencije, prostate, zdravlja srca, ali i energije, oporavka mišića, kao i o ishrani, treninzima i sličnim temama. Naša zajednica nije samo {mjesto} za inspiraciju – to je prostor {gdje} svakodnevno radimo na međusobnoj motivaciji i odgovornosti, podstičući svakog člana da dostigne svoju najbolju verziju u svemu što radi.</div>

            <div className="route__community__box1__benefits">
                <div className="route__community__box1__benefits__left">
                    <img src="/images/Community/box1_left.png" />
                </div>
                <div className="route__community__box1__benefits__right">
                    <p className="route__community__box1__benefits__right__head">Valnor® zajednica nudi:</p>
                    <div className="route__community__box1__benefits__right__items">
                        <div className="route__community__box1__benefits__right__items__item">
                            <img src="/images/Community/box1_right1.svg" />
                            <span>Podrška i motivacija</span>
                        </div>
                        <div className="route__community__box1__benefits__right__items__item">
                            <img src="/images/Community/box1_right2.svg" />
                            <span>Zdravstveni {savjeti}</span>
                        </div>
                        <div className="route__community__box1__benefits__right__items__item">
                            <img src="/images/Community/box1_right3.svg" />
                            <span>Edukacija i informisanje</span>
                        </div>
                        <div className="route__community__box1__benefits__right__items__item">
                            <img src="/images/Community/box1_right4.svg" />
                            <span>Treninzi i {vježbe}</span>
                        </div>
                        <div className="route__community__box1__benefits__right__items__item">
                            <img src="/images/Community/box1_right5.svg" />
                            <span>Recepti i obroci</span>
                        </div>
                        <div className="route__community__box1__benefits__right__items__item">
                            <img src="/images/Community/box1_right6.svg" />
                            <span>{Razmjena} iskustva</span>
                        </div>
                    </div>
                    <p className="route__community__box1__benefits__right__head">+ Neograničenu podršku</p>
                </div>
            </div>
            <button className="route__community__box1__button" onClick={openGroupPage}>
                <img src="/images/Social/facebook_white.png" />
                <span>PRIDRUŽI SE NAŠOJ ZAJEDNICI</span>
            </button>
        </div>

        <div className="route__community__box2">
            <div className="route__community__box2__head">Više od zajednice - Snaga muške grupe</div>
            <div className="route__community__box2__text">Valnor zajednica je siguran prostor za sve muškarce koji žele {unaprijediti} svoje zdravlje. Naša misija je da svakom članu pružimo podršku i inspiraciju za lične transformacije u svim aspektima života.</div>

            <div className="route__community__box2__items">
                <div className="route__community__box2__items__item">
                    <img src="/images/Community/box2_1.svg" />
                    <span>{Savjeti} stručnjaka za<br />{unaprjeđenje} zdravlja</span>
                </div>
                <div className="route__community__box2__items__item">
                    <img src="/images/Community/box2_2.svg" />
                    <span>Poklon proizvodi<br/>iz našeg asortimana</span>
                </div>
                <div className="route__community__box2__items__item">
                    <img src="/images/Community/box2_3.svg" />
                    <span>Podrška i zajednička<br/>proslava napretka</span>
                </div>
            </div>
        </div>

        <div className="route__community__box3">
            <div className="route__community__box3__left">
                <img src="/images/Community/box3_1.png" />
            </div>
            <div className="route__community__box3__right">
                <p className="route__community__box3__right__head">Međusobna podrška članova - {savjeti}, iskustva i {rješenja}</p>
                <p className="route__community__box3__right__text">Valnor zajednica je više od online grupe – to je prostor {gdje} se pričaju muške priče i otvoreno {dijele} iskustva koja se tiču muškog zdravlja, kao što su potencija, prostata, ali i o svakodnevnim aspektima kao što su ishrana, suplementacija i treninzi. Kakva je tvoja rutina i odnos prema zdravlju? Kakva je tvoja mreža podrške? Kroz zajedništvo gradimo zdravlje u {cjelini}, od fizičkog blagostanja do međusobnih odnosa i pozitivnih navika. Na kraju, svi smo dio jedne velike Valnor porodice.</p>
                <p className="route__community__box3__right__last">Zajedno smo jači – {vrijeme} je da postaneš najbolja verzija sebe!</p>
            </div>
        </div>

        <div className="route__community__box4">
            <div className="route__community__box4__wrap">
                <div className="route__community__box4__wrap__left">
                    <img src="/images/Community/box4_1.png" />
                </div>
                <div className="route__community__box4__wrap__right">
                    <p className="route__community__box4__wrap__right__text">„Hvala Valnor zajednici! Uz vašu podršku i Joint Ease {promijenio} sam svoju svakodnevicu. Sada mogu normalno hodati bez bolova u {koljenima}, a sve što sam naučio kroz Valnor mi daje snagu da nastavim raditi na svom zdravlju.”</p>
                    <p className="route__community__box4__wrap__right__last">{ime1} J. | članica Valnor zajednice</p>
                </div>
            </div>
        </div>

        <div className="route__community__box5">
            <div className="route__community__box5__item">
                <img src="/images/Community/user1.png" />
                <div className="route__community__box5__item__box">
                    <span>{comment1}</span>
                    <p>Bravo, znao sam da ćeš {uspjeti}</p>
                    <div className="route__community__box5__item__like">
                        <div className="route__community__box5__item__like__heart">
                            <img src="/images/Community/heart.svg" />
                        </div>
                        <span>3</span>
                    </div>
                </div>
            </div>
            <div className="route__community__box5__item">
                <img src="/images/Community/user2.png" />
                <div className="route__community__box5__item__box">
                    <span>{comment2}</span>
                    <p>Fasciniran sam tvojim napretkom u tako kratkom vremenu</p>
                    <div className="route__community__box5__item__like">
                        <div className="route__community__box5__item__like__heart">
                            <img src="/images/Community/heart.svg" />
                        </div>
                        <span>1</span>
                    </div>
                </div>
            </div>
            <div className="route__community__box5__item">
                <img src="/images/Community/user3.png" />
                <div className="route__community__box5__item__box">
                    <span>{comment3}</span>
                    <p>Bravo {ime1}, pratim te i nadam se da ću i ja {uspjeti} na tom putu</p>
                    <div className="route__community__box5__item__like">
                        <div className="route__community__box5__item__like__heart">
                            <img src="/images/Community/heart.svg" />
                        </div>
                        <span>1</span>
                    </div>
                </div>
            </div>
        </div>

        <div className="route__community__box6">
            <p className="route__community__box6__head">Članovi Valnor zajednice</p>
            <div className="route__community__box6__images">
                <img src="/images/Community/box6_1.png" />
                <img src="/images/Community/box6_2.png" />
                <img src="/images/Community/box6_3.png" />
                <img src="/images/Community/box6_4.png" />
            </div>
        </div>

        <button className="route__community__finalButton" onClick={openGroupPage}>
            <img src="/images/Social/facebook_white.png" />
            <span>PRIDRUŽI SE NAŠOJ ZAJEDNICI</span>
        </button>
    </div>
};

export default Community;