import React from 'react';
import "./index.scss";

import rpcClientModule from '../../../modules/rpcClientModule';
import Spinner from '../../customComponents/Spinner';


const SuccessfullRegisterModal = (props) => {
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <p>{rpcClientModule.country === "RS" ? 'Uspešno' : 'Uspješno'} ste se registrovali na Valnor Shop</p>
                {/* <button onClick={() => {props.animateNavigate("/"); props.onClose()}}>Nastavi sa kupovinom</button> */}
                <button onClick={() => {props.onClose()}}>Nastavi sa kupovinom</button>
            </div>
        </div>
    );
};

export default SuccessfullRegisterModal;