import { HashRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import React from "react";

import rpcClient from "./modules/rpcClientModule";

import Header from "./components/Header";
import ContentWrapper from "./components/ContentWrapper";
import Footer from "./components/Footer";
import * as cartActions from "./actions/cartActions"

import * as userDataActions from "./actions/userDataActions";
import animateModule from "./modules/animateModule";

import LandingPage from "./routes/LandingPage";
import Login from "./routes/Login";
import Account from "./routes/Account";
import ContactUs from "./routes/ContactUs";
import AboutUs from "./routes/AboutUs";
import OurStory from "./routes/OurStory";
import ShippmentPolicy from "./routes/ShippmentPolicy";
import RefundPolicy from "./routes/RefundPolicy";
import Offer from "./routes/Offer";
import MostPopular from "./routes/MostPopular";
import TrackYourShipment from "./routes/TrackYourShipment";
import Quiz from "./routes/Quiz";
import Checkout from "./routes/Checkout";
import Thankyou from "./routes/Checkout/Thankyou";

import PrivacyPolicy from "./routes/PrivacyPolicy";
import TermsOfUse from "./routes/TermsOfUse";
import Bundles from "./routes/Bundles";
import BlogMršavljenje from "./routes/BlogMršavljenje";
import BlogKosa from "./routes/BlogKosa";
import BlogLibido from "./routes/BlogLibido";
import BlogGeneralno from "./routes/BlogGeneralno";

import ClubAccess from "./routes/ClubAccess";
import Community from "./routes/Community";
import ResetPassword from "./routes/ResetPassword";

/* import EmailPromptModal from "./components/Modals/EmailPromptModal"; */

import { useDispatch, useSelector } from "react-redux";
import { animateBox } from "./modules/componentAnimation";

/* let firstTime = true; */
let loginTimeout = null;
const App = () => {

  let dispatch = useDispatch();
  const mainNavigate = useNavigate();

  const userDataSelector = useSelector(state => state?.userData ?? {});
  const timestampSelector = useSelector(state => state?.timestamp ?? null);


  const animateNavigate = to => {
    animateModule(mainNavigate, to, document.querySelector(".component__contentWrapper"));
  };

  React.useEffect(() => {
    const storedCart = localStorage.getItem('cart');
    if (storedCart) {
      dispatch(cartActions.updateCart(JSON.parse(storedCart)))
    }
  }, [dispatch]);


  const checkLogin = () => {
    rpcClient({
      action: "call",
      method: "auth.checkLogin"
    }).then(res => {

      if (res.status === "error" && res.data === "RPC_CLIENT_ERROR") {
        dispatch(userDataActions.setUserData(null, null));

        loginTimeout = setTimeout(checkLogin, 2000);
        return
      }

      if (res.status === "ok") {

        /* if(firstTime && !res?.data?.UserInfo?.Email){
          firstTime = false;
          animateBox(<EmailPromptModal />)
        } */

        dispatch(userDataActions.setUserData(true, res.data));
        if (!userDataSelector?.isLoggedIn) {
          if (window.location.toString().endsWith("/login")) animateNavigate("/");
        };

        loginTimeout = setTimeout(checkLogin, 60000);
      } else {
        // if (!userDataSelector?.isLoggedIn) {
        //   if (!window.location.toString().endsWith("/login")) animateNavigate("/login");
        // };
        dispatch(userDataActions.setUserData(false, null));
      };
    }).catch(() => {
      dispatch(userDataActions.setUserData(null, null));

      loginTimeout = setTimeout(checkLogin, 2000);
    })
  };

  React.useEffect(() => {
    checkLogin();

    return () => clearTimeout(loginTimeout);
  }, [timestampSelector]);

  React.useEffect(() => {
    var _mtm = window._mtm = window._mtm || [];
    _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src=`
    ${rpcClient.country === 'RS' ? 'https://cdn.matomo.cloud/obscale.matomo.cloud/container_6sCfkopE.js' : 
      rpcClient.country === 'BA' ? 'https://cdn.matomo.cloud/obscale.matomo.cloud/container_BYxneWoN.js' :
      rpcClient.country === 'HR' ? 'https://cdn.matomo.cloud/obscale.matomo.cloud/container_ZY8cDxNB.js' :
      rpcClient.country === 'ME' ? 'https://cdn.matomo.cloud/obscale.matomo.cloud/container_6YfhW3ew.js' : ''
    }
    `; s.parentNode.insertBefore(g,s);
  }, [])


  return <>
    <Header />
    <ContentWrapper>
      <Routes>

        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/account" element={<Account />} />
        <Route path="offers/:slog" element={<Offer />} />

        <Route path="/most-popular" element={<MostPopular />} />
        <Route path="/shop/:group" element={<MostPopular />} />

        <Route path="/quiz" element={<Quiz />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/checkout/:id" element={<Thankyou />} />

        <Route path="/kontaktiraje-nas" element={<ContactUs />} />
        <Route path="/o-nama" element={<AboutUs />} />
        <Route path="/politika-dostave" element={<ShippmentPolicy />} />
        <Route path="/politika-povrata" element={<RefundPolicy />} />
        <Route path="/nasa-prica" element={<OurStory />} />

        <Route path="/track" element={<TrackYourShipment />} />

        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/bundles" element={<Bundles />} />
        <Route path="/blog/prostata" element={<BlogMršavljenje />} />
        <Route path="/blog/potencija" element={<BlogKosa />} />
        <Route path="/blog/povećanje" element={<BlogLibido />} />
        <Route path="/blog/zdravlje" element={<BlogGeneralno />} />

        <Route path="/club" element={<ClubAccess />} />
        <Route path="/community" element={<Community />} />
        <Route path="/forgotPassword" element={<ResetPassword />} />


      </Routes>
    </ContentWrapper>
    <Footer />
  </>
};

export default App;
