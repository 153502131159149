import React from "react";
import "./index.scss";

import rpcClientModule from "../../modules/rpcClientModule";

let koristenja, mail, phone, address, page, text1, text2, list1, text3, text4, text5, title1, text6, text7;

switch (rpcClientModule.country) {
    case 'BA':
        koristenja = 'korištenja';
        mail = 'podrska@valnor.ba';
        phone = '+387 35 365 130';
        address = 'Ćehaje bb, 75350 Srebrenik, Bosna i Hercegovina';
        page = `Valnor.ba`;
        text1 = ` Dobrodošli na web stranicu ${page} (u daljem tekstu „Sajt“). Korišćenjem našeg Sajta, saglasni ste sa uslovima korišćenja definisanim u ovom dokumentu („Uslovi korišćenja“). Ovi uslovi predstavljaju pravno obavezujući ugovor između vas („korisnik“) i kompanije Green Side d.o.o., sa sjedištem u Bosni i Hercegovini. `;
        text2 = `Pristupom i korišćenjem Sajta prihvatate sljedeće uslove: `;
        list1 = `Korisnik se obavezuje da koristi Sajt u skladu sa svim primjeljivim zakonima i regulativama Bosne i Hercegovine`;
        text3 = ` Neki dijelovi Sajta mogu zahtijevati registraciju i kreiranje korisničkog naloga. Korisnik je odgovoran za tačnost i potpunost informacija dostavljenih prilikom registracije. `;
        text4 = `Green Side d.o.o. ne snosi odgovornost za bilo kakve direktne, indirektne, slučajne ili posljedične štete nastale korišćenjem Sajta ili nesposobnošću korišćenja istog. Informacije na Sajtu su pružene „kakve jesu“ i „kako su dostupne“ bez ikakvih garancija. Takođe, Green Side d.o.o. ne preuzima odgovornost za rad posredničkog call centra koji obrađuje vaše narudžbine. Call centar je odgovoran za tačnost podataka i uslugu prema korisnicima, a Green Side d.o.o. nije odgovoran za greške, kašnjenja ili bilo kakve nepravilnosti koje mogu nastati tokom njihove obrade podataka ili komunikacije sa korisnicima.`;
        text5 = `Naša Politika privatnosti opisuje kako prikupljamo, koristimo i dijelimo vaše lične podatke. Pristupanjem Sajtu, prihvatate i našu Politiku privatnosti.`;
        title1 = `6. Promjene uslova korišćenja`;
        text6 = `Green Side d.o.o. zadržava pravo izmjene Uslova korišćenja u bilo kojem trenutku bez prethodne najave. Ažurirane verzije uslova će biti objavljene na Sajtu i stupaju na snagu odmah po objavljivanju. `;
        text7 = ` Ovi uslovi korišćenja uređuju se i tumače u skladu sa zakonima BiH. Svi sporovi koji proizađu iz ovih Uslova biće rješavani pred nadležnim sudovima u Bosni i Hercegovini. `;
        break;
    case 'RS':
        koristenja = 'korišćenja';
        mail = 'podrska@valnor.rs';
        phone = '+382 257 150 310';
        address = 'Loznica, Marije Bursać 6, Srbija.';
        page = `Valnor.rs`;
        text1 = ` Dobrodošli na web stranicu ${page} (u daljem tekstu „Sajt“). Korišćenjem našeg Sajta, saglasni ste sa uslovima korišćenja definisanim u ovom dokumentu („Uslovi korišćenja“). Ovi uslovi predstavljaju pravno obavezujući ugovor između vas („korisnik“) i kompanije Green Side d.o.o., sa sedištem u Srbiji.`;
        text2 = `Pristupom i korišćenjem Sajta prihvatate sledeće uslove: `;
        list1 = `Korisnik se obavezuje da koristi Sajt u skladu sa svim primeljivim zakonima i regulativama Srbije `;
        text3 = ` Neki delovi Sajta mogu zahtevati registraciju i kreiranje korisničkog naloga. Korisnik je odgovoran za tačnost i potpunost informacija dostavljenih prilikom registracije. `;
        text4 = `Green Side d.o.o. ne snosi odgovornost za bilo kakve direktne, indirektne, slučajne ili posledične štete nastale korišćenjem Sajta ili nesposobnošću korišćenja istog. Informacije na Sajtu su pružene „kakve jesu“ i „kako su dostupne“ bez ikakvih garancija. Takođe, Green Side d.o.o. ne preuzima odgovornost za rad posredničkog call centra koji obrađuje vaše narudžbine. Call centar je odgovoran za tačnost podataka i uslugu prema korisnicima, a Green Side d.o.o. nije odgovoran za greške, kašnjenja ili bilo kakve nepravilnosti koje mogu nastati tokom njihove obrade podataka ili komunikacije sa korisnicima.`;
        text5 = `Naša Politika privatnosti opisuje kako prikupljamo, koristimo i delimo vaše lične podatke. Pristupanjem Sajtu, prihvatate i našu Politiku privatnosti.`;
        title1 = `6. Promene uslova korišćenja`;
        text6 = `Green Side d.o.o. zadržava pravo izmene Uslova korišćenja u bilo kom trenutku bez prethodne najave. Ažurirane verzije uslova će biti objavljene na Sajtu i stupaju na snagu odmah po objavljivanju. `;
        text7 = ` Ovi uslovi korišćenja uređuju se i tumače u skladu sa zakonima Srbije. Svi sporovi koji proizađu iz ovih Uslova biće rešavani pred nadležnim sudovima u Srbiji. `;

        break;
    case 'HR':
        koristenja = 'korištenja';
        mail = 'podrska@valnor.hr';
        phone = '+385 32 373 309';
        address = 'Županja, Trg prof. Martina Robotića 2/2, Županja, Hrvatska';
        page = `Valnor.hr`;
        text1 = ` Dobrodošli na web stranicu ${page} (u daljem tekstu „Sajt“). Korišćenjem našeg Sajta, saglasni ste sa uslovima korišćenja definisanim u ovom dokumentu („Uslovi korišćenja“). Ovi uslovi predstavljaju pravno obavezujući ugovor između vas („korisnik“) i kompanije Green Side d.o.o., sa sjedištem u Hrvatskoj.`;
        text2 = `Pristupom i korišćenjem Sajta prihvatate sljedeće uslove: `;
        list1 = `Korisnik se obavezuje da koristi Sajt u skladu sa svim primjeljivim zakonima i regulativama Hrvatske`;
        text3 = ` Neki dijelovi Sajta mogu zahtijevati registraciju i kreiranje korisničkog naloga. Korisnik je odgovoran za tačnost i potpunost informacija dostavljenih prilikom registracije. `;
        text4 = `Green Side d.o.o. ne snosi odgovornost za bilo kakve direktne, indirektne, slučajne ili posljedične štete nastale korišćenjem Sajta ili nesposobnošću korišćenja istog. Informacije na Sajtu su pružene „kakve jesu“ i „kako su dostupne“ bez ikakvih garancija. Takođe, Green Side d.o.o. ne preuzima odgovornost za rad posredničkog call centra koji obrađuje vaše narudžbine. Call centar je odgovoran za tačnost podataka i uslugu prema korisnicima, a Green Side d.o.o. nije odgovoran za greške, kašnjenja ili bilo kakve nepravilnosti koje mogu nastati tokom njihove obrade podataka ili komunikacije sa korisnicima.`;
        text5 = `Naša Politika privatnosti opisuje kako prikupljamo, koristimo i dijelimo vaše lične podatke. Pristupanjem Sajtu, prihvatate i našu Politiku privatnosti.`;
        title1 = `6. Promjene uslova korišćenja`;
        text6 = `Green Side d.o.o. zadržava pravo izmjene Uslova korišćenja u bilo kojem trenutku bez prethodne najave. Ažurirane verzije uslova će biti objavljene na Sajtu i stupaju na snagu odmah po objavljivanju. `;
        text7 = ` Ovi uslovi korišćenja uređuju se i tumače u skladu sa zakonima Hrvatske. Svi sporovi koji proizađu iz ovih Uslova biće rješavani pred nadležnim sudovima u Hrvatske. `;

        break;
    case 'ME':
        koristenja = 'korištenja';
        mail = 'podrska@valnor.me';
        phone = '+382 204 144 70';
        address = 'UL. Admirala Zmajevića br. 81, Stari Aerodrom, Podgorica';
        page = `Valnor.me`;
        text1 = ` Dobrodošli na web stranicu ${page} (u daljem tekstu „Sajt“). Korišćenjem našeg Sajta, saglasni ste sa uslovima korišćenja definisanim u ovom dokumentu („Uslovi korišćenja“). Ovi uslovi predstavljaju pravno obavezujući ugovor između vas („korisnik“) i kompanije Green Side d.o.o., sa sjedištem u Crnoj Gori.`;
        text2 = `Pristupom i korišćenjem Sajta prihvatate sljedeće uslove: `;
        list1 = `Korisnik se obavezuje da koristi Sajt u skladu sa svim primjeljivim zakonima i regulativama Crne Gore`;
        text3 = ` Neki dijelovi Sajta mogu zahtijevati registraciju i kreiranje korisničkog naloga. Korisnik je odgovoran za tačnost i potpunost informacija dostavljenih prilikom registracije. `;
        text4 = `Green Side d.o.o. ne snosi odgovornost za bilo kakve direktne, indirektne, slučajne ili posljedične štete nastale korišćenjem Sajta ili nesposobnošću korišćenja istog. Informacije na Sajtu su pružene „kakve jesu“ i „kako su dostupne“ bez ikakvih garancija. Takođe, Green Side d.o.o. ne preuzima odgovornost za rad posredničkog call centra koji obrađuje vaše narudžbine. Call centar je odgovoran za tačnost podataka i uslugu prema korisnicima, a Green Side d.o.o. nije odgovoran za greške, kašnjenja ili bilo kakve nepravilnosti koje mogu nastati tokom njihove obrade podataka ili komunikacije sa korisnicima.`;
        title1 = `6. Promjene uslova korišćenja`;
        text6 = `Green Side d.o.o. zadržava pravo izmjene Uslova korišćenja u bilo kojem trenutku bez prethodne najave. Ažurirane verzije uslova će biti objavljene na Sajtu i stupaju na snagu odmah po objavljivanju. `;
        text7 = ` Ovi uslovi korišćenja uređuju se i tumače u skladu sa zakonima Crne Gore. Svi sporovi koji proizađu iz ovih Uslova biće rješavani pred nadležnim sudovima u Srbiji. `;

        break;
    default:
        mail = 'podrska@Valnor.ba';
        phone = '+387 35 365 130';
        address = 'Ćehaje bb, 75350 Srebrenik, Bosna i Hercegovina';
        page = `Valnor.ba`;
        text1 = ` Dobrodošli na web stranicu ${page} (u daljem tekstu „Sajt“). Korišćenjem našeg Sajta, saglasni ste sa uslovima korišćenja definisanim u ovom dokumentu („Uslovi korišćenja“). Ovi uslovi predstavljaju pravno obavezujući ugovor između vas („korisnik“) i kompanije Green Side d.o.o., sa sjedištem u Bosni i Hercegovini. `;
        text2 = `Pristupom i korišćenjem Sajta prihvatate sljedeće uslove: `;
        list1 = `Korisnik se obavezuje da koristi Sajt u skladu sa svim primjeljivim zakonima i regulativama Bosne i Hercegovine`;
        text3 = ` Neki dijelovi Sajta mogu zahtijevati registraciju i kreiranje korisničkog naloga. Korisnik je odgovoran za tačnost i potpunost informacija dostavljenih prilikom registracije. `;
        text4 = `Green Side d.o.o. ne snosi odgovornost za bilo kakve direktne, indirektne, slučajne ili posljedične štete nastale korišćenjem Sajta ili nesposobnošću korišćenja istog. Informacije na Sajtu su pružene „kakve jesu“ i „kako su dostupne“ bez ikakvih garancija. Takođe, Green Side d.o.o. ne preuzima odgovornost za rad posredničkog call centra koji obrađuje vaše narudžbine. Call centar je odgovoran za tačnost podataka i uslugu prema korisnicima, a Green Side d.o.o. nije odgovoran za greške, kašnjenja ili bilo kakve nepravilnosti koje mogu nastati tokom njihove obrade podataka ili komunikacije sa korisnicima.`;
        text5 = `Naša Politika privatnosti opisuje kako prikupljamo, koristimo i dijelimo vaše lične podatke. Pristupanjem Sajtu, prihvatate i našu Politiku privatnosti.`;
        title1 = `6. Promjene uslova korišćenja`;
        text6 = `Green Side d.o.o. zadržava pravo izmjene Uslova korišćenja u bilo kojem trenutku bez prethodne najave. Ažurirane verzije uslova će biti objavljene na Sajtu i stupaju na snagu odmah po objavljivanju. `;
        text7 = ` Ovi uslovi korišćenja uređuju se i tumače u skladu sa zakonima BiH. Svi sporovi koji proizađu iz ovih Uslova biće rješavani pred nadležnim sudovima u Bosni i Hercegovini. `;

        break;
};

const TermsOfUse = () => {
    return <div className="route__termsOfUse">
        <h2 className="route__termsOfUse__title">Uslovi {koristenja} - Valnor</h2>
        <h4 className="route__termsOfUse__subTitle">1. Uvod</h4>
        <p className="route__termsOfUse__text mb20">{text1}</p>
        <h4 className="route__termsOfUse__subTitle">Informacije o kompaniji</h4>
        <ul className="route__termsOfUse__list mb20">
            <li><b>Naziv kompanije:</b> Green Side d.o.o.</li>
            <li><b>Adresa:</b> {address}</li>
            <li><b>Email za kontakt:</b> {mail}</li>
        </ul>
        <h4 className="route__termsOfUse__subTitle">2. Uslovi korišćenja Sajta</h4>
        <p className="route__termsOfUse__text">{text2}</p>
        <ul className="route__termsOfUse__list mb20">
            <li>{list1}</li>
            <li>Sajt i svi materijali na njemu zaštićeni su pravima intelektualne svojine. Zabranjeno je kopiranje, distribucija ili bilo kakvo drugo korišćenje sadržaja bez prethodne pismene dozvole. </li>
        </ul>
        <h4 className="route__termsOfUse__subTitle">3. Registracija i korisnički nalozi</h4>
        <p className="route__termsOfUse__text mb20">{text3}</p>
        <h4 className="route__termsOfUse__subTitle">4. Ograničenje odgovornosti</h4>
        <p className="route__termsOfUse__text mb20">{text4}</p>
        <h4 className="route__termsOfUse__subTitle">5. Zaštita privatnosti</h4>
        <p className="route__termsOfUse__text mb20">{text5}</p>
        <h4 className="route__termsOfUse__subTitle">{title1}</h4>
        <p className="route__termsOfUse__text mb20">{text6}</p>
        <h4 className="route__termsOfUse__subTitle">7. Pravo i nadležnost</h4>
        <p className="route__termsOfUse__text mb20">{text7}</p>
        <h4 className="route__termsOfUse__subTitle">8. Kontakt informacije</h4>
        <p className="route__termsOfUse__text">Za sva pitanja u vezi sa ovim Uslovima korišćenja, molimo vas da nas kontaktirate putem e-mail adrese: {mail}</p>
    </div>
};

export default TermsOfUse;