import React from "react";
import "./index.scss";

import rpcClientModule from "../../modules/rpcClientModule";
import { useLocation } from 'react-router-dom';
import Spinner from "../../components/customComponents/Spinner";
import { useDispatch, useSelector } from "react-redux";
import * as cartActions from "../../actions/cartActions";
import PhoneInput from "react-phone-input-2";
import { countryPrefixes } from "../../modules/countryModule";
import { useNavigate } from "react-router-dom";
import animateModule from "../../modules/animateModule";
import * as timestmapActions from "../../actions/timestampActions";

let provjerite = 'provjerite';

switch (rpcClientModule.country) {
    case 'BA':
        provjerite = 'provjerite';
        break;
    case 'RS':
        provjerite = 'proverite';
        break;
    case 'HR':
        provjerite = 'provjerite';
        break;
    case 'ME':
        provjerite = 'provjerite';
        break;
    default:
        break;
}


const Checkout = () => {
    const [offers, setOffers] = React.useState();
    const cartData = useSelector((state) => state.cartReducer.cart);
    const userSelector = useSelector((state) => state.userData);
    const [phoneNumber, setPhoneNumber] = React.useState();
    const [spinner, setSpinner] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState();

    const loginUsernameRef = React.useRef();
    const loginPasswordRef = React.useRef();

    const usernameRef = React.useRef();
    const passwordRef = React.useRef();
    const nameRef = React.useRef();
    const lastNameRef = React.useRef();
    // const emailRef = React.useRef();

    let dispatch = useDispatch();


    const curNavigate = useNavigate();
    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    React.useEffect(() => {
        if (userSelector?.userData?.UserInfo?.PhoneNumber) {
            setPhoneNumber(userSelector.userData.UserInfo?.PhoneNumber);
        }
    }, [userSelector])


    React.useEffect(() => {
        rpcClientModule({
            action: "call",
            method: "offers.f.getAll",
            callback: res => {
                if (res.status === "ok") {
                    setOffers(res.data);
                }
            }
        })
    }, []);
    const makeOrder = () => {
        const articles = cartData.reduce((accumulator, current) => {
            const existingItem = accumulator.find(item => item.ID === current.ID);

            if (existingItem) {
                existingItem.Quantity += Number(current.quantity);
            } else {
                const newItem = { ...current, Quantity: Number(current.quantity) };
                delete newItem.quantity;

                accumulator.push(newItem);
            }
            return accumulator;
        }, []);
        let data = {
            Articles: articles,
            FirstName: nameRef.current.value,
            LastName: lastNameRef.current.value,
            PhoneNumber: phoneNumber,
            // Email: emailRef.current.value
        };
        if (data.Articles.length === 0) return setErrorMsg("Nema artikala za narudžbu.");
        if (!data.FirstName) return setErrorMsg("Ime ne može biti prazno.");
        if (!data.LastName) return setErrorMsg("Prezime ne može biti prazno.");
        if (!data.PhoneNumber) return setErrorMsg("Broj telefona ne može biti prazan.");
        setSpinner(true);

        //userSelector?.userData?.UserInfo?.PhoneNumber

        let tmpUser = {};
        if (data.FirstName && !userSelector?.userData?.UserInfo?.FirstName) tmpUser.FirstName = data.FirstName;
        if (data.LastName && !userSelector?.userData?.UserInfo?.LastName) tmpUser.LastName = data.LastName;
        if (data.PhoneNumber && !userSelector?.userData?.UserInfo?.PhoneNumber) tmpUser.FirstName = data.PhoneNumber;
        if (data.Email && !userSelector?.userData?.UserInfo?.Email) tmpUser.Email = data.Email;

        if (Object.keys(tmpUser).length > 0) {
            rpcClientModule({
                action: "call",
                method: "shopUsers.f.edit",
                params: tmpUser
            })
        }

        rpcClientModule({
            action: "call",
            method: "shopOrders.add",
            params: data,
            callback: res => {
                if (res.status === "ok") {
                    localStorage.setItem('cart', JSON.stringify([]));
                    dispatch(cartActions.updateCart([]));
                    animateNavigate(`/checkout/${res.data}`);
                } else {
                    setErrorMsg("Došlo je do greske prilikom kreiranja narudžbe, molimo pokušajte ponovo kasnije.")
                }
            }
        }).finally(() => {
            setSpinner(false);
        });
    }

    const login = (isRegistered) => {
        setSpinner(true);
        let data = {
            username: loginUsernameRef.current.value,
            password: loginPasswordRef.current.value
        };
        if (isRegistered) {
            data.username = usernameRef.current.value;
            data.password = passwordRef.current.value;
        }

        const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
        if (data.username && !emailRegex.test(data.username)) {
            return setErrorMsg("Email mora biti u odgovarajućem formatu.");
        }
        rpcClientModule({
            action: "call",
            method: "auth.loginShop",
            params: data,
            callback: res => {
                if (res.status === "ok") {
                    dispatch(timestmapActions.updateTimestamp());
                    setSpinner(false)
                } else {
                    setErrorMsg("Email ili lozinka su netačni.")
                }
            }
        }).finally(() => {
            setSpinner(false);
        });
    }

    const register = () => {
        setSpinner(true);
        let data = {
            Username: usernameRef.current.value,
            Password: passwordRef.current.value,
            PhoneNumber: phoneNumber,
            FirstName: nameRef.current.value,
            LastName: lastNameRef.current.value,
            // Email: emailRef.current.value
        };
        rpcClientModule({
            action: "call",
            method: "shopUsers.f.add",
            params: data,
        }).then((res) => {
            if (res.status === "ok") {
                loginUsernameRef.current.value = usernameRef.current.value;
                loginPasswordRef.current.value = passwordRef.current.value;
                setErrorMsg("")
                login();
            }else{
                if(res.data === "EMAIL_EXISTS") setErrorMsg(`Korisnik sa navedenim emailom već postoji`);
                if(res.data === "PHONE_EXISTS") setErrorMsg(`Korisnik sa navedenim brojem telefona već postoji`);
                if(res.data === "USERNAME_EXISTS") setErrorMsg(`Korisnik sa navedenim emailom već postoji`);
                // if(res.data === "USERNAME_SHORT") setErrorMsg(`Korisničko ime mora biti između 8 i 64 karaktera`);
                if(res.data === "PASSWORD_SHORT") setErrorMsg(`Lozinka mora biti između 8 i 64 karaktera`);
            }
            setSpinner(false);
        }).catch(e => {
        })
    }

    return <div className="route__checkout">
        <div className="route__checkout__left">
            {userSelector?.isLoggedIn ? <>

                <label >Ime*</label>
                <input ref={nameRef} defaultValue={userSelector?.userData?.UserInfo?.FirstName} />
                <label>Prezime*</label>
                <input ref={lastNameRef} defaultValue={userSelector?.userData?.UserInfo?.LastName} />
                <label>Broj telefona*</label>
                <div className="route__checkout__left__input">
                    <PhoneInput
                        onlyCountries={['ba', 'rs', 'me', 'hr']}
                        localization={countryPrefixes}
                        country={rpcClientModule.country.toLowerCase()}
                        value={phoneNumber}
                        onChange={phone => setPhoneNumber(phone)}
                    />
                </div>
                {/* <label>E-mail</label>
                <input ref={emailRef} defaultValue={userSelector?.userData?.UserInfo?.Email} /> */}
                {spinner ? <Spinner color="#fd4798" /> : <button onClick={makeOrder}>Završi kupovinu</button>}
                <span style={{ color: "gray" }}>Polja označena sa * su obavezna</span>
            </> : <>
                <p className="route__checkout__left__head">
                    Prijavite se
                </p>
                <label>Email</label>
                <input ref={loginUsernameRef} />
                <label>Lozinka</label>
                <input type="password" ref={loginPasswordRef} />
                {spinner ? <Spinner color="#fd4798" /> : <button onClick={() => {login(false)}}>Prijavi se</button>}
                <p className="route__checkout__left__head">
                    ili kreirajte profil
                </p>
                <label>Email</label>
                <input ref={usernameRef} />
                <label>Lozinka</label>
                <input type="password" ref={passwordRef} />
                <label>Broj telefona</label>
                <div className="route__checkout__left__input">
                    <PhoneInput
                        onlyCountries={['ba', 'rs', 'me', 'hr']}
                        localization={countryPrefixes}
                        country={rpcClientModule.country.toLowerCase()}
                        value={phoneNumber}
                        onChange={phone => setPhoneNumber(phone)}
                    />
                </div>

                <label >Ime</label>
                <input ref={nameRef} />
                <label>Prezime</label>
                <input ref={lastNameRef} />
                {/* <label>E-mail</label>
                <input ref={emailRef} /> */}
                {spinner ? <Spinner color="#fd4798" /> : <button onClick={register}>Kreiraj profil</button>}
            </>}
            <p style={{textAlign: "right"}}>{errorMsg}</p>
        </div>
        <div className="route__checkout__right">
            {cartData && offers && <>
                {cartData.map(item => {
                    let cartItem = offers.find(e => e.ID === item.ID);
                    if (cartItem) return <div className="route__checkout__right__item">
                        <div className="route__checkout__right__item__img">
                            <img src={cartItem.Images[0]} />
                            <p>{item.quantity}</p>
                        </div>
                        <p>{cartItem.Name}</p>
                        <div className="route__checkout__right__item__price">
                            <p>{Number(cartItem.OldPrice).toFixed(2)} {cartItem.Currency}</p>
                            <p>{Number(cartItem.NewPrice).toFixed(2)} {cartItem.Currency}</p>
                        </div>

                    </div>
                })}
                <p className="route__checkout__right__total">
                    <span> <strong>Total:</strong>   ({cartData.length} artikala) </span>
                    <strong>{(() => {
                        let total = 0;
                        let currency;
                        cartData.map((elem, index) => {
                            let cartItem = offers.find(e => e.ID === elem.ID);
                            total += Number(cartItem.NewPrice) * elem.quantity;
                            currency = cartItem.Currency;

                        })
                        return `${Number(total).toFixed(2)} ${currency}`
                    })()}</strong>
                </p>
                <p className="route__checkout__right__savings">
                    <img src="/images/priceTag.svg" />
                    <span> Ukupno uštede: </span>
                    <span>{(() => {
                        let total = 0;
                        let totalOld = 0;
                        let currency;
                        cartData.map((elem, index) => {
                            let cartItem = offers.find(e => e.ID === elem.ID);
                            total += Number(cartItem.NewPrice) * elem.quantity;
                            totalOld += Number(cartItem.OldPrice) * elem.quantity;
                            currency = cartItem.Currency;

                        })
                        return `${Number(Number(totalOld) - Number(total)).toFixed(2)} ${currency}`
                    })()}</span>
                </p>

            </>}
        </div>

    </div>
};



export default Checkout;