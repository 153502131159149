import React from "react";
import "./index.scss";

import rpcClientModule from "../../modules/rpcClientModule";

let uvodHeadline, uvodText, politikaPovrataHeadline, politikaPovrataText, ponovnoSlanjeHeadline, ponovnoSlanjeText, ogranicenjaHeadline;
let ogranicenjaItems = [];

switch (rpcClientModule.country) {
    case 'BA':
        uvodHeadline = 'Uvod';
        uvodText = 'Valnor nudi 90-dnevnu garanciju zadovoljstva od datuma isporuke za sve proizvode, osim odjeće i dodataka (primjeri: kutijice za tablete).';
        politikaPovrataHeadline = '1. Politika Povrata Novca';
        politikaPovrataText = 'Povrat je moguć i za otvorene proizvode u obliku praha ili kapsula. Povrat mora biti prethodno odobren pozivom na broj +387 35 365 130 radnim danima od 9:00 do 18:00. Ako proizvod pošaljete nazad bez prethodnog kontakta, nećemo biti u mogućnosti obraditi povrat. Nakon što povrat bude odobren, Valnor će vam obezbijediti besplatnu etiketu za povrat, koju možete iskoristiti za besplatnu dostavu artikla nazad prema Valnor. Iznos povrata izračunava se kao ukupna vrijednost narudžbe umanjena za 20% naknade za obnavljanje zaliha. Troškovi dostave nisu povratni. Povrat se obrađuje unutar 5-10 radnih dana nakon odobrenja. ';
        ponovnoSlanjeHeadline = '2. Ponovno Slanje';
        ponovnoSlanjeText = 'Ako je artikal oštećen, pošaljite fotografiju oštećenja na podrska@valnor.ba u roku od 7 dana. Ako zamjena nije dostupna, izvršit ćemo povrat ';
        ogranicenjaHeadline = '3. Ograničenja';
        ogranicenjaItems = [
            'Povrati su isključivo na način plaćanja korišten prilikom kupovine.',
            'Dodatni troškovi mogu se primijeniti za ponovna slanja zbog pogrešne adrese ili odsutnosti kupca.',
            'Sve narudžbe označene kao „Dostavljene” s važećim informacijama o praćenju ne mogu biti refundirane ako kupac ne može pronaći artikal. Kupac će morati direktno kontaktirati kurirsku službu kako bi saznao gdje je paket ostavljen.',
            'Narudžbe dostavljene na pogrešnu adresu zbog greške kupca nisu povratne. Ako je narudžba već poslana, a kupac primejti grešku u adresi, Valnor nije odgovorna za preusmjeravanje pošiljke na ispravnu adresu niti za troškove promjene adrese tokom transporta.',
        ]
        break;
    case 'RS':
        uvodHeadline = 'Uvod';
        uvodText = 'Valnor nudi 90-dnevnu garanciju zadovoljstva od datuma isporuke za sve proizvode, osim odeće i dodataka (primeri: kutijice za tablete).';
        politikaPovrataHeadline = '1. Politika Povrata Novca';
        politikaPovrataText = 'Povrat je moguć i za otvorene proizvode u obliku praha ili kapsula. Povrat mora biti prethodno odobren pozivom na broj +381 15 715 0310 radnim danima od 9:00 do 18:00. Ako proizvod pošaljete nazad bez prethodnog kontakta, nećemo biti u mogućnosti obraditi povrat. Nakon što povrat bude odobren, Valnor će vam obezbediti besplatnu etiketu za povrat, koju možete iskoristiti za besplatnu dostavu artikla nazad prema Valnor. Iznos povrata izračunava se kao ukupna vrijednost narudžbe umanjena za 20% naknade za obnavljanje zaliha. Troškovi dostave nisu povratni. Povrat se obrađuje unutar 5-10 radnih dana nakon odobrenja. ';
        ponovnoSlanjeHeadline = '2. Ponovno Slanje';
        ponovnoSlanjeText = 'Ako je artikal oštećen, pošaljite fotografiju oštećenja na podrska@valnor.rs u roku od 7 dana. Ako zamena nije dostupna, izvršit ćemo povrat ';
        ogranicenjaHeadline = '3. Ograničenja';
        ogranicenjaItems = [
            'Povrati su isključivo na način plaćanja korišten prilikom kupovine.',
            'Dodatni troškovi mogu se primeniti za ponovna slanja zbog pogrešne adrese ili odsutnosti kupca.',
            'Sve narudžbe označene kao „Dostavljene” s važećim informacijama o praćenju ne mogu biti refundirane ako kupac ne može pronaći artikal. Kupac će morati direktno kontaktirati kurirsku službu kako bi saznao gdje je paket ostavljen.',
            'Narudžbe dostavljene na pogrešnu adresu zbog greške kupca nisu povratne. Ako je narudžba već poslana, a kupac primeti grešku u adresi, Valnor nije odgovorna za preusmeravanje pošiljke na ispravnu adresu niti za troškove promene adrese tokom transporta.',
        ]
        break;
    case 'HR':
        uvodHeadline = 'Uvod';
        uvodText = 'Valnor nudi 90-dnevnu garanciju zadovoljstva od datuma isporuke za sve proizvode, osim odjeće i dodataka (primjeri: kutijice za tablete).';
        politikaPovrataHeadline = '1. Politika Povrata Novca';
        politikaPovrataText = 'Povrat je moguć i za otvorene proizvode u obliku praha ili kapsula. Povrat mora biti prethodno odobren pozivom na broj +385 32 373 309 radnim danima od 9:00 do 18:00. Ako proizvod pošaljete nazad bez prethodnog kontakta, nećemo biti u mogućnosti obraditi povrat. Nakon što povrat bude odobren, Valnor će vam obezbijediti besplatnu etiketu za povrat, koju možete iskoristiti za besplatnu dostavu artikla nazad prema Valnor. Iznos povrata izračunava se kao ukupna vrijednost narudžbe umanjena za 20% naknade za obnavljanje zaliha. Troškovi dostave nisu povratni. Povrat se obrađuje unutar 5-10 radnih dana nakon odobrenja. ';
        ponovnoSlanjeHeadline = '2. Ponovno Slanje';
        ponovnoSlanjeText = 'Ako je artikal oštećen, pošaljite fotografiju oštećenja na podrska@valnor.hr u roku od 7 dana. Ako zamjena nije dostupna, izvršit ćemo povrat ';
        ogranicenjaHeadline = '3. Ograničenja';
        ogranicenjaItems = [
            'Povrati su isključivo na način plaćanja korišten prilikom kupovine.',
            'Dodatni troškovi mogu se primijeniti za ponovna slanja zbog pogrešne adrese ili odsutnosti kupca.',
            'Sve narudžbe označene kao „Dostavljene” s važećim informacijama o praćenju ne mogu biti refundirane ako kupac ne može pronaći artikal. Kupac će morati direktno kontaktirati kurirsku službu kako bi saznao gdje je paket ostavljen.',
            'Narudžbe dostavljene na pogrešnu adresu zbog greške kupca nisu povratne. Ako je narudžba već poslana, a kupac primejti grešku u adresi, Valnor nije odgovorna za preusmjeravanje pošiljke na ispravnu adresu niti za troškove promjene adrese tokom transporta.',
        ]
        break;
    case 'ME':
        uvodHeadline = 'Uvod';
        uvodText = 'Valnor nudi 90-dnevnu garanciju zadovoljstva od datuma isporuke za sve proizvode, osim odjeće i dodataka (primjeri: kutijice za tablete).';
        politikaPovrataHeadline = '1. Politika Povrata Novca';
        politikaPovrataText = 'Povrat je moguć i za otvorene proizvode u obliku praha ili kapsula. Povrat mora biti prethodno odobren pozivom na broj +382 20 41 4470 radnim danima od 9:00 do 18:00. Ako proizvod pošaljete nazad bez prethodnog kontakta, nećemo biti u mogućnosti obraditi povrat. Nakon što povrat bude odobren, Valnor će vam obezbijediti besplatnu etiketu za povrat, koju možete iskoristiti za besplatnu dostavu artikla nazad prema Valnor. Iznos povrata izračunava se kao ukupna vrijednost narudžbe umanjena za 20% naknade za obnavljanje zaliha. Troškovi dostave nisu povratni. Povrat se obrađuje unutar 5-10 radnih dana nakon odobrenja. ';
        ponovnoSlanjeHeadline = '2. Ponovno Slanje';
        ponovnoSlanjeText = 'Ako je artikal oštećen, pošaljite fotografiju oštećenja na podrska@valnor.me u roku od 7 dana. Ako zamjena nije dostupna, izvršit ćemo povrat ';
        ogranicenjaHeadline = '3. Ograničenja';
        ogranicenjaItems = [
            'Povrati su isključivo na način plaćanja korišten prilikom kupovine.',
            'Dodatni troškovi mogu se primijeniti za ponovna slanja zbog pogrešne adrese ili odsutnosti kupca.',
            'Sve narudžbe označene kao „Dostavljene” s važećim informacijama o praćenju ne mogu biti refundirane ako kupac ne može pronaći artikal. Kupac će morati direktno kontaktirati kurirsku službu kako bi saznao gdje je paket ostavljen.',
            'Narudžbe dostavljene na pogrešnu adresu zbog greške kupca nisu povratne. Ako je narudžba već poslana, a kupac primejti grešku u adresi, Valnor nije odgovorna za preusmjeravanje pošiljke na ispravnu adresu niti za troškove promjene adrese tokom transporta.',
        ]
        break;
    default:
        uvodHeadline = 'Uvod';
        uvodText = 'Valnor nudi 90-dnevnu garanciju zadovoljstva od datuma isporuke za sve proizvode, osim odjeće i dodataka (primjeri: kutijice za tablete).';
        politikaPovrataHeadline = '1. Politika Povrata Novca';
        politikaPovrataText = 'Povrat je moguć i za otvorene proizvode u obliku praha ili kapsula. Povrat mora biti prethodno odobren pozivom na broj +387 35 365 130 radnim danima od 9:00 do 18:00. Ako proizvod pošaljete nazad bez prethodnog kontakta, nećemo biti u mogućnosti obraditi povrat. Nakon što povrat bude odobren, Valnor će vam obezbijediti besplatnu etiketu za povrat, koju možete iskoristiti za besplatnu dostavu artikla nazad prema Valnor. Iznos povrata izračunava se kao ukupna vrijednost narudžbe umanjena za 20% naknade za obnavljanje zaliha. Troškovi dostave nisu povratni. Povrat se obrađuje unutar 5-10 radnih dana nakon odobrenja. ';
        ponovnoSlanjeHeadline = '2. Ponovno Slanje';
        ponovnoSlanjeText = 'Ako je artikal oštećen, pošaljite fotografiju oštećenja na podrska@valnor.rs u roku od 7 dana. Ako zamjena nije dostupna, izvršit ćemo povrat ';
        ogranicenjaHeadline = '3. Ograničenja';
        ogranicenjaItems = [
            'Povrati su isključivo na način plaćanja korišten prilikom kupovine.',
            'Dodatni troškovi mogu se primijeniti za ponovna slanja zbog pogrešne adrese ili odsutnosti kupca.',
            'Sve narudžbe označene kao „Dostavljene” s važećim informacijama o praćenju ne mogu biti refundirane ako kupac ne može pronaći artikal. Kupac će morati direktno kontaktirati kurirsku službu kako bi saznao gdje je paket ostavljen.',
            'Narudžbe dostavljene na pogrešnu adresu zbog greške kupca nisu povratne. Ako je narudžba već poslana, a kupac primejti grešku u adresi, Valnor nije odgovorna za preusmjeravanje pošiljke na ispravnu adresu niti za troškove promjene adrese tokom transporta.',
        ]
        break;
}

const RefundPolicy = () => {

    return <div className="route__refundPolicy">
        <p className="route__refundPolicy__headline">Politika povrata novca - Valnor</p>
        <p className="route__refundPolicy__subheadline">{uvodHeadline}</p>
        <p className="route__refundPolicy__text">{uvodText}</p>
        <p className="route__refundPolicy__subheadline">{politikaPovrataHeadline}</p>
        <p className="route__refundPolicy__text">{politikaPovrataText}</p>
        <p className="route__refundPolicy__subheadline">{ponovnoSlanjeHeadline}</p>
        <p className="route__refundPolicy__text">{ponovnoSlanjeText}</p>
        <p className="route__refundPolicy__subheadline">{ogranicenjaHeadline}</p>
        <ul>
            {ogranicenjaItems.map(el => {
                return <li>{el}</li>
            })}
        </ul>
    </div>
}

export default RefundPolicy