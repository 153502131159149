import { combineReducers } from "redux";

import timestampReducer from "./timestampReducer";
import userDataReducer from "./userDataReducer";

import cartReducer from "./cartReducer";


export default combineReducers({
    timestamp: timestampReducer,
    userData: userDataReducer,
    cartReducer: cartReducer
});