import React from "react";
import "./index.scss";

import { useParams, useNavigate } from "react-router-dom";
import rpcClientModule from "../../modules/rpcClientModule";
import { groups_images } from "../../modules/miscModule";
import animateBox from "../../modules/animateModule";

import Spinner from "../../components/customComponents/Spinner";

const MostPopular = () => {
    const curParams = useParams();
    const curNavigate = useNavigate();

    const [offers, setOffers] = React.useState();
    const [selectedGroup, setSelectedGroup] = React.useState();

    const animateNavigate = to => animateBox(curNavigate, to, document.querySelector(".component__contentWrapper"));

    const getOfferGroups = () => {
        let out = [];
        if (offers?.status !== "ok") return out;

        for (let o of offers.data) {
            if (!out.includes(o.Group)) out.push(o.Group);
        };

        return out;
    };

    const getOffersForGroup = g => {
        let out = [];
        if (offers?.status !== "ok") return out;
        if (!g) return offers.data;

        for (let o of offers.data) {
            if (o.Group === g) out.push(o);
        };
        return out;
    };

    const selectGroup = (g) => {
        if (selectGroup === g) return;
        let tmp = [];
        for (let item of document.querySelectorAll(".route__mostPopular__right__item")) {
            tmp.push(new Promise(r => {
                item.animate([
                    {opacity: getComputedStyle(item).opacity},
                    {opacity: 0}
                ], {duration: 200, iterations: 1, fill: "both", easing: "ease"}).onfinish = r;
            }));
        };

        Promise.allSettled(tmp).then(() => setSelectedGroup(g));
    };

    React.useEffect(() => {
        setSelectedGroup(curParams?.group ?? undefined);
    }, [curParams?.group]);
    React.useEffect(() => {
        rpcClientModule({
            action: "call",
            method: "offers.f.getAll",
            args: {
                filters: [
                    {name: "ChildOffers", op: "eq", value: null}
                ],
                includeReviews: true
            },
            callback: setOffers
        });
    }, []);

    return <div className="route__mostPopular">
        <div className="route__mostPopular__left">
            <div className="route__mostPopular__left__category route__mostPopular__left__category--first">
                <img src={groups_images["Sve kategorije"]} />
                <span>Sve kategorije</span>
            </div>
            {offers ? <>
                {offers?.status === "ok" && <>
                    <p className={`route__mostPopular__left__category ${!selectedGroup ? "route__mostPopular__left__category--active" : ""}`} onClick={() => selectGroup()}>
                        <img src={groups_images["Najpopularniji"]} />
                        <span>Najpopularniji</span>
                    </p>
                    {getOfferGroups().map(g => <div className={`route__mostPopular__left__category ${selectedGroup === g ? "route__mostPopular__left__category--active" : ""}`} onClick={() => selectGroup(g)}>
                        <img src={groups_images[g] ?? "#"} />
                        <span>{g}</span>
                    </div>)}
                </>}
            </> : <Spinner color="#FD4798" style={{width: "32px", height: "32px"}} />}
        </div>
        <div className="route__mostPopular__right">
            {offers ? (offers?.status === "ok" ? <>
                <p className="route__mostPopular__right__crumb">Shop / {selectedGroup ?? "Najpopularniji"}</p>
                <div className="route__mostPopular__right__group">
                    <img src={groups_images[selectedGroup ?? "Najpopularniji"] ?? "#"} />
                    <span>{selectedGroup ?? "Najpopularniji"}</span>
                </div>
                {getOffersForGroup(selectedGroup).map(art => <div className="route__mostPopular__right__item" key={`${selectedGroup}-${art.ID}`}>
                    <img className="route__mostPopular__right__item__img" src={art.Images[0] ?? "#"} />
                    <p className="route__mostPopular__right__item__name">{art.Name}</p>
                    {art.RatingCount > 0 ? <div className="route__mostPopular__right__item__rating">
                        {(new Array(Math.ceil(art.Rating))).fill(<img src="/images/star.svg" />)}
                        <span>{Number(art.Rating).toFixed(1)}</span>

                        <p>{art.RatingCount} Ocjena</p>
                    </div> : <div className="route__mostPopular__right__item__rating">
                        <span>&nbsp;</span><p>&nbsp;</p>
                    </div>}
                    <p className="route__mostPopular__right__item__price">{Number(art.NewPrice).toFixed(2)} {art.Currency}</p>

                    <div className="route__mostPopular__right__item__button" onClick={() => animateNavigate(`/offers/${art.Slog}`)}>POGLEDAJ PONUDU</div>
                </div>)}
            </> : <p className="route__mostPopular__right__err">Došlo je do greške!</p>) : <Spinner color="#FD4798" style={{width: "32px", height: "32px"}} />}
        </div>
    </div>
};

export default MostPopular;