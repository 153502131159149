import React from "react";
import "./index.scss";
import '@splidejs/react-splide/css';

import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useNavigate } from "react-router-dom";

import rpcClientModule from "../../modules/rpcClientModule";
import animateModule from "../../modules/animateModule";
import * as splideModule from "../../modules/splideModule";


let narudžbi = 'narudžbi';
let rješenje = 'rješenje';
let Osjećajte = 'Osjećajte';
let ljepoti = 'ljepoti';
let tijela = 'tijela';
let lijepe = 'lijepe';
let Njega = 'Njega';
let bezbijedni = 'bezbijedni';
let tijelu = `tijelu`;
let Savjeti = `Savjeti`;

switch (rpcClientModule.country) {
    case 'BA':
        narudžbi = 'narudžbi';
        rješenje = 'rješenje';
        Osjećajte = 'Osjećajte';
        ljepoti = 'ljepoti';
        tijela = 'tijela';
        lijepe = 'lijepe';
        Njega = 'Njega';
        bezbijedni = 'bezbijedni';
        tijelu = `tijelu`;
        Savjeti = `Savjeti`;
        break;
    case 'RS':
        narudžbi = 'porudžbini';
        rješenje = 'rešenje';
        Osjećajte = 'Osećajte';
        ljepoti = 'lepoti';
        tijela = 'tela';
        lijepe = 'lepe';
        Njega = 'Nega';
        bezbijedni = 'bezbedni';
        tijelu = `telu`;
        Savjeti = `Saveti`;
        break;
    case 'HR':
        narudžbi = 'narudžbi';
        rješenje = 'rješenje';
        Osjećajte = 'Osjećajte';
        ljepoti = 'ljepoti';
        tijela = 'tijela';
        lijepe = 'lijepe';
        Njega = 'Njega';
        bezbijedni = 'bezbijedni';
        tijelu = `tijelu`;
        Savjeti = `Savjeti`;
        break;
    case 'ME':
        narudžbi = 'narudžbi';
        rješenje = 'rješenje';
        Osjećajte = 'Osjećajte';
        ljepoti = 'ljepoti';
        tijela = 'tijela';
        lijepe = 'lijepe';
        Njega = 'Njega';
        bezbijedni = 'bezbijedni';
        tijelu = `tijelu`;
        Savjeti = `Savjeti`;
        break;
    default:
        break;
}


const LandingPage = () => {
    const curNavigate = useNavigate();

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    return <div className="route__landingPage">
        <div className="route__landingPage__banner">
            <img src="./images/banner.svg" />
            <div className="route__landingPage__banner__right">
                <p className="route__landingPage__banner__right__first">Snažna akcija</p>
                <p className="route__landingPage__banner__right__second">uštedi do 53%</p>

                <p className="route__landingPage__banner__right__desc">
                    Valnor - prirodna snaga zdravlja.
                    <br></br>
                    Klinički dokazano.
                    <br></br>
                    Pronađi {rješenje} koje odgovara tvojim potrebama
                </p>

                <button className="route__landingPage__banner__right__button" onClick={() => animateNavigate("/most-popular")}>
                    Kliknite ovdje i preuzmi moćnu ponudu
                    <img src="./images/arrowRight.png" alt="" />
                </button>
                
                <span className="route__landingPage__banner__right__footnote">*Rezultati mogu varirati ovisno o individualnim faktorima</span>
            </div>
        </div>

        <div className="route__landingPage__box2">
            <h2 className="route__landingPage__box2__title">
                Zdravlje muškarca. Snaga Valnora
                <span>Ostvari svoje ciljeve na putu ka zdravlju, vitalnosti i snazi!</span>
            </h2>
            <div className="route__landingPage__box2__list">
                <div className="route__landingPage__box2__list__item" onClick={() => animateNavigate(`/offers/alpha-potent`)} style={{background: `url("../images/list1.png") center no-repeat, radial-gradient(62.67% 53.73% at 43% 49.91%, #4E5369 0%, #000000 100%)`}}>
                    <h4>
                        Osnažite,
                        <br />
                        potenciju
                    </h4>
                    <h3>Proizvodi za potenciju</h3>
                </div>
                <div className="route__landingPage__box2__list__item" onClick={() => animateNavigate(`/offers/beauty-fem`)} style={{background: `url("../images/list2.png") center no-repeat, radial-gradient(62.67% 53.73% at 43% 49.91%, #4E5369 0%, #000000 100%)`}}>
                    <h4>
                        Veličina,
                        <br />
                        na tvojoj strani
                    </h4>
                    <h3>Povećanje polnog organa</h3>
                </div>
                <div className="route__landingPage__box2__list__item" onClick={() => animateNavigate(`/offers/prosta-vital`)} style={{background: `url("../images/list3.png") center bottom no-repeat, radial-gradient(62.67% 53.73% at 43% 49.91%, #4E5369 0%, #000000 100%)`}}>
                    <h4>
                        Zdravlje,
                        <br />
                        prostate
                    </h4>
                    <h3>Proizvodi za prostatu</h3>
                </div>
                <div className="route__landingPage__box2__list__item" onClick={() => animateNavigate(`/offers/joint-ease`)} style={{background: `url("../images/list4.png") center no-repeat, radial-gradient(62.67% 53.73% at 43% 49.91%, #4E5369 0%, #000000 100%)`}}>
                    <h4>
                        Kreći se
                        <br />
                        bez ograničenja
                    </h4>
                    <h3>Proizvod za zdravlje kostiju</h3>
                </div>
            </div>
        </div>

        <div className="route__landingPage__box3">
            <h2>Nisi siguran odakle da počneš?</h2>
            <img src="/images/swirl.png" />
            <p>Uradi kviz ispod i saznaj koji su proizvodi pravi za tebe.</p>
            <div onClick={() => animateNavigate("/quiz")}>Uradi kviz</div>
        </div>

        <LandingPage_offers />

        <div className="route__landingPage__community">
            <img src="/images/comunity.png" />
            <div className="route__landingPage__community__text">
                <p className="route__landingPage__community__text__head">Zajednica koja podržava tvoj put ka snazi i zdravlju</p>
                <img className="route__landingPage__community__text__img" src="/images/swirl.png" />
                <p className="route__landingPage__community__text__text">Postani najbolja verzija sebe. Dnevna rutina – prilagođena vašim ciljevima, {tijelu} i načinu života</p>
                <div className="route__landingPage__community__text__button" onClick={() => animateNavigate("/community")}>Saznajte Više</div>
            </div>
        </div>

        <div className="route__landingPage__learn">
            <div className="route__landingPage__learn__wrap">
                <p className="route__landingPage__learn__wrap__head">Valnor lifestyle</p>
                <div className="route__landingPage__learn__wrap__content">
                    <div className="route__landingPage__learn__wrap__content__single" onClick={() => {
                        animateNavigate("/blog/prostata");
                    }}>
                        <div className="route__landingPage__learn__wrap__content__single__img">
                            <img src="/images/learn1.png" />
                        </div>
                        <p className="route__landingPage__learn__wrap__content__single__small">Trening</p>
                        <p className="route__landingPage__learn__wrap__content__single__text">Kako fizička aktivnost utiče na prostatu?</p>
                    </div>

                    <div className="route__landingPage__learn__wrap__content__single" onClick={() => animateNavigate("/blog/potencija")}>
                        <div className="route__landingPage__learn__wrap__content__single__img">
                            <img src="/images/learn2.png" />
                        </div>
                        <p className="route__landingPage__learn__wrap__content__single__small">Ishrana i zdravlje</p>
                        <p className="route__landingPage__learn__wrap__content__single__text">Koji to biljni dodaci mogu poboljšati potenciju?</p>
                    </div>

                    <div className="route__landingPage__learn__wrap__content__single" onClick={() => animateNavigate("/blog/povećanje")}>
                        <div className="route__landingPage__learn__wrap__content__single__img">
                            <img src="/images/learn3.png" />
                        </div>
                        <p className="route__landingPage__learn__wrap__content__single__small">{Savjeti}</p>
                        <p className="route__landingPage__learn__wrap__content__single__text">Top tikovi za povećanje koje će ona pamtiti.</p>
                    </div>

                    <div className="route__landingPage__learn__wrap__content__single" onClick={() => animateNavigate("/blog/zdravlje")}>
                        <div className="route__landingPage__learn__wrap__content__single__img">
                            <img src="/images/learn4.png" />
                        </div>
                        <p className="route__landingPage__learn__wrap__content__single__small">{Njega} {tijela}</p>
                        <p className="route__landingPage__learn__wrap__content__single__text">Tajni recept za jače kosti i zglobove krije se u prirodi.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
};

const LandingPage_offers = props => {
    const [offers, setOffers] = React.useState([]);
    const [selectedGroup, setSelectedGroup] = React.useState();
    const [dropdownOpened, setDropdownOpened] = React.useState(false);

    const isPhone = window.innerWidth >= 800 ? false : true;
    const curNavigate = useNavigate();

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    const getGroups = () => {
        let out = [];
        for (let offer of offers) {
            if (!out.includes(offer.Group)) out.push(offer.Group);
        };

        return out;
    };
    const getArticlesForGroup = g => {
        if (!g) return offers;
        let out = [];

        for (let item of offers) {
            if (item.Group === g) out.push(item);
        };

        return out;
    };

    React.useEffect(() => {
        rpcClientModule({
            action: "call",
            method: "offers.f.getAll",
            args: {
                filters: [
                    {name: "ChildOffers", op: "eq", value: null}
                ]
            },
            callback: res => {
                if (res.status === "ok") setOffers(res.data);
            }
        });
    }, []);

    return <div className="route__landingPage__articles">
        {isPhone && <div className="route__landingPage__articles__top">
            <div className="route__landingPage__articles__top__head">Najprodavaniji</div>
            <div className="route__landingPage__articles__top__dropdown">
                <div className={`route__landingPage__articles__top__dropdown__item ${dropdownOpened ? "route__landingPage__articles__top__dropdown__item--active" : ""}`} onClick={() => setDropdownOpened(d => !d)}>{selectedGroup || "Svi proizvodi"}</div>
                <div className={`route__landingPage__articles__top__dropdown__items ${dropdownOpened ? "route__landingPage__articles__top__dropdown__items--active" : ""}`}>
                    <p onClick={() => {setSelectedGroup(); setDropdownOpened(false);}}>Svi proizvodi</p>
                    {getGroups().map(g => <p onClick={() => {setSelectedGroup(g); setDropdownOpened(false);}}>{g}</p>)}
                </div>
            </div>
            
        </div>}
        {!isPhone && <div className="route__landingPage__articles__top">
            <div className="route__landingPage__articles__top__head">Najprodavaniji</div>
            <div className="route__landingPage__articles__top__groups">
                <p className={selectedGroup ? "" : "active"} onClick={() => setSelectedGroup()}>Svi proizvodi</p>
                {getGroups().map(g => <p className={selectedGroup === g ? "active" : ""} onClick={() => setSelectedGroup(g)}>{g}</p>)}
            </div>
        </div>}
        <div className="route__landingPage__articles__bottom">
            <Splide options={{
                type: "carousel",
                breakpoints: splideModule.breakpoints,
                pagination: false,
                gap: 20
            }}>
                {getArticlesForGroup(selectedGroup).map(o => <SplideSlide>
                        <div className="route__landingPage__articles__bottom__item">
                        <img className="route__landingPage__articles__bottom__item__img" src={o.Images[0] ?? "#"} />
                        <div className="route__landingPage__articles__bottom__item__name">{o.Name}</div>
                        <div className="route__landingPage__articles__bottom__item__description">{o.Description}</div>

                        <div className="route__landingPage__articles__bottom__item__price">
                            <span className="route__landingPage__articles__bottom__item__price__left">
                                <div className="route__landingPage__articles__bottom__item__price__left__dot"></div>
                                <p className="route__landingPage__articles__bottom__item__price__left__text">Jednokratna kupovina</p>
                            </span>
                            <span className="route__landingPage__articles__bottom__item__price__value">{Number(o.NewPrice).toFixed(2)} {o.Currency}</span>
                        </div>

                        <div className="route__landingPage__articles__bottom__item__button" onClick={() => animateNavigate(`/offers/${o.Slog}`)}>POGLEDAJ PONUDU</div>
                    </div>
                </SplideSlide>)}
            </Splide>
        </div>
    </div>;
};

export default LandingPage;