import React from 'react';
import { createRoot } from "react-dom/client";
import { Provider } from 'react-redux';
import { createStore } from "redux";
import { HashRouter } from "react-router-dom"
import allReducers from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { setStore } from './modules/backendModule';
import './index.css';
import App from './App';


let store = createStore(allReducers, composeWithDevTools());
setStore(store);
const rootElem = createRoot(document.querySelector("#root"));
rootElem.render(<Provider store={store}>
    <HashRouter>
        <App />
    </HashRouter>
</Provider>);