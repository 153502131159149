import React from "react";
import "./index.scss";

import rpcClientModule from "../../modules/rpcClientModule";
import Spinner from "../../components/customComponents/Spinner";
import { useDispatch, useSelector } from "react-redux";
import animateModule from "../../modules/animateModule";
import * as cartActions from "../../actions/cartActions";

const CartModal = (props) => {
    const [offers, setOffers] = React.useState();
    const [cartDataState, setCartDataState] = React.useState();

    let dispatch = useDispatch();
    const cartData = useSelector((state) => state.cartReducer.cart ?? []);
    const wrapRef = React.useRef();

    const animateNavigate = to => animateModule(props.navigate, to, document.querySelector(".component__contentWrapper"));

    const onClose = () => {
        if (!wrapRef.current) return props.onClose();

        props.onClose();
        wrapRef.current.animate([
            {right: getComputedStyle(wrapRef.current).right},
            {right: "-150%"}
        ], {duration: 300, iterations: 1, fill: "both", easing: "ease"});
    };

    React.useEffect(() => {
        rpcClientModule({
            action: "call",
            method: "offers.f.getAll",
            callback: res => {
                if (res.status === "ok") {
                    setOffers(res.data);
                } else {
                    setOffers([]);
                };
            }
        });
    }, []);
    React.useLayoutEffect(() => {
        if (!wrapRef.current) return;

        wrapRef.current.animate([
            {right: getComputedStyle(wrapRef.current).right},
            {right: "5px"}
        ], {duration: 300, easing: "ease", fill: "both"});
    }, [wrapRef.current]);
    React.useEffect(() => {
        setCartDataState(cartData);
    }, [cartData]);
    React.useEffect(() => {
        if (!cartDataState) return;
        try {
            if (JSON.stringify(cartDataState) !== JSON.stringify(cartData)) {
                dispatch(cartActions.updateCart(cartDataState));
            };
        } catch {};
        localStorage.setItem('cart', JSON.stringify(cartDataState));
    }, [cartDataState]);

    return <div className="route__cartModalMain" onClick={onClose}>
        <div className="route__cartModal" ref={wrapRef} onClick={e => e?.stopPropagation?.()}>

        {offers && cartData ? <>
            <p className="route__cartModal__head">
                Vaša Valnor košarica

                <div className="route__cartModal__head__close" style={{backgroundImage: `url("/images/iconClose_black.svg")`}} onClick={onClose}></div>
            </p>
            <div className="route__cartModal__container">

                {cartDataState && cartDataState.map((elem, index) => {
                    let cartItem = offers.find(e => e.ID === elem.ID);
                    const incrementQuantity = () => {
                        setCartDataState(prevData =>
                            prevData.map((item, ind) =>
                                ind === index
                                    ? { ...item, quantity: item.quantity + 1 }
                                    : item
                            )
                        );
                    };

                    const decrementQuantity = () => {
                        setCartDataState(prevData =>
                            prevData.map((item, ind) =>
                                ind === index && item.quantity > 1
                                    ? { ...item, quantity: item.quantity - 1 }
                                    : item
                            )
                        );
                    };
                    const removeItem = (index) => {
                        const updatedCartData = cartDataState.filter((item, ind) => ind !== index);
                        setCartDataState(updatedCartData);
                    };
                    if (cartItem) {
                        return <div className="route__cartModal__container__item">
                            <img src={cartItem.Images[0]} />
                            <div className="route__cartModal__container__item__data">
                                <p className="route__cartModal__container__item__data__head">{cartItem.Heading}</p>
                                <p className="route__cartModal__container__item__data__price">
                                    <span>{(Number(cartItem.OldPrice) * elem.quantity).toFixed(2)} {cartItem.Currency}</span> &nbsp; &nbsp;
                                    <span>{(Number(cartItem.NewPrice) * elem.quantity).toFixed(2)} {cartItem.Currency}</span>
                                </p>
                                <div className="route__cartModal__container__item__data__quantity">
                                    <p>
                                        <p onClick={decrementQuantity}>-</p>
                                        <p>{elem.quantity}</p>
                                        <p onClick={incrementQuantity}>+</p>
                                    </p>
                                    <img onClick={() => { removeItem(index) }} src="/images/trashCan.svg" />
                                </div>
                            </div>
                        </div>
                    }

                })}
            </div>
            {cartDataState && <div className="route__cartModal__checkout">
                {cartData?.length > 0 && <>
                    <p className="route__cartModal__checkout__head">
                        <span> Subtotal: ({cartDataState.length} artikala) </span>
                        <span>{(() => {
                            let total = 0;
                            let currency;
                            cartDataState.map((elem, index) => {
                                let cartItem = offers.find(e => e.ID === elem.ID);
                                total += Number(cartItem.NewPrice) * elem.quantity;
                                currency = cartItem.Currency;

                            })
                            return `${Number(total).toFixed(2)} ${currency}`
                        })()}</span>
                    </p>
                    <p className="route__cartModal__checkout__info">
                        Popusti i promocije će biti izračunati naknadno
                    </p>
                </>}
                {cartData?.length > 0 && <button onClick={() => {
                    onClose();
                    animateNavigate("/checkout");
                }}>Završi kupovinu</button>}
                <p className="route__cartModal__checkout__info2">90-DNEVNA GARANCIJA ZADOVOLJSTVA</p>
            </div>}



        </> : <Spinner />}


        </div>
    </div>
    
};



export default CartModal;