import React from "react";
import "./index.scss";

import rpcClientModule from "../../modules/rpcClientModule";

let mail, phone, address, page, text1, text2, text3, text4, text5, bold1, bold2, bold3, listB1, listB2, listB3, listB4, listB5, list1, list2, list3, list4, list5, title1, text6, text7, text8, title2, title3, text9, title4, text10;

switch (rpcClientModule.country) {
    case 'BA':
        mail = 'podrska@valnor.ba';
        phone = '+387 35 365 130';
        address = 'Green Side d.o.o. Ćehaje bb, 75350 Srebrenik, Bosna i Hercegovina';
        page = `valnor.ba`;
        text1 = `Valnor („mi“, „nas“, „naši“) ozbiljno shvata privatnost. Ova Politika privatnosti objašnjava kako prikupljamo, koristimo i dijelimo lične podatke kada posjetite našu web stranicu ${page} (u daljem tekstu „Sajt“). Korištenjem sajta prihvatate uslove ove Politike privatnosti. Ažuriranja stupaju na snagu odmah po objavljivanju.`;
        text2 = `Ova Politika privatnosti važi za „Green Side d.o.o.“, vlasnika ${page}, sa sejdištem na adresi ${address}`;
        text3 = `Detalji o veb pregledaču, IP adresi, vremenskoj zoni i slične informacije koje automatski prikupljamo prilikom vaše posjete sajtu putem serverskog praćenja. `;
        bold1 = `Informacije o uređaju:`;
        bold2 = `Lični podaci:`;
        bold3 = `Informacije o praćenju:`;
        text4 = `Informacije kao što su ime, adresa e-pošte, adresa za naplatu i dostavu, detalji o plaćanju i telefonski broj koje nam pružate tokom kupovine ili kao potencijalni klijent.`;
        text5 = `Informacije o vašoj interakciji sa oglasima na platformama kao što su Facebook, Instagram i TikTok.`;
        listB1 = `Obrada narudžbina:`;
        listB2 = `Marketing i oglašavanje:`;
        listB3 = `Upravljanje podacima potencijalnih klijenata:`;
        listB4 = `Remarketing:`;
        listB5 = `Dodatna ponuda proizvoda:`;
        list1 = `Nakon što zaprimimo vašu narudžbinu, vaši podaci se prosljeđuju našem partnerskom call centru, koji je odvojena kompanija od Green Side d.o.o., radi provjere i potvrde narudžbine. Call centar osigurava preciznost unosa i završnu obradu kako bismo vam pružili brzu i pouzdanu uslugu. `;
        list2 = `Vaše podatke koristimo za prikazivanje oglasa prilagođenih vašim interesima na platformama poput Facebooka, Instagrama, TikToka, Googlea, Vibera i putem SMS-a. Pratimo interakcije kako bismo kontinuirano poboljšali kvalitet i efikasnost oglasa. `;
        list3 = `Podaci o potencijalnim klijentima pohranjuju se u naš CRM sistem za praćenje interesa i dalju komunikaciju, kako bismo osigurali brzu i tačnu podršku prilikom kupovine. `;
        list4 = `Prikupljene podatke možemo koristiti u kampanjama za remarketing s ciljem promocije sličnih proizvoda koji bi vam mogli biti korisni. Kampanje sprovodimo putem različitih online platformi kako bismo vam prikazali relevantne ponude i popuste.`;
        list5 = `Možemo vas kontaktirati kako bismo vam ponudili proizvode koji bi mogli biti korisni i zanimljivi, uključujući direktne pozive za predstavljanje novih ponuda ili promocija, u skladu s vašim prethodnim interesovanjima.`;
        title1 = `3. Dijeljenje podataka`;
        text6 = `Podatke dijelimo s trećim stranama, uključujući:`;
        text7 = `Ako ste stanovnik EU, UK ili Kalifornije, imate pravo na pristup, ispravku, brisanje ili ograničenje obrade vaših podataka. Da biste ostvarili ova prava, kontaktirajte nas putem e-pošte, a mi ćemo odgovoriti u roku od 30 dana od prijema zahtjeva.`;
        title2 = `6. Sigurnosne mjere`;
        text8 = `Preduzimamo tehničke i organizacione mjere zaštite kako bismo osigurali integritet i sigurnost vaših podataka. Iako se trudimo da implementiramo visoke standarde sigurnosti, nijedna metoda prenosa putem interneta ili elektronskog skladištenja nije 100% sigurna. `;
        title3 = `7. Privatnost djece`;
        text9 = `Sajt nije namijenjen djeci mlađoj od 13 godina, i svjesno ne prikupljamo njihove podatke.`;
        title4 = `8. Promjene u politici privatnosti`;
        text10 = `Valnor zadržava pravo izmjene Politike privatnosti. Ažurirane verzije stupaju na snagu odmah po objavljivanju. `;

        break;
    case 'RS':
        mail = 'podrska@valnor.rs';
        phone = '+382 257 150 310';
        address = 'Green Side d.o.o. Loznica, Marije Bursać 6, Srbija.';
        page = `valnor.rs`;
        text1 = `Valnor („mi“, „nas“, „naši“) ozbiljno shvata privatnost. Ova Politika privatnosti objašnjava kako prikupljamo, koristimo i delimo lične podatke kada posetite našu web stranicu ${page} (u daljem tekstu „Sajt“). Korištenjem sajta prihvatate uslove ove Politike privatnosti. Ažuriranja stupaju na snagu odmah po objavljivanju.`;
        text2 = `Ova Politika privatnosti važi za „Green Side d.o.o.“, vlasnika ${page}, sa sedištem na adresi ${address}`;
        text3 = `Detalji o veb pregledaču, IP adresi, vremenskoj zoni i slične informacije koje automatski prikupljamo prilikom vaše posete sajtu putem serverskog praćenja. `;
        bold1 = `Informacije o uređaju:`;
        bold2 = `Lični podaci:`;
        bold3 = `Informacije o praćenju:`;
        text4 = `Informacije kao što su ime, adresa e-pošte, adresa za naplatu i dostavu, detalji o plaćanju i telefonski broj koje nam pružate tokom kupovine ili kao potencijalni klijent.`;
        text5 = `Informacije o vašoj interakciji sa oglasima na platformama kao što su Facebook, Instagram i TikTok.`;
        listB1 = `Obrada narudžbina:`;
        listB2 = `Marketing i oglašavanje:`;
        listB3 = `Upravljanje podacima potencijalnih klijenata:`;
        listB4 = `Remarketing:`;
        listB5 = `Dodatna ponuda proizvoda:`;
        list1 = `Nakon što zaprimimo vašu narudžbinu, vaši podaci se prosleđuju našem partnerskom call centru, koji je odvojena kompanija od Green Side d.o.o., radi provere i potvrde narudžbine. Call centar osigurava preciznost unosa i završnu obradu kako bismo vam pružili brzu i pouzdanu uslugu. `;
        list2 = `Vaše podatke koristimo za prikazivanje oglasa prilagođenih vašim interesima na platformama poput Facebooka, Instagrama, TikToka, Googlea, Vibera i putem SMS-a. Pratimo interakcije kako bismo kontinuirano poboljšali kvalitet i efikasnost oglasa. `;
        list3 = `Podaci o potencijalnim klijentima pohranjuju se u naš CRM sistem za praćenje interesa i dalju komunikaciju, kako bismo osigurali brzu i tačnu podršku prilikom kupovine. `;
        list4 = `Prikupljene podatke možemo koristiti u kampanjama za remarketing s ciljem promocije sličnih proizvoda koji bi vam mogli biti korisni. Kampanje sprovodimo putem različitih online platformi kako bismo vam prikazali relevantne ponude i popuste.`;
        list5 = `Možemo vas kontaktirati kako bismo vam ponudili proizvode koji bi mogli biti korisni i zanimljivi, uključujući direktne pozive za predstavljanje novih ponuda ili promocija, u skladu s vašim prethodnim interesovanjima.`;
        title1 = `3. Deljenje podataka`;
        text6 = `Podatke delimo s trećim stranama, uključujući:`;
        text7 = `Ako ste stanovnik EU, UK ili Kalifornije, imate pravo na pristup, ispravku, brisanje ili ograničenje obrade vaših podataka. Da biste ostvarili ova prava, kontaktirajte nas putem e-pošte, a mi ćemo odgovoriti u roku od 30 dana od prijema zahteva.`;
        title2 = `6. Sigurnosne mere`;
        text8 = `Preduzimamo tehničke i organizacione mere zaštite kako bismo osigurali integritet i sigurnost vaših podataka. Iako se trudimo da implementiramo visoke standarde sigurnosti, nijedna metoda prenosa putem interneta ili elektronskog skladištenja nije 100% sigurna. `;
        title3 = `7. Privatnost dece`;
        text9 = `Sajt nije namenjen deci mlađoj od 13 godina, i svesno ne prikupljamo njihove podatke.`;
        title4 = `8. Promene u politici privatnosti`;
        text10 = `Valnor zadržava pravo izmene Politike privatnosti. Ažurirane verzije stupaju na snagu odmah po objavljivanju. `;

        break;
    case 'HR':
        mail = 'podrska@valnor.hr';
        phone = '+385 32 373 309';
        address = 'Županja, Trg prof. Martina Robotića 2/2, Županja, Hrvatska';
        page = `valnor.hr`;
        text1 = `Valnor („mi“, „nas“, „naši“) ozbiljno shvata privatnost. Ova Politika privatnosti objašnjava kako prikupljamo, koristimo i dijelimo lične podatke kada posjetite našu web stranicu ${page} (u daljem tekstu „Sajt“). Korištenjem sajta prihvatate uslove ove Politike privatnosti. Ažuriranja stupaju na snagu odmah po objavljivanju.`;
        text2 = `Ova Politika privatnosti važi za „Green Side d.o.o.“, vlasnika ${page}, sa sejdištem na adresi ${address}`;
        text3 = `Detalji o veb pregledaču, IP adresi, vremenskoj zoni i slične informacije koje automatski prikupljamo prilikom vaše posjete sajtu putem serverskog praćenja. `;
        bold1 = `Informacije o uređaju:`;
        bold2 = `Lični podaci:`;
        bold3 = `Informacije o praćenju:`;
        text4 = `Informacije kao što su ime, adresa e-pošte, adresa za naplatu i dostavu, detalji o plaćanju i telefonski broj koje nam pružate tokom kupovine ili kao potencijalni klijent.`;
        text5 = `Informacije o vašoj interakciji sa oglasima na platformama kao što su Facebook, Instagram i TikTok.`;
        listB1 = `Obrada narudžbina:`;
        listB2 = `Marketing i oglašavanje:`;
        listB3 = `Upravljanje podacima potencijalnih klijenata:`;
        listB4 = `Remarketing:`;
        listB5 = `Dodatna ponuda proizvoda:`;
        list1 = `Nakon što zaprimimo vašu narudžbinu, vaši podaci se prosljeđuju našem partnerskom call centru, koji je odvojena kompanija od Green Side d.o.o., radi provjere i potvrde narudžbine. Call centar osigurava preciznost unosa i završnu obradu kako bismo vam pružili brzu i pouzdanu uslugu. `;
        list2 = `Vaše podatke koristimo za prikazivanje oglasa prilagođenih vašim interesima na platformama poput Facebooka, Instagrama, TikToka, Googlea, Vibera i putem SMS-a. Pratimo interakcije kako bismo kontinuirano poboljšali kvalitet i efikasnost oglasa. `;
        list3 = `Podaci o potencijalnim klijentima pohranjuju se u naš CRM sistem za praćenje interesa i dalju komunikaciju, kako bismo osigurali brzu i tačnu podršku prilikom kupovine. `;
        list4 = `Prikupljene podatke možemo koristiti u kampanjama za remarketing s ciljem promocije sličnih proizvoda koji bi vam mogli biti korisni. Kampanje sprovodimo putem različitih online platformi kako bismo vam prikazali relevantne ponude i popuste.`;
        list5 = `Možemo vas kontaktirati kako bismo vam ponudili proizvode koji bi mogli biti korisni i zanimljivi, uključujući direktne pozive za predstavljanje novih ponuda ili promocija, u skladu s vašim prethodnim interesovanjima.`;
        title1 = `3. Dijeljenje podataka`;
        text6 = `Podatke dijelimo s trećim stranama, uključujući:`;
        text7 = `Ako ste stanovnik EU, UK ili Kalifornije, imate pravo na pristup, ispravku, brisanje ili ograničenje obrade vaših podataka. Da biste ostvarili ova prava, kontaktirajte nas putem e-pošte, a mi ćemo odgovoriti u roku od 30 dana od prijema zahtjeva.`;
        title2 = `6. Sigurnosne mjere`;
        text8 = `Preduzimamo tehničke i organizacione mjere zaštite kako bismo osigurali integritet i sigurnost vaših podataka. Iako se trudimo da implementiramo visoke standarde sigurnosti, nijedna metoda prenosa putem interneta ili elektronskog skladištenja nije 100% sigurna. `;
        title3 = `7. Privatnost djece`;
        text9 = `Sajt nije namijenjen djeci mlađoj od 13 godina, i svjesno ne prikupljamo njihove podatke.`;
        title4 = `8. Promjene u politici privatnosti`;
        text10 = `Valnor zadržava pravo izmjene Politike privatnosti. Ažurirane verzije stupaju na snagu odmah po objavljivanju. `;

        break;
    case 'ME':
        mail = 'podrska@valnor.me';
        phone = '+382 204 144 70';
        address = 'Green Side d.o.o. UL. Admirala Zmajevića br. 81, Stari Aerodrom, Podgorica';
        page = `valnor.me`;
        text1 = `Valnor („mi“, „nas“, „naši“) ozbiljno shvata privatnost. Ova Politika privatnosti objašnjava kako prikupljamo, koristimo i dijelimo lične podatke kada posjetite našu web stranicu ${page} (u daljem tekstu „Sajt“). Korištenjem sajta prihvatate uslove ove Politike privatnosti. Ažuriranja stupaju na snagu odmah po objavljivanju.`;
        text2 = `Ova Politika privatnosti važi za „Green Side d.o.o.“, vlasnika ${page}, sa sejdištem na adresi ${address}`;
        text3 = `Detalji o veb pregledaču, IP adresi, vremenskoj zoni i slične informacije koje automatski prikupljamo prilikom vaše posjete sajtu putem serverskog praćenja. `;
        bold1 = `Informacije o uređaju:`;
        bold2 = `Lični podaci:`;
        bold3 = `Informacije o praćenju:`;
        text4 = `Informacije kao što su ime, adresa e-pošte, adresa za naplatu i dostavu, detalji o plaćanju i telefonski broj koje nam pružate tokom kupovine ili kao potencijalni klijent.`;
        text5 = `Informacije o vašoj interakciji sa oglasima na platformama kao što su Facebook, Instagram i TikTok.`;
        listB1 = `Obrada narudžbina:`;
        listB2 = `Marketing i oglašavanje:`;
        listB3 = `Upravljanje podacima potencijalnih klijenata:`;
        listB4 = `Remarketing:`;
        listB5 = `Dodatna ponuda proizvoda:`;
        list1 = `Nakon što zaprimimo vašu narudžbinu, vaši podaci se prosljeđuju našem partnerskom call centru, koji je odvojena kompanija od Green Side d.o.o., radi provjere i potvrde narudžbine. Call centar osigurava preciznost unosa i završnu obradu kako bismo vam pružili brzu i pouzdanu uslugu. `;
        list2 = `Vaše podatke koristimo za prikazivanje oglasa prilagođenih vašim interesima na platformama poput Facebooka, Instagrama, TikToka, Googlea, Vibera i putem SMS-a. Pratimo interakcije kako bismo kontinuirano poboljšali kvalitet i efikasnost oglasa. `;
        list3 = `Podaci o potencijalnim klijentima pohranjuju se u naš CRM sistem za praćenje interesa i dalju komunikaciju, kako bismo osigurali brzu i tačnu podršku prilikom kupovine. `;
        list4 = `Prikupljene podatke možemo koristiti u kampanjama za remarketing s ciljem promocije sličnih proizvoda koji bi vam mogli biti korisni. Kampanje sprovodimo putem različitih online platformi kako bismo vam prikazali relevantne ponude i popuste.`;
        list5 = `Možemo vas kontaktirati kako bismo vam ponudili proizvode koji bi mogli biti korisni i zanimljivi, uključujući direktne pozive za predstavljanje novih ponuda ili promocija, u skladu s vašim prethodnim interesovanjima.`;
        title1 = `3. Dijeljenje podataka`;
        text6 = `Podatke dijelimo s trećim stranama, uključujući:`;
        text7 = `Ako ste stanovnik EU, UK ili Kalifornije, imate pravo na pristup, ispravku, brisanje ili ograničenje obrade vaših podataka. Da biste ostvarili ova prava, kontaktirajte nas putem e-pošte, a mi ćemo odgovoriti u roku od 30 dana od prijema zahtjeva.`;
        title2 = `6. Sigurnosne mjere`;
        text8 = `Preduzimamo tehničke i organizacione mjere zaštite kako bismo osigurali integritet i sigurnost vaših podataka. Iako se trudimo da implementiramo visoke standarde sigurnosti, nijedna metoda prenosa putem interneta ili elektronskog skladištenja nije 100% sigurna. `;
        title3 = `7. Privatnost djece`;
        text9 = `Sajt nije namijenjen djeci mlađoj od 13 godina, i svjesno ne prikupljamo njihove podatke.`;
        title4 = `8. Promjene u politici privatnosti`;
        text10 = `Valnor zadržava pravo izmjene Politike privatnosti. Ažurirane verzije stupaju na snagu odmah po objavljivanju. `;

        break;
    default:
        mail = 'podrska@valnor.ba';
        phone = '+387 35 365 130';
        address = 'Green Side d.o.o. Ćehaje bb, 75350 Srebrenik, Bosna i Hercegovina';
        page = `valnor.ba`;
        text1 = `Valnor („mi“, „nas“, „naši“) ozbiljno shvata privatnost. Ova Politika privatnosti objašnjava kako prikupljamo, koristimo i dijelimo lične podatke kada posjetite našu web stranicu ${page} (u daljem tekstu „Sajt“). Korištenjem sajta prihvatate uslove ove Politike privatnosti. Ažuriranja stupaju na snagu odmah po objavljivanju.`;
        text2 = `Ova Politika privatnosti važi za „Green Side d.o.o.“, vlasnika ${page}, sa sejdištem na adresi ${address}`;
        text3 = `Detalji o veb pregledaču, IP adresi, vremenskoj zoni i slične informacije koje automatski prikupljamo prilikom vaše posjete sajtu putem serverskog praćenja. `;
        bold1 = `Informacije o uređaju:`;
        bold2 = `Lični podaci:`;
        bold3 = `Informacije o praćenju:`;
        text4 = `Informacije kao što su ime, adresa e-pošte, adresa za naplatu i dostavu, detalji o plaćanju i telefonski broj koje nam pružate tokom kupovine ili kao potencijalni klijent.`;
        text5 = `Informacije o vašoj interakciji sa oglasima na platformama kao što su Facebook, Instagram i TikTok.`;
        listB1 = `Obrada narudžbina:`;
        listB2 = `Marketing i oglašavanje:`;
        listB3 = `Upravljanje podacima potencijalnih klijenata:`;
        listB4 = `Remarketing:`;
        listB5 = `Dodatna ponuda proizvoda:`;
        list1 = `Nakon što zaprimimo vašu narudžbinu, vaši podaci se prosljeđuju našem partnerskom call centru, koji je odvojena kompanija od Green Side d.o.o., radi provjere i potvrde narudžbine. Call centar osigurava preciznost unosa i završnu obradu kako bismo vam pružili brzu i pouzdanu uslugu. `;
        list2 = `Vaše podatke koristimo za prikazivanje oglasa prilagođenih vašim interesima na platformama poput Facebooka, Instagrama, TikToka, Googlea, Vibera i putem SMS-a. Pratimo interakcije kako bismo kontinuirano poboljšali kvalitet i efikasnost oglasa. `;
        list3 = `Podaci o potencijalnim klijentima pohranjuju se u naš CRM sistem za praćenje interesa i dalju komunikaciju, kako bismo osigurali brzu i tačnu podršku prilikom kupovine. `;
        list4 = `Prikupljene podatke možemo koristiti u kampanjama za remarketing s ciljem promocije sličnih proizvoda koji bi vam mogli biti korisni. Kampanje sprovodimo putem različitih online platformi kako bismo vam prikazali relevantne ponude i popuste.`;
        list5 = `Možemo vas kontaktirati kako bismo vam ponudili proizvode koji bi mogli biti korisni i zanimljivi, uključujući direktne pozive za predstavljanje novih ponuda ili promocija, u skladu s vašim prethodnim interesovanjima.`;
        title1 = `3. Dijeljenje podataka`;
        text6 = `Podatke dijelimo s trećim stranama, uključujući:`;
        text7 = `Ako ste stanovnik EU, UK ili Kalifornije, imate pravo na pristup, ispravku, brisanje ili ograničenje obrade vaših podataka. Da biste ostvarili ova prava, kontaktirajte nas putem e-pošte, a mi ćemo odgovoriti u roku od 30 dana od prijema zahtjeva.`;
        title2 = `6. Sigurnosne mjere`;
        text8 = `Preduzimamo tehničke i organizacione mjere zaštite kako bismo osigurali integritet i sigurnost vaših podataka. Iako se trudimo da implementiramo visoke standarde sigurnosti, nijedna metoda prenosa putem interneta ili elektronskog skladištenja nije 100% sigurna. `;
        title3 = `7. Privatnost djece`;
        text9 = `Sajt nije namijenjen djeci mlađoj od 13 godina, i svjesno ne prikupljamo njihove podatke.`;
        title4 = `8. Promjene u politici privatnosti`;
        text10 = `Valnor zadržava pravo izmjene Politike privatnosti. Ažurirane verzije stupaju na snagu odmah po objavljivanju. `;

        break;
};

const PrivacyPolicy = () => {
    return <div className="route__privacyPolicy">
        <h2 className="route__privacyPolicy__title">Politika Privatnosti - Valnor</h2>
        <h4 className="route__privacyPolicy__subTitle">Uvod</h4>
        <p className="route__privacyPolicy__text mb20">{text1}</p>
        <h4 className="route__privacyPolicy__subTitle">Informacije o kompaniji</h4>
        <p className="route__privacyPolicy__text mb20">{text2}</p>
        <h4 className="route__privacyPolicy__subTitle">1. Informacije koje prikupljamo</h4>
        <p className="route__privacyPolicy__text"><b>{bold1}</b> {text3}</p>
        <p className="route__privacyPolicy__text"><b>{bold2}</b> {text4}</p>
        <p className="route__privacyPolicy__text mb20"><b>{bold3}</b> {text5}</p>
        <h4 className="route__privacyPolicy__subTitle">2. Kako koristimo Vaše podatke</h4>
        <ul className="route__privacyPolicy__list mb20">
            <li><b>{listB1}</b> {list1}</li>
            <li><b>{listB2}</b> {list2}</li>
            <li><b>{listB3}</b> {list3}</li>
            <li><b>{listB4}</b> {list4}</li>
            <li><b>{listB5}</b> {list5}</li>
        </ul>
        <h4 className="route__privacyPolicy__subTitle">{title1}</h4>
        <p className="route__privacyPolicy__text">{text6}</p>
        <p className="route__privacyPolicy__text">Scale CRM, Facebook, TikTok, Google. Više informacija o njihovim uslovima možete pronaći na:</p>
        <ul className="route__privacyPolicy__list mb20">
            <li><a href="https://www.facebook.com/terms.php?_rdr">Uslovi korištenja Facebook-a</a></li>
            <li><a href="https://help.instagram.com/581066165581870">Uslovi korištenja usluge Instagram-a</a></li>
            <li><a href="https://www.tiktok.com/legal/page/row/terms-of-service/en">Uslovi korištenja usluge TikTok-a</a></li>
        </ul>
        <h4 className="route__privacyPolicy__subTitle">4. Korisnička prava</h4>
        <p className="route__privacyPolicy__text mb20">{text7}</p>
        <h4 className="route__privacyPolicy__subTitle">5. Zadržavanje podataka</h4>
        <p className="route__privacyPolicy__text mb20">Podaci se čuvaju onoliko dugo koliko je potrebno za usklađenost sa zakonima i našim obavezama prema korisnicima. Ako želite da vaši podaci budu izbrisani, molimo kontaktirajte nas putem kontakt podataka navedenih u nastavku. </p>
        <h4 className="route__privacyPolicy__subTitle">{title2}</h4>
        <p className="route__privacyPolicy__text mb20">{text8}</p>
        <h4 className="route__privacyPolicy__subTitle">{title3}</h4>
        <p className="route__privacyPolicy__text mb20">{text9}</p>
        <h4 className="route__privacyPolicy__subTitle">{title4}</h4>
        <p className="route__privacyPolicy__text mb20">{text10}</p>
        <h4 className="route__privacyPolicy__subTitle">Kontaktirajte nas</h4>
        <p className="route__privacyPolicy__text mb20">Email: <b>{mail}</b></p>
    </div>
};

export default PrivacyPolicy;