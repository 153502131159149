import React from "react";
import "./index.scss";

import PhoneInput from 'react-phone-input-2';
import * as timestmapActions from "../../actions/timestampActions";

import rpcClientModule from "../../modules/rpcClientModule";
import { countryPrefixes } from "../../modules/countryModule";
import animateModule from "../../modules/animateModule";
import Spinner from "../../components/customComponents/Spinner";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";



let orderHistoryText,
    accountSettings,
    nameHeadline,
    surnameHeadline,
    emailHeadline,
    phoneNumHeadline,
    saveChanges,
    oldPasswordWord,
    newPasswordWord,
    passwordHeadline;

switch (rpcClientModule.country) {
    case 'BA':
        orderHistoryText = 'Historija Narudžbi';
        accountSettings = 'Postavke računa';
        nameHeadline = 'Vaše Ime';
        surnameHeadline = 'Vaše Prezime';
        emailHeadline = 'Vaš Email';
        phoneNumHeadline = 'Vaš broj telefona';
        saveChanges = "Spremi promjene";
        oldPasswordWord = 'Stara lozinka';
        newPasswordWord = 'Nova lozinka';
        passwordHeadline = 'Promjeni lozinku';
        break;
    case 'RS':
        orderHistoryText = 'Istorija Porudžbina';
        accountSettings = 'Postavke računa';
        nameHeadline = 'Vaše Ime';
        surnameHeadline = 'Vaše Prezime';
        emailHeadline = 'Vaš Email';
        phoneNumHeadline = 'Vaš broj telefona';
        saveChanges = "Spremi promene";
        oldPasswordWord = 'Stara lozinka';
        newPasswordWord = 'Nova lozinka';
        passwordHeadline = 'Promeni lozinku';
        break;
    case 'HR':
        accountSettings = 'Postavke računa';
        orderHistoryText = 'Povijest Narudžbi';
        nameHeadline = 'Vaše Ime';
        surnameHeadline = 'Vaše Prezime';
        emailHeadline = 'Vaš Email';
        phoneNumHeadline = 'Vaš broj telefona';
        saveChanges = "Spremi promjene";
        oldPasswordWord = 'Stara lozinka';
        newPasswordWord = 'Nova lozinka';
        passwordHeadline = 'Promjeni lozinku';
        break;
    case 'ME':
        accountSettings = 'Postavke računa';
        orderHistoryText = 'Historija Narudžbi';
        nameHeadline = 'Vaše Ime';
        surnameHeadline = 'Vaše Prezime';
        emailHeadline = 'Vaš Email';
        phoneNumHeadline = 'Vaš broj telefona';
        saveChanges = "Spremi promjene";
        oldPasswordWord = 'Stara lozinka';
        newPasswordWord = 'Nova lozinka';
        passwordHeadline = 'Promjeni lozinku';
        break;
    default:
        break;
}

const Account = () => {

    const curNavigate = useNavigate();
    const curDispatch = useDispatch();
    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    const [activeTab, setActiveTab] = React.useState('orderHistory');

    const userDataSelector = useSelector(state => state?.userData ?? {});
    const userInfo = userDataSelector?.userData?.UserInfo || {};

    const [spinner, setSpinner] = React.useState(false);

    //ACCOUNT SETTINGS SECTION
    const [name, setName] = React.useState(userInfo.FirstName || "");
    const [surname, setSurname] = React.useState(userInfo.LastName || "");
    const [email, setEmail] = React.useState(userInfo.Username || "");
    const [phoneNum, setPhoneNum] = React.useState(userInfo.PhoneNumber || "");
    const [countryCode, setCountryCode] = React.useState("");
    const [passwordChangeMsg, setPasswordChangeMsg] = React.useState("");
    const [infoChangeMsg, setInfoChangeMsg] = React.useState("");
    const [showPassword, setShowPassword] = React.useState(false);
    const [showNewPassword, setShowNewPassword] = React.useState(false);

    const passwordRef = React.useRef();
    const newPasswordRef = React.useRef();

    //ORDER HISTORY

    const [dropdownVisible, setDropdownVisible] = React.useState(null);
    const [orderHistory, setOrderHistory] = React.useState();
    const [offers, setOffers] = React.useState();
    const [orderHistoryMsg, setOrderHistoryMsg] = React.useState('');
    const [isMobile, setIsMobile] = React.useState(window.innerWidth < 834);

    //END OF ORDER HISTORY

    const logout = () => {
        rpcClientModule({
            action: "call",
            method: "auth.logout",
        }).then(res => {
            curDispatch(timestmapActions.updateTimestamp());
            animateNavigate("/")
        })
    }

    const editAccountInfo = () => {
        if (spinner) return;
        setSpinner(true);
        let data = {};
        if (email) data.Username = email;
        if (phoneNum) data.PhoneNumber = phoneNum;
        if (name) data.FirstName = name;
        if (surname) data.LastName = surname;

        rpcClientModule({
            action: "call",
            method: "shopUsers.f.edit",
            params: data
        }).then(res => {
            if (res.status === 'ok') {
                setInfoChangeMsg(`${rpcClientModule.country === "RS" ? 'Promene uspešno' : 'Promjene uspješno'} sačuvane`)
            } else {
                setInfoChangeMsg(`Došlo je do greške prilikom ${rpcClientModule.country === "RS" ? 'promene' : 'promjene'} podataka`)
            }
        })
        setSpinner(false);
    }

    const changePassword = () => {
        if (spinner) return;
        if (!passwordRef.current.value || !newPasswordRef.current.value) return setPasswordChangeMsg(`Popunite sva potrebna polja`)
        setSpinner(true);
        rpcClientModule({
            action: "call",
            method: "shopUsers.f.changePassword",
            params: {
                oldPassword: passwordRef.current.value,
                newPassword: newPasswordRef.current.value
            }
        }).then(res => {
            if (res.status === 'ok') {
                setPasswordChangeMsg(`Lozinka ${rpcClientModule.country === "RS" ? 'uspešno promenjena' : 'uspješno promjenjena'} `)
            } else {
                setPasswordChangeMsg(`Došlo je do greške prilikom ${rpcClientModule.country === "RS" ? 'promene' : 'promjene'} lozinke`)
            }
        })
        setSpinner(false);
    }

    React.useEffect(() => {
        if (userInfo.phoneNum) {
            const phoneNumberCountryCode = userInfo.phoneNum.startsWith("+")
                ? userInfo.phoneNum.slice(1, userInfo.phoneNum.length).match(/^\d+/)?.[0]
                : "";
            setCountryCode(phoneNumberCountryCode || "ba");
        }
    }, [userInfo.phoneNum]);

    const togglePasswordVisibility = () => {
        setShowPassword((s) => !s);
    };

    const toggleNewPasswordVisibility = () => {
        setShowNewPassword((s) => !s);
    };

    // ORDER HISTORY SECTION 

    const getOrderHistory = () => {
        rpcClientModule({
            action: "call",
            method: "shopOrders.f.getAll",
        }).then(res => {
            if (res.status === 'ok') {
                setOrderHistory(res.data)
            } else {
                setOrderHistoryMsg('Došlo je do greške prilikom dohvatanja podataka')
            }
        })
    }

    const toggleDropdown = (id) => {
        setDropdownVisible(dropdownVisible === id ? null : id);
    };

    const getAllOffers = () => {
        rpcClientModule({
            action: "call",
            method: "offers.f.getAll",
        }).then(res => {
            if (res.status === 'ok') {
                setOffers(res.data)
            } else {
                setOrderHistoryMsg('Došlo je do greške prilikom dohvatanja podataka')
            }
        })
    }

    const formatArticles = (articles) => {
        return articles.map((article) => {
            const offer = offers.find((offer) => offer.ID === article.ID);
            return (
                <div key={article.ID} className="article">
                    <img src={offer?.Images[0]} alt={`Article ${article.ID}`} className="article-image" />
                    <span className="article-quantity">{article.Quantity}</span>
                </div>
            );
        });
    };

    const getPrice = (articles) => {
        let currency = '';
        let oldPrice = 0;
        let newPrice = 0;
        articles.map((article) => {
            const offer = offers.find((offer) => offer.ID === article.ID);
            oldPrice = oldPrice + Number(offer.OldPrice) * article.Quantity;
            newPrice = newPrice + Number(offer.NewPrice) * article.Quantity;
            currency = offer.Currency;
        })

        return <div className="price">
            <del>{oldPrice.toFixed(2)} {currency}</del>
            <p className="newPrice">{newPrice.toFixed(2)} {currency}</p>
        </div>
    }

    React.useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 834);

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);


    React.useEffect(() => {
        getOrderHistory();
        getAllOffers();
    }, [])


    return <div className="route__account">
        <div className="route__account__tabs">
            <p className={`route__account__tabs__normal ${activeTab === 'orderHistory' ? 'active' : ''}`} onClick={() => { setActiveTab("orderHistory") }}>{orderHistoryText}</p>
            <p className={`route__account__tabs__normal ${activeTab === 'accountSettings' ? 'active' : ''}`} onClick={() => { setActiveTab("accountSettings") }}>{accountSettings}</p>
        </div>
        {activeTab === 'orderHistory' && <div className="route__account__orderHistory">
            {isMobile && orderHistory && orderHistory?.length > 0 && <table className="user-data-table-mobile">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {orderHistory.map((item) => (
                        <React.Fragment key={item.ID}>
                            <tr onClick={() => toggleDropdown(item.LeadID)}>
                                <td>{item.LeadID}</td>
                                <td>
                                    {item.Status}
                                    <p className="followShipment__paragraph">Prati Pošiljku</p>
                                </td>
                            </tr>
                            {dropdownVisible === item.LeadID && (
                                <tr className={`dropdown-row ${dropdownVisible === item.LeadID ? 'expanded' : ''}`}>
                                    <td colSpan="2">
                                        <div className={`dropdown-content ${dropdownVisible === item.LeadID ? 'expanded' : ''}`}>
                                            <p><strong>Vrijeme Narudžbe:</strong> {new Date(item.createdAt).toLocaleString()}</p>
                                            <p><strong>Artikli:</strong> {formatArticles(item.Articles)}</p>
                                            <p><strong>Cijena:</strong> {getPrice(item.Articles)}</p>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>}
            {!isMobile && orderHistory && orderHistory?.length > 0 && <div className="user-data-table">
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Status</th>
                            <th>{rpcClientModule.country === "RS" ? 'Vreme Porudžbine' : 'Vrijeme Narudžbe'}</th>
                            <th>Artikli</th>
                            <th>{rpcClientModule.country === "RS" ? 'Cena' : 'Cijena'}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orderHistory.map((item) => (
                            <tr key={item.ID}>
                                <td>{item.LeadID}</td>
                                <td>
                                    {item.Status}
                                    <p onClick={() => animateNavigate(`/track?id=${item.LeadID}`)} className="followShipment__paragraph">Prati Pošiljku</p>
                                </td>
                                <td>{new Date(item.createdAt).toLocaleString()}</td>
                                <td>{formatArticles(item.Articles)}</td>
                                <td>{getPrice(item.Articles)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>}
            {orderHistory?.length === 0 && <p className="route__account__orderHistory__msg">Nema evidentiranih narudžbi na vašem računu.</p>}
            <p className="route__account__orderHistory__msg">{orderHistoryMsg}</p>
        </div>}
        {activeTab === 'accountSettings' && <div className="route__account__accountSettings">
            {userDataSelector?.isLoggedIn && userDataSelector?.userData?.UserInfo && <div className="route__account__accountSettings__info">
                <p className="route__account__accountSettings__info__headline">{nameHeadline}</p>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Ime"
                />

                <p className="route__account__accountSettings__info__headline">{surnameHeadline}</p>
                <input
                    type="text"
                    value={surname}
                    onChange={(e) => setSurname(e.target.value)}
                    placeholder="Prezime"
                />

                <p className="route__account__accountSettings__info__headline">{emailHeadline}</p>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                />
                <p className="route__account__accountSettings__info__headline">{phoneNumHeadline}</p>

                <PhoneInput
                    onlyCountries={['ba', 'rs', 'me', 'hr']}
                    localization={countryPrefixes}
                    country={countryCode}
                    value={phoneNum}
                    onChange={(phone) => setPhoneNum(phone)}
                />

                {spinner ? <Spinner style={{ margin: '0 auto', width: '32px' }} /> : <p className="route__account__accountSettings__info__btn" onClick={() => editAccountInfo()}>{saveChanges}</p>}
                <p className="route__account__accountSettings__info__msg">{infoChangeMsg}</p>

                <div className="route__account__accountSettings__info__password">
                    <div style={{ position: "relative" }}>
                        <input type={showPassword ? "text" : "password"} ref={passwordRef} placeholder={`${oldPasswordWord}*`}></input>
                        <span onClick={togglePasswordVisibility}
                            style={{
                                position: "absolute",
                                right: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                cursor: "pointer"
                            }}
                        >
                            {showPassword ? "👁️" : "👁️‍🗨️"}
                        </span>
                    </div>
                    <div style={{ position: "relative" }}>
                        <input type={showNewPassword ? "text" : "password"} ref={newPasswordRef} placeholder={`${newPasswordWord}*`}></input>
                        <span onClick={toggleNewPasswordVisibility}
                            style={{
                                position: "absolute",
                                right: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                cursor: "pointer"
                            }}
                        >
                            {showNewPassword ? "👁️" : "👁️‍🗨️"}
                        </span>
                    </div>
                    {spinner ? <Spinner style={{ margin: '0 auto', width: '32px' }} /> : <p className="route__account__accountSettings__info__password__btn" onClick={() => changePassword()}>{passwordHeadline}</p>}
                    <p className="route__account__accountSettings__info__password__msg">{passwordChangeMsg}</p>
                </div>

                <p className="route__account__accountSettings__info__logout" onClick={() => logout()}>Odjavi se</p>
            </div>}
        </div>}
    </div>
}

export default Account