import React from "react";
import "./index.scss";
import axios from "axios";
import { useParams } from "react-router-dom";
import rpcClientModule from "../../modules/rpcClientModule";
import { useLocation } from 'react-router-dom';
import Spinner from "../../components/customComponents/Spinner";
import { useDispatch, useSelector } from "react-redux";
import * as cartActions from "../../actions/cartActions";
import CartModal from "../../components/CartModal";
import { useNavigate } from "react-router-dom";
import animateModule from "../../modules/animateModule";

import rpcClient from "../../modules/rpcClientModule";
import { quizIdLookup } from "../../quizIdLookup";


let ljepoti = 'ljepoti';
let dijelu = "";
let predjelu = "";
let sedmično = "";
let Rijetko = "";
let primjećujem = "";
let promjene = "";
let Umjereno = "";
let osjećaj = "";
let riješim = "";
let osjećate = "";
let Osjećati = "";
let tjelesne = "";
let tijela = "";

switch (rpcClient.country) {
    case 'BA':
        ljepoti = 'ljepoti';
        dijelu = "dijelu";
        predjelu = "predjelu";
        sedmično = "sedmično";
        Rijetko = "Rijetko";
        primjećujem = "primjećujem";
        promjene = "promjene";
        Umjereno = "Umjereno";
        osjećaj = "osjećaj";
        riješim = "riješim";
        osjećate = "osjećate";
        Osjećati = "Osjećati";
        tjelesne = "tjelesne";
        tijela = "tijela";
        break;
    case 'RS':
        ljepoti = 'lepoti';
        dijelu = "delu";
        predjelu = "predelu";
        sedmično = "nedeljno";
        Rijetko = "Retko";
        primjećujem = "primećujem";
        promjene = "promene";
        Umjereno = "Umereno";
        osjećaj = "osećaj";
        riješim = "rešim";
        osjećate = "osećate";
        Osjećati = "Osećati";
        tjelesne = "telesne";
        tijela = "tela";
        break;
    case 'HR':
        ljepoti = 'ljepoti';
        dijelu = "dijelu";
        predjelu = "predjelu";
        sedmično = "u tjednu";
        Rijetko = "Rijetko";
        primjećujem = "primjećujem";
        promjene = "promjene";
        Umjereno = "Umjereno";
        osjećaj = "osjećaj";
        riješim = "riješim";
        osjećate = "osjećate";
        Osjećati = "Osjećati";
        tjelesne = "tjelesne";
        tijela = "tijela";
        break;
    case 'ME':
        ljepoti = 'ljepoti';
        dijelu = "dijelu";
        predjelu = "predjelu";
        sedmično = "sedmično";
        Rijetko = "Rijetko";
        primjećujem = "primjećujem";
        promjene = "promjene";
        Umjereno = "Umjereno";
        osjećaj = "osjećaj";
        riješim = "riješim";
        osjećate = "osjećate";
        Osjećati = "Osjećati";
        tjelesne = "tjelesne";
        tijela = "tijela";
        break;
    default:
        break;
}


let questions = {
    1: [
        {
            "q": `Koji je vaš krajnji cilj u zdravlju ?`,
            "a": [
                "Poboljšati zdravlje prostate",
                "Poboljšati potenciju i energiju",
                "Povećati veličinu i performanse",
                "Podržati opšte zdravlje"
            ]
        },
        {
            "q": "S kojim problemima najviše imate problema kada je u pitanju zdravlje prostate?",
            "a": [
                "Učestalo mokrenje, posebno noću",
                `Nelagodnost i pritisak u donjem ${dijelu} stomaka`,
                "Slabiji mlaz prilikom mokrenja",
                `Povećani umor i manjak energije`
            ]
        },
        {
            "q": `Koliko imate godina?`,
            "a": [
                `18-29`,
                `30-39`,
                `40-49`,
                `50+`
            ]
        },
        {
            "q": `Koliko često imate simptome kao što su učestalo mokrenje ili bol u ${predjelu} prostate?`,
            "a": [
                `Gotovo svakodnevno`,
                `Više puta ${sedmično}`,
                "Povremeno",
                `${Rijetko}, ali ${primjećujem} ${promjene}`
            ]
        },
        {
            "q": "Kako bi opisali uticaj simptoma prostate na vaš kvalitet života?",
            "a": [
                "Značajno utiče, ometa moj san i svakodnevne aktivnosti",
                `${Umjereno} utiče, ponekad ometa moj dan`,
                "Povremeno utiče, ali ne previše",
                `${Rijetko} utiče, ali ${primjećujem} ${promjene}`
            ]
        },
        {
            "q": "Da li trenutno koristite neku vrstu terapije ili dodataka za zdravlje prostate?",
            "a": [
                `Da, koristim medicinsku terapiju`,
                `Koristim prirodne dodatke`,
                "Razmišljam o tome",
                "Ne, trenutno ne koristim"
            ]
        },
        {
            "q": `Koji je vaš glavni cilj u vezi sa zdravljem prostate?`,
            "a": [
                "Smanjiti učestalost mokrenja",
                `Ublažiti ${osjećaj} pritiska i nelagodnosti`,
                `Smanjiti napor prilikom mokrenja`,
                `Želim da ${riješim} upalu prostate`
            ]
        }
    ],
    2: [
        {
            "q": "Šta vas najviše muči kada je u pitanju potencija?",
            "a": [
                "Nedostatak energije i izdržljivosti",
                "Smanjena seksualna želja",
                `Kratko trajanje performansi`,
                "Nedovoljno samopouzdanje"
            ]
        },
        {
            "q": `Koliko imate godina?`,
            "a": [
                `18-29`,
                `30-39`,
                `40-49`,
                `50+`
            ]
        },
        {
            "q": `Koliko često ${osjećate} nedostatak energije u intimnim trenucima?`,
            "a": [
                "Često",
                "Ponekad",
                `${Rijetko}`,
                "Nikada"
            ]
        },
        {
            "q": `Koji je vaš glavni cilj u vezi s potencijom?`,
            "a": [
                "Povećati energiju i izdržljivost",
                "Poboljšati libido",
                "Održavati dugotrajnu erekciju",
                `${Osjećati} se sigurnije`
            ]
        },
        {
            "q": `Da li koristite dodatke za podršku potenciji?`,
            "a": [
                `Da, redovno`,
                `Povremeno`,
                "Ne, ali planiram",
                `Ne koristim ih`
            ]
        },
        {
            "q": `Kako stres utiče na vašu potenciju?`,
            "a": [
                "Značajno",
                `Prilično`,
                `Malo`,
                `Ne utiče`
            ]
        }
    ],
    3: [
        {
            "q": "Šta želite postići kada je u pitanju povećanje performansi?",
            "a": [
                "Duža i stabilnija erekcija",
                "Povećanje obima i veličine",
                `Produženo trajanje`,
                "Intenzivniji orgazmi"
            ]
        },
        {
            "q": `Koliko imate godina?`,
            "a": [
                `18-29`,
                `30-39`,
                `40-49`,
                `50+`
            ]
        },
        {
            "q": `Koji aspekt performansi vam je najvažniji?`,
            "a": [
                "Stabilnost erekcije",
                "Veći obim",
                "Trajanje odnosa",
                `Zadovoljstvo partnera`
            ]
        },
        {
            "q": `Koliko često se ${osjećate} nesigurno u vezi svoje performanse?`,
            "a": [
                "Često",
                `Ponekad`,
                `${Rijetko}`,
                "Nikada"
            ]
        },
        {
            "q": "Da li koristite dodatke za povećanje performansi?",
            "a": [
                `Da, redovno`,
                `Povremeno`,
                "Ne, ali planiram",
                `Ne koristim ih`
            ]
        },
        {
            "q": `Šta očekujete od dodataka za povećanje performansi?`,
            "a": [
                "Veći obim i duže trajanje",
                "Stabilnija erekcija",
                "Povećana seksualna želja",
                "Povećano samopouzdanje"
            ]
        }
    ],
    4: [
        {
            "q": "Koje su vaše glavne brige vezane za opšte zdravlje?",
            "a": [
                `Održavanje energije i vitalnosti`,
                "Održavanje zdravlja srca i cirkulacije",
                "Jačanje imunološkog sistema",
                `Problem sa sluhom`,
                `Problem sa kostima`,
                `Problem sa spavanjem`
            ]
        },
        {
            "q": "Koliko imate godina?",
            "a": [
                `18-29`,
                "30-39",
                "40-49",
                "50+"
            ]
        },
        {
            "q": "Na šta se najviše fokusirate kada radite na svom opštem zdravlju?",
            "a": [
                `Povećanje energije`,
                "Jačanje otpornosti na stres",
                `Unapređenje imuniteta`,
                `Održavanje normalne ${tjelesne} težine`
            ]
        },
        {
            "q": `Koliko često ${osjećate} umor ili iscrpljenost?`,
            "a": [
                "Svakodnevno",
                "Povremeno",
                `${Rijetko}`,
                "Gotovo nikada"
            ]
        },
        {
            "q": "Da li koristite dodatke za podršku opštem zdravlju?",
            "a": [
                `Da, redovno`,
                "Povremeno",
                "Ne, ali planiram",
                "Ne koristim ih"
            ]
        },
        {
            "q": `Koji aspekt opšteg zdravlja vam je najvažniji?`,
            "a": [
                "Povećanje energije",
                `Smanjenje stresa`,
                `Jačanje imunološkog sistema`,
                "Poboljšanje mentalne jasnoće",
                `Poboljšanje ${tijela} i snage`
            ]
        }
    ]
}


const Quiz = () => {
    const [step, setStep] = React.useState(0);
    const [selectedQuiz, setSelectedQuiz] = React.useState();
    const [selectedAnswers, setSelectedAnswers] = React.useState([]);
    const [offers, setOffers] = React.useState();
    const [selectedOffer, setSelectedOffer] = React.useState();
    const [emailMsgError, setEmailMsgErrorRef] = React.useState('');
    const [phoneMsgError, setPhoneMsgErrorRef] = React.useState('');

    const firstLastNameRef = React.useRef();
    const emailRef = React.useRef();
    const phoneNumberRef = React.useRef();

    const curNavigate = useNavigate();


    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    React.useEffect(() => {
        rpcClientModule({
            action: "call",
            method: "offers.f.getAll",
            args: {
                filters: [
                    { name: "ChildOffers", op: "eq", value: null }
                ]
            },
            callback: res => {
                if (res.status === "ok") setOffers(res.data);
            }
        });
    }, []);


    React.useEffect(() => {

        if (!offers) return;
        let curOffer;
        switch (selectedQuiz) {
            case 1:
                curOffer = offers.find(e => e.Name.toLowerCase().includes("prosta"));
                break;
            case 2:
                curOffer = offers.find(e => e.Name.toLowerCase().includes("alpha"));
                break;
            case 3:
                curOffer = offers.find(e => e.Name.toLowerCase().includes("hair"));
                break;
            case 4:
                curOffer = offers.find(e => e.Name.toLowerCase().includes("digest"));
                break;
            default:
                curOffer = offers[0];
                break;
        }
        if (curOffer) {
            setSelectedOffer(curOffer)
        }

    }, [step])

    const sendEmail = () => {

        if (!firstLastNameRef.current.value || !emailRef.current.value){
            setEmailMsgErrorRef("Molimo unesite Vaše podatke");
            return;
        }
        let value = "";
        if (quizIdLookup[selectedOffer?.Slog] && quizIdLookup[selectedOffer?.Slog][rpcClient?.country]) {
            value = quizIdLookup[selectedOffer?.Slog][rpcClient?.country];
        }else{
            setEmailMsgErrorRef("Došlo je do greške, molimo pokušajte ponovo kasnije");
        }

        axios({
            method: "POST",
            url: `https://backend.scale-crm.com:5000/api/leads/addSubscriber`,
            data: {
                Email: emailRef.current.value,
                FirstLastName: firstLastNameRef.current.value,
                Group: value
            }
        }).then(res => {
        }).catch(() => {
        }).finally(() => {
            animateNavigate(`/offers/${selectedOffer?.Slog}`);
        })
    };

    const sendPhoneNumber = () => {

        if (!phoneNumberRef.current.value){
            setPhoneMsgErrorRef("Molimo unesite Vaše podatke");
            return;
        }

        axios({
            method: "POST",
            url: `https://backend.scale-crm.com:5000/api/leads/addSubscriber`,
            data: {
                PhoneNumber: phoneNumberRef.current.value,
                OfferID: selectedOffer.ScaleleadID
            }
        }).then(res => {
        }).catch(() => {
        }).finally(() => {
            animateNavigate(`/offers/${selectedOffer?.Slog}`);
        })
    };


    return step === -1 ? <div className="route__quiz__result">
        {selectedQuiz === 4 && <div className="route__quiz__result__content">
            <p className="route__quiz__result__content__head">Pronašli smo vaš savršeni spoj!</p>
            <p className="route__quiz__result__content__subhead">Na osnovu vaših odgovora na pitanja, naša preporuka je...</p>
            <img src={selectedOffer?.Images[0]} />
            <p className="route__quiz__result__content__name">{selectedOffer?.Name}</p>
            <p className="route__quiz__result__content__reviews">
                ★★★★★<br />
                <span>2491 recenzije</span>
            </p>
            <p className="route__quiz__result__content__price">{selectedOffer?.NewPrice} {selectedOffer?.Currency}</p>
            <button onClick={() => animateNavigate(`/offers/${selectedOffer.Slog}`)} >Naruči</button>
        </div>}
    </div> : <div className="route__quiz">
        <div className="route__quiz__slide">
            <div style={{ left: selectedQuiz ? `${100 / (questions[selectedQuiz]?.length + 3) * step}%` : "0%" }} className="route__quiz__slide__button"></div>
            <div style={{ width: selectedQuiz ? `${100 / (questions[selectedQuiz]?.length + 3) * step}%` : "0%" }} className="route__quiz__slide__bcg"></div>
            <p className="route__quiz__slide__back" onClick={() => { if (step > 0) { setStep(prvStep => prvStep - 1) } }}>&lt; Nazad</p>
        </div>


        {step === 0 && <div className="route__quiz__start">
            <p className="route__quiz__start__head">Koji je vaš krajnji cilj u zdravlju?</p>
            <div className="route__quiz__start__content">
                <div onClick={() => {
                    setStep(1);
                    setSelectedQuiz(1)
                }}>
                    <img src="/images/quiz/icon1.svg" />
                    <p>Poboljšati zdravlje prostate</p>
                </div>
                <div onClick={() => {
                    setStep(1);
                    setSelectedQuiz(2)
                }}>
                    <img src="/images/quiz/icon2.svg" />
                    <p>Poboljšati potenciju i energiju</p>
                </div>
                <div onClick={() => {
                    setStep(1);
                    setSelectedQuiz(3)
                }}>
                    <img src="/images/quiz/icon3.svg" />
                    <p>Povećati veličinu i performanse</p>
                </div>
                <div onClick={() => {
                    setStep(1);
                    setSelectedQuiz(4)
                }}>
                    <img src="/images/quiz/icon4.svg" />
                    <p>Podržati opšte zdravlje</p>
                </div>
            </div>
        </div>}
        {selectedQuiz && step > 0 && step <= questions[selectedQuiz]?.length && <div className="route__quiz__questions">
            <p className="route__quiz__start__head">{questions[selectedQuiz][step - 1].q}</p>
            <div className="route__quiz__questions__q">
                {questions[selectedQuiz][step - 1].a.map(q => {
                    return <p onClick={() => { setSelectedAnswers(prevData => [...prevData, q]) }} className={selectedAnswers.includes(q) ? "route__quiz__questions__q__a route__quiz__questions__q__a-active" : "route__quiz__questions__q__a"}>{q}</p>
                })}
            </div>
            <div className="route__quiz__questions__button">
                <button onClick={() => { setStep(prev => prev + 1) }}>Dalje</button>
            </div>

        </div>}
        {selectedQuiz && step === questions?.[selectedQuiz]?.length + 1 && <div className="route__quiz__emailForm">
            <p className="route__quiz__start__head">Skoro ste gotovi!</p>
            <p className="route__quiz__emailForm__subhead">Unesite svoju e-mail adresu kako bismo vam poslali rezultate.</p>
            <input ref={firstLastNameRef} placeholder="Ime i prezime" />
            <input ref={emailRef} placeholder="E-mail" />
            <button className="route__quiz__emailForm__buttonFill" onClick={sendEmail}>Dalje</button>
            <p className="route__quiz__emailForm__subhead">ili</p>
            <button onClick={() => { setStep(prev => prev + 1) }} className="route__quiz__emailForm__buttonEmpty">Preskoči i pogledaj rezultate</button>
            <p style={{color: "#FF4C4C"}}>{emailMsgError}</p>
        </div>}

        {step === questions[selectedQuiz]?.length + 2 && <div className="route__quiz__emailForm">
            <p className="route__quiz__start__head">Poslednji korak!</p>
            <p className="route__quiz__emailForm__subhead">Unesite svoj broj telefona kako biste prvi saznali za promocije i nove proizvode.</p>
            <input ref={phoneNumberRef} placeholder="Broj telefona" />
            <button className="route__quiz__emailForm__buttonFill" onClick={sendPhoneNumber} >Pošalji i pogledaj rezultate</button>
            <p className="route__quiz__emailForm__subhead">ili</p>
            <button onClick={() => { animateNavigate(`/offers/${selectedOffer?.Slog}`); }} className="route__quiz__emailForm__buttonEmpty">Preskoči i pogledaj rezultate</button>
            <p style={{color: "#FF4C4C"}}>{phoneMsgError}</p>
        </div>}
        <div className="route__quiz__image">
            {/* <img src="/images/quiz/products.png" /> */}
        </div>
    </div>
};



export default Quiz;