import React from "react";
import "./index.scss";

import { getParamsFromURLObject } from "../../modules/urlModule";
import Spinner from "../../components/customComponents/Spinner";

const TrackYourShipment = () => {
    const [data, setData] = React.useState();

    let inputRef = React.useRef();

    const fetchData = () => {
        if (data === null) return;
        setData(null);
        
        let val = inputRef.current.value;
        if (!val) return;
        setTimeout(() => {
            fetch(`https://backend.scale-crm.com:5000/api/deals/getShippmentStatus`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    LeadID: val
                })
            }).then(res => res.json()).then(res => {
                if (res.status === "ok") {
                    if (res.data.length === 0) return setData(["Paket u pripremi za slanje"]);
                    setData(res.data);
                } else {
                    setData(["Paket nije pronađen"]);
                };
            }).catch(() => {
                setData(["Paket nije pronađen"]);
            });
        }, 1000);
    };

    React.useLayoutEffect(() => {
        if (!inputRef.current) return;
        let params = getParamsFromURLObject(String(window.location));

        if (params.id) {
            inputRef.current.value = params.id;
            fetchData();
        };
    }, [inputRef.current]);

    return <div className="route__trackYourShipment">
        <h2>Pratite Vaš paket</h2>
        <input ref={inputRef} type="text" placeholder="Broj narudžbe" />
        <button onClick={fetchData} style={{pointerEvents: data === null ? "none" : "all"}}>Pogledaj</button>

        {data === null && <Spinner color="#FD4798" style={{margin: "0 auto"}} />}
        {data && data.map(d => <p>{d}</p>)}
    </div>
};

export default TrackYourShipment;