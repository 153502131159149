import React from "react";
import "./index.scss";

import rpcClientModule from "../../modules/rpcClientModule";

let obradaHeadline, obradaText, vrijemeHeadline, vrijemeText, preuzimanjeHeadline, preuzimanjeText;

switch (rpcClientModule.country) {
    case 'BA':
        obradaHeadline = 'Obrada Narudžbe';
        obradaText = 'Nakon narudžbe, naš tim će kontaktirati kupca telefonom radi potvrde adrese. Narudžbe se obrađuju i šalju radnim danima (ponedjeljak - petak), dok vikendom i praznicima nema slanja.';
        vrijemeHeadline = 'Vrijeme Dostave';
        vrijemeText = 'Dostava traje između 24-48 sati nakon slanja. Kupac će dobiti SMS i Viber obavijest po slanju narudžbe. Kurirska služba će kontaktirati kupca na dan dostave kako bi potvrdila vrijeme dolaska paketa. ';
        preuzimanjeHeadline = 'Preuzimanje Paketa';
        preuzimanjeText = 'Kupac je dužan preuzeti paket prilikom dostave. U slučaju da kupac nije u mogućnosti preuzeti paket, molimo da nas obavjesti unaprjed radi odlaganja dostave. Ukoliko paket nije preuzet bez prethodne obavjesti, zadržavamo pravo daljeg postupanja u skladu sa pravilima poslovanja ';
        break;
    case 'RS':
        obradaHeadline = 'Obrada Narudžbe';
        obradaText = 'Nakon narudžbe, naš tim će kontaktirati kupca telefonom radi potvrde adrese. Narudžbe se obrađuju i šalju radnim danima (ponedeljak - petak), dok vikendom i praznicima nema slanja.';
        vrijemeHeadline = 'Vreme Dostave';
        vrijemeText = 'Dostava traje između 24-48 sati nakon slanja. Kupac će dobiti SMS i Viber obavijest po slanju narudžbe. Kurirska služba će kontaktirati kupca na dan dostave kako bi potvrdila vreme dolaska paketa. ';
        preuzimanjeHeadline = 'Preuzimanje Paketa';
        preuzimanjeText = 'Kupac je dužan preuzeti paket prilikom dostave. U slučaju da kupac nije u mogućnosti preuzeti paket, molimo da nas obavesti unapred radi odlaganja dostave. Ukoliko paket nije preuzet bez prethodne obavesti, zadržavamo pravo daljeg postupanja u skladu sa pravilima poslovanja ';
        break;
    case 'HR':
        obradaHeadline = 'Obrada Narudžbe';
        obradaText = 'Nakon narudžbe, naš tim će kontaktirati kupca telefonom radi potvrde adrese. Narudžbe se obrađuju i šalju radnim danima (ponedjeljak - petak), dok vikendom i praznicima nema slanja.';
        vrijemeHeadline = 'Vrijeme Dostave';
        vrijemeText = 'Dostava traje između 24-48 sati nakon slanja. Kupac će dobiti SMS i Viber obavijest po slanju narudžbe. Kurirska služba će kontaktirati kupca na dan dostave kako bi potvrdila vrijeme dolaska paketa. ';
        preuzimanjeHeadline = 'Preuzimanje Paketa';
        preuzimanjeText = 'Kupac je dužan preuzeti paket prilikom dostave. U slučaju da kupac nije u mogućnosti preuzeti paket, molimo da nas obavjesti unaprjed radi odlaganja dostave. Ukoliko paket nije preuzet bez prethodne obavjesti, zadržavamo pravo daljeg postupanja u skladu sa pravilima poslovanja ';
        break;
    case 'ME':
        obradaHeadline = 'Obrada Narudžbe';
        obradaText = 'Nakon narudžbe, naš tim će kontaktirati kupca telefonom radi potvrde adrese. Narudžbe se obrađuju i šalju radnim danima (ponedjeljak - petak), dok vikendom i praznicima nema slanja.';
        vrijemeHeadline = 'Vrijeme Dostave';
        vrijemeText = 'Dostava traje između 24-48 sati nakon slanja. Kupac će dobiti SMS i Viber obavijest po slanju narudžbe. Kurirska služba će kontaktirati kupca na dan dostave kako bi potvrdila vrijeme dolaska paketa. ';
        preuzimanjeHeadline = 'Preuzimanje Paketa';
        preuzimanjeText = 'Kupac je dužan preuzeti paket prilikom dostave. U slučaju da kupac nije u mogućnosti preuzeti paket, molimo da nas obavjesti unaprjed radi odlaganja dostave. Ukoliko paket nije preuzet bez prethodne obavjesti, zadržavamo pravo daljeg postupanja u skladu sa pravilima poslovanja ';
        break;
    default:
        obradaHeadline = 'Obrada Narudžbe';
        obradaText = 'Nakon narudžbe, naš tim će kontaktirati kupca telefonom radi potvrde adrese. Narudžbe se obrađuju i šalju radnim danima (ponedjeljak - petak), dok vikendom i praznicima nema slanja.';
        vrijemeHeadline = 'Vrijeme Dostave';
        vrijemeText = 'Dostava traje između 24-48 sati nakon slanja. Kupac će dobiti SMS i Viber obavijest po slanju narudžbe. Kurirska služba će kontaktirati kupca na dan dostave kako bi potvrdila vrijeme dolaska paketa. ';
        preuzimanjeHeadline = 'Preuzimanje Paketa';
        preuzimanjeText = 'Kupac je dužan preuzeti paket prilikom dostave. U slučaju da kupac nije u mogućnosti preuzeti paket, molimo da nas obavjesti unaprjed radi odlaganja dostave. Ukoliko paket nije preuzet bez prethodne obavjesti, zadržavamo pravo daljeg postupanja u skladu sa pravilima poslovanja ';
        break;
}

const ShippmentPolicy = () => {

    return <div className="route__shippmentPolicy">
        <p className="route__shippmentPolicy__headline">Pravila Dostave - Valnor</p>
        <p className="route__shippmentPolicy__subheadline">{obradaHeadline}</p>
        <p className="route__shippmentPolicy__text">{obradaText}</p>
        <p className="route__shippmentPolicy__subheadline">{vrijemeHeadline}</p>
        <p className="route__shippmentPolicy__text">{vrijemeText}</p>
        <p className="route__shippmentPolicy__subheadline">{preuzimanjeHeadline}</p>
        <p className="route__shippmentPolicy__text">{preuzimanjeText}</p>
    </div>
}

export default ShippmentPolicy