export const quizIdLookup = {
    "cellu-lift" : {
        "BA": "74827699-cd3f-44ff-919a-4eceef9ffc01",
        "RS": "714d8946-1f79-4f1c-87c9-52f4da7cb06f",
        "HR": "843dd82f-3619-4e34-8eb6-30d4119e6235",
        "ME": "e6bc07f8-ac5d-441c-a515-d7da5688f57d",
    },
    "hair-grow" : {
        "BA": "8df42720-9241-43d9-bdb2-a11ed621420a",
        "RS": "141f7b08-c891-4b9b-8824-a526f133e369",
        "HR": "987fdc6b-ab9a-4a7c-a0af-1dd12e7fd440",
        "ME": "75d8bebe-cdf1-4989-a398-61503576770a",
    },
    "pure-fit" : {
        "BA": "af9d04b6-c84e-4cc2-bcc1-9e438e7e9914",
        "RS": "32969aa9-b06e-4548-8a78-cab171a830f6",
        "HR": "19363674-7ab5-4cc8-967f-aff812aad804",
        "ME": "45708754-0fd6-4a32-bde7-a0528122523f",
    },
    "beauty-fem" : {
        "BA": "0de3a146-158b-4095-acfa-1a8dc001acb1",
        "RS": "26e520ae-faff-4235-b103-b17f1ddd18f7",
        "HR": "af032dad-4cf9-49c2-8ab2-b89015b36baf",
        "ME": "d7e441c1-10fa-43f8-9157-ae882003c707",
    },
    "hemo-calm" : {
        "BA": "254fbcb2-9e14-4e0d-a058-31636a74e1e6",
        "RS": "e6371f6b-c353-4a3c-86f4-d0c093a48a08",
        "HR": "417a4bf4-f48e-4d6f-8da0-03c436e52e82",
        "ME": "8c2c72fa-c621-4bfc-9e35-d5f1c1676fd5",
    },
    "alpha-potent" : {
        "BA": "110a85f5-23bb-4b57-9866-b9a5a77e78ef",
        "RS": "c9362fb3-f428-42f6-ae78-b6f1140cf1bc",
        "HR": "ba56c589-612a-45b4-846b-bd4e52c01c05",
        "ME": "791033e7-d621-477e-a3b7-f59f45dddb63",
    },
    "prosta-vital" : {
        "BA": "72569e84-5e9b-496d-b76b-300303be8a56",
        "RS": "d68e5da8-e848-4276-8598-1d04968cbdfc",
        "HR": "cdf2a436-4fe9-4461-9892-f835f8a45bf5",
        "ME": "bc697ec1-e451-4882-abd1-70e3181077c1",
    },
    "libido" : {
        "BA": "test Value",
    },
}