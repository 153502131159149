import React from "react";
import "./index.scss";

import rpcClientModule from "../../modules/rpcClientModule";

let headline = '';
let text1 = '';
let tacno = '';
let odmjerenu = '';
let izbjegava = '';
let mjesanjem = '';
let Bezbjednost = '';
let bezbjednost = '';
let bezbjednoscu = '';
let provjere = "";
let bezbjedno = "";
let certifikat = "";
let provjera = "";
let svjetu = "";
let gdje = "";
let bezbjedne = "";
let rješenja = "";
let usmjeravanju = "";
let sprečavajući = "";
let donijeti = "";

switch (rpcClientModule.country) {
    case 'BA':
        headline = "Da li su Suplementi u Obliku Kapsula Bezbjedni za Korištenje?";
        text1 = "Danas su suplementi u obliku kapsula veoma popularni, zahvaljujući njihovoj praktičnosti i efektivnosti u pružanju potrebnih hranljivih materija...";
        tacno = "tačno";
        odmjerenu = 'odmjerenu';
        izbjegava = 'izbjegava';
        mjesanjem = 'mješanjem';
        Bezbjednost = 'Bezbjednost';
        bezbjednost = "bezbjednost";
        bezbjednoscu = "bezbjednošću";
        provjere = "provjere";
        bezbjedno = "bezbjedno";
        certifikat = "certifikat";
        provjera = "provjera";
        svjetu = "svjetu";
        gdje = "gdje";
        bezbjedne = "bezbjedne";
        rješenja = "rješenja";
        usmjeravanju = "usmjeravanju";
        sprečavajući = "sprječavajući";
        donijeti = "donijeti";
        break;
    case 'RS':
        headline = "Da li su Suplementi u Obliku Kapsula Bezbedni za Korišćenje?";
        text1 = "Danas su suplementi u obliku kapsula veoma popularni, zahvaljujući njihovoj praktičnosti i efektivnosti u pružanju potrebnih hranljivih materija...";
        tacno = "tačno";
        odmjerenu = "odmerenu";
        izbjegava = 'izbegava';
        mjesanjem = 'mešanjem';
        Bezbjednost = 'Bezbednost';
        bezbjednost = 'bezbednost';
        bezbjednoscu = "bezbednošću";
        provjere = "provere";
        bezbjedno = "bezbedno";
        certifikat = "sertifikat";
        provjera = "provera";
        svjetu = "svetu";
        gdje = "gde";
        bezbjedne = "bezbedne";
        rješenja = "rešenja";
        usmjeravanju = "usmeravanju";
        sprečavajući = "sprečavajući";
        donijeti = "doneti";
        break;
    case 'HR':
        headline = "Da li su Suplementi u Obliku Kapsula Bezbjedni za Korištenje?";
        text1 = "Danas su suplementi u obliku kapsula veoma popularni, zahvaljujući njihovoj praktičnosti i efektivnosti u pružanju potrebnih hranljivih materija...";
        tacno = "točno";
        odmjerenu = 'odmjerenu';
        izbjegava = 'izbjegava';
        mjesanjem = 'mješanjem';
        Bezbjednost = 'Bezbjednost';
        bezbjednost = 'bezbjednost';
        bezbjednoscu = "bezbjednošću";
        provjere = "provjere";
        bezbjedno = "bezbjedno";
        certifikat = "certifikat";
        provjera = "provjera";
        svjetu = "svjetu";
        gdje = "gdje";
        bezbjedne = "bezbjedne";
        rješenja = "rješenja";
        usmjeravanju = "usmjeravanju";
        sprečavajući = "sprječavajući";
        donijeti = "donijeti";
        break;
    case 'ME':
        headline = "Da li su Suplementi u Obliku Kapsula Bezbjedni za Korištenje?";
        text1 = "Danas su suplementi u obliku kapsula veoma popularni, zahvaljujući njihovoj praktičnosti i efektivnosti u pružanju potrebnih hranljivih materija...";
        tacno = "tačno";
        odmjerenu = 'odmjerenu';
        izbjegava = 'izbjegava';
        mjesanjem = 'mješanjem';
        Bezbjednost = 'Bezbjednost';
        bezbjednost = 'bezbjednost';
        bezbjednoscu = "bezbjednošću";
        provjere = "provjere";
        bezbjedno = "bezbjedno";
        certifikat = "certifikat";
        provjera = "provjera";
        svjetu = "svjetu";
        gdje = "gdje";
        bezbjedne = "bezbjedne";
        rješenja = "rješenja";
        usmjeravanju = "usmjeravanju";
        sprečavajući = "sprječavajući";
        donijeti = "donijeti";
        break;
    default:
        break;
}

const BlogGeneralno = () => {
    return <div className="route__blogGeneralno">
        <div className="route__blogGeneralno__wrap">
            <h1>Tajni recept za jače kosti i zglobove krije se u prirodi</h1>
            <p>Svi znamo koliko je važno održavati zdravlje kostiju i zglobova, posebno kako starimo. Jaki i zdravi kosti i zglobovi omogućavaju nam slobodu kretanja, obavljanje svakodnevnih aktivnosti bez bola i očuvanje kvaliteta života. Priroda nam nudi brojna {rješenja} koja mogu značajno pomoći u jačanju kostiju i zaštiti zglobova. U ovom blogu otkrivamo ključne sastojke iz prirode koji podržavaju zdravlje kostiju i zglobova.</p>

            <h2>1. Preslica – Prirodni izvor silicijuma</h2>
            <p>Preslica (Equisetum arvense) je biljka bogata silicijumom, mineralom koji igra važnu ulogu u očuvanju zdravlja kostiju. Silicijum je esencijalan za sintezu kolagena, koji pomaže u jačanju kostiju i zglobova, kao i za njihovu elastičnost. Preslica je posebno korisna za ljude koji žele da održe jake i fleksibilne kosti tokom godina.</p>


            <h2>2. Đumbir – Protivupalni učinak</h2>
            <p>Đumbir (Zingiber officinale) je poznat po svojim snažnim protivupalnim svojstvima, što ga čini odličnim saveznikom u borbi protiv bola i ukočenosti u zglobovima. Redovno konzumiranje đumbira može pomoći u smanjenju upalnih procesa i ublažavanju simptoma artritisa, što doprinosi većoj pokretljivosti i udobnosti.</p>

            <h2>3. Vitamini D3, C i K2 – Vitalni za zdravlje kostiju</h2>
            <p>Vitamini D3, C i K2 su ključni za zdravlje kostiju. Vitamin D3 pomaže apsorpciji kalcijuma, što je presudno za čvrstoću kostiju. Vitamin K2 igra važnu ulogu u {usmjeravanju} kalcijuma ka kostima, {sprečavajući} njegovu akumulaciju u arterijama. Vitamin C je važan za proizvodnju kolagena, proteina koji gradi strukturu kostiju i zglobova.</p>

            <h2>4. Minerali bor i magnezijum – Esencijalni za snagu kostiju</h2>
            <p>Bor i magnezijum su minerali koji imaju ključnu ulogu u očuvanju mineralne gustine kostiju. Magnezijum pomaže u pravilnoj apsorpciji kalcijuma, dok bor podržava funkciju vitamina D i K u organizmu. Ovi minerali, u kombinaciji sa drugim hranljivim sastojcima, pomažu u očuvanju zdravih kostiju i smanjuju rizik od osteoporoze.</p>

            <h2>Podrška uz Valnor® Osteo flex</h2>
            <p><b>Valnor® Osteo flex</b> je pažljivo formulisan dodatak za podršku zdravlju kostiju, obogaćen preslicom, đumbirom, vitaminima D3, C i K2, kao i mineralima borom i magnezijumom. Ova klinički ispitana formula pruža sve što je potrebno za očuvanje čvrstine kostiju i zdravlja zglobova. </p>
            <p><b>Iskoristite specijalni popust od 53%</b> i otkrijte sve prednosti koje <b>Valnor® Osteo flex</b> može {donijeti} vašem skeletnom sistemu.</p>

            <p><b>Zaključak</b></p>
            <p>Priroda nam nudi brojne sastojke koji mogu značajno doprineti jačanju kostiju i očuvanju zdravlja zglobova. Redovna upotreba prirodnih dodataka poput Valnor® Osteo flex, može vam pomoći da održite snagu i fleksibilnost tokom života. Briga o zdravlju kostiju danas znači aktivniji i zdraviji život u budućnosti.</p>
            <p>Pridružite se Valnor zajednici i otkrijte kako priroda može biti vaš najbolji saveznik za zdrave kosti i zglobove!</p>
        </div>
    </div>
};

export default BlogGeneralno;