import React from "react";
import "./index.scss";

import { useNavigate } from "react-router-dom";

import rpcClientModule from "../../modules/rpcClientModule";
import animateModule from "../../modules/animateModule";

import Spinner from "../../components/customComponents/Spinner";


let Ocjena = 'Ocjena';

switch (rpcClientModule.country) {
    case 'BA':
        Ocjena = 'Ocjena';
        break;
    case 'RS':
        Ocjena = 'Ocena';
        break;
    case 'HR':
        Ocjena = 'Ocjena';
        break;
    case 'ME':
        Ocjena = 'Ocjena';
        break;
    default:
        break;
}


const Bundles = () => {
    const [offers, setOffers] = React.useState();

    const curNavigate = useNavigate();
    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    React.useEffect(() => {
        rpcClientModule({
            action: "call",
            method: "offers.f.getAll",
            args: {
                filters: [
                    {name: "ChildOffers", op: "neq", value: null}
                ],
                includeReviews: true
            },
            callback: setOffers
        })
    }, []);

    return <div className="route__bundles">
        <p className="route__bundles__crumb">Shop</p>
        <h4 className="route__bundles__site">Paketi</h4>
        <div className="route__bundles__list">
            {offers?.status === "ok" ? 
                offers?.data?.map(item => {
                    return <div className="route__bundles__list__item" key={item.ID}>
                        <img className="route__bundles__list__item__image" src={item.Images[0]} />
                        <h4 className="route__bundles__list__item__name">{item.Name}</h4>
                        {item.RatingCount > 0 ? <div className="route__bundles__list__item__stars">
                            {(new Array(Math.ceil(item.Rating))).fill(<img src="/images/star.svg" />)}
                            <span>{Number(item.Rating).toFixed(1)}</span>

                            <p>{item.RatingCount} {Ocjena}</p>
                        </div> : <div className="route__bundles__list__item__stars">
                            <span>&nbsp;</span><p>&nbsp;</p>
                        </div>}
                        <div className="route__bundles__list__item__price">
                            <span className="route__bundles__list__item__price--new">{Number(item.NewPrice).toFixed(2)} {item.Currency}</span>
                            <span className="route__bundles__list__item__price--old">{Number(item.OldPrice).toFixed(2)} {item.Currency}</span>
                        </div>
                        <a className="route__bundles__list__item__btn" onClick={() => {
                            animateNavigate(`/offers/${item.Slog}`);
                        }}>Naruči</a>
                    </div>
                })
                : (offers ? <p style={{fontWeight: 900}}>Došlo je do greške!</p> : <Spinner color="#FD4798" style={{width: "32px", height: "32px"}}/>   ) 
            }
        </div>
    </div>
};

export default Bundles;