export const breakpoints = {
    4200: {
        perPage: 5,
    },
    1200: {
        perPage: 4,
    },
    1000: {
        perPage: 2,
    },
    550: {
        perPage: 1,
    }
};