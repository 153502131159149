import React from "react";
import "./index.scss";

import rpcClientModule from "../../modules/rpcClientModule";
import animateModule from "../../modules/animateModule";

import { useNavigate } from "react-router-dom";

const BlogMršavljenje = () => {

    const curNavigate = useNavigate();
    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    let mail, phone, address, text1, text2;

    let vježbanje = "";
    let tijelu = "";
    let vježbama = "";
    let vježbe = "";
    let tjelesnu = "";
    let sprječavaju = "";
    let Vježbe = "";
    let doprineti = "";
    let dijela = "";
    let tijela = "";
    let cjelokupnom = "";

    switch (rpcClientModule.country) {
        case 'BA':
            mail = 'podrska@valnor.ba';
            phone = '+387 35 365 130';
            address = 'Green Side d.o.o. Ćehaje bb, 75350 Srebrenik, Bosna i Hercegovina';
            text1 = `Postizanje zdrave tjelesne težine je dugotrajan proces koji zahtijeva balans između pravilne ishrane, redovne fizičke aktivnosti i emocionalne podrške. Kada se pristupi mršavljenju sa zdravim stavom i jasnim ciljevima, proces postaje lakši i donosi dugoročne rezultate. Mnogi ljudi traže prirodne dodatke kako bi ubrzali proces mršavljenja na zdrav način, bez štetnih posljedica po organizam.`;
            text2 = `Mršavljenje je proces koji zahtijeva dosljednost, strpljenje i pažljivo planiranje...`;
            vježbanje = "vježbanje";
            tijelu = "tijelu";
            vježbama = "vježbama";
            vježbe = "vježbe";
            tjelesnu = "tjelesnu";
            sprječavaju = "sprječavaju";
            Vježbe = "Vježbe";
            doprineti = "doprinijeti";
            dijela = "dijela";
            tijela = "tijela";
            cjelokupnom = "cjelokupnom";
            break;
        case 'RS':
            mail = 'podrska@valnor.rs';
            phone = '+382 257 150 310';
            address = 'Green Side d.o.o. Loznica, Marije Bursać 6, Srbija.';
            text1 = `Postizanje zdrave telesne težine je dugotrajan proces koji zahteva balans između pravilne ishrane, redovne fizičke aktivnosti i emocionalne podrške. Kada se pristupi mršavljenju sa zdravim stavom i jasnim ciljevima, proces postaje lakši i donosi dugoročne rezultate. Mnogi ljudi traže prirodne dodatke kako bi ubrzali proces mršavljenja na zdrav način, bez štetnih posledica po organizam.`;
            text2 = `Mršavljenje je proces koji zahteva doslednost, strpljenje i pažljivo planiranje...`;
            vježbanje = "vežbanje";
            tijelu = "telu";
            vježbama = "vežbama";
            vježbe = "vežbe";
            tjelesnu = "telesnu";
            sprječavaju = "sprečavaju";
            Vježbe = "Vežbe";
            doprineti = "doprineti";
            dijela = "dela";
            tijela = "tela";
            cjelokupnom = "celokupnom";
            break;
        case 'HR':
            mail = 'podrska@valnor.hr';
            phone = '+385 32 373 309';
            address = 'Županja, Trg prof. Martina Robotića 2/2, Županja, Hrvatska';
            text1 = `Postizanje zdrave tjelesne težine je dugotrajan proces koji zahtijeva balans između pravilne ishrane, redovne fizičke aktivnosti i emocionalne podrške. Kada se pristupi mršavljenju sa zdravim stavom i jasnim ciljevima, proces postaje lakši i donosi dugoročne rezultate. Mnogi ljudi traže prirodne dodatke kako bi ubrzali proces mršavljenja na zdrav način, bez štetnih posljedica po organizam.`;
            text2 = `Mršavljenje je proces koji zahtijeva dosljednost, strpljenje i pažljivo planiranje...`;
            vježbanje = "vježbanje";
            tijelu = "tijelu";
            vježbama = "vježbama";
            vježbe = "vježbe";
            tjelesnu = "tjelesnu";
            sprječavaju = "sprječavaju";
            Vježbe = "Vježbe";
            doprineti = "doprinijeti";
            dijela = "dijela";
            tijela = "tijela";
            cjelokupnom = "cjelokupnom";
            break;
        case 'ME':
            mail = 'podrska@valnor.me';
            phone = '+382 204 144 70';
            address = 'Green Side d.o.o. UL. Admirala Zmajevića br. 81, Stari Aerodrom, Podgorica';
            text1 = `Postizanje zdrave tjelesne težine je dugotrajan proces koji zahtijeva balans između pravilne ishrane, redovne fizičke aktivnosti i emocionalne podrške. Kada se pristupi mršavljenju sa zdravim stavom i jasnim ciljevima, proces postaje lakši i donosi dugoročne rezultate. Mnogi ljudi traže prirodne dodatke kako bi ubrzali proces mršavljenja na zdrav način, bez štetnih posljedica po organizam.`;
            text2 = `Mršavljenje je proces koji zahtijeva dosljednost, strpljenje i pažljivo planiranje...`;
            vježbanje = "vježbanje";
            tijelu = "tijelu";
            vježbama = "vježbama";
            vježbe = "vježbe";
            tjelesnu = "tjelesnu";
            sprječavaju = "sprječavaju";
            Vježbe = "Vježbe";
            doprineti = "doprinijeti";
            dijela = "dijela";
            tijela = "tijela";
            cjelokupnom = "cjelokupnom";
            break;
        default:
            mail = 'podrska@valnor.ba';
            phone = '+387 35 365 130';
            address = 'Green Side d.o.o. Ćehaje bb, 75350 Srebrenik, Bosna i Hercegovina';
            text1 = `Postizanje zdrave tjelesne težine je dugotrajan proces koji zahtijeva balans između pravilne ishrane, redovne fizičke aktivnosti i emocionalne podrške. Kada se pristupi mršavljenju sa zdravim stavom i jasnim ciljevima, proces postaje lakši i donosi dugoročne rezultate. Mnogi ljudi traže prirodne dodatke kako bi ubrzali proces mršavljenja na zdrav način, bez štetnih posljedica po organizam.`;
            text2 = `Mršavljenje je proces koji zahtijeva dosljednost, strpljenje i pažljivo planiranje...`;
            vježbanje = "vježbanje";
            tijelu = "tijelu";
            vježbama = "vježbama";
            vježbe = "vježbe";
            tjelesnu = "tjelesnu";
            sprječavaju = "sprječavaju";
            Vježbe = "Vježbe";
            doprineti = "doprinijeti";
            dijela = "dijela";
            tijela = "tijela";
            cjelokupnom = "cjelokupnom";
            break;
    };

    return <div className="route__blogMršavljenje">
        <div className="route__blogMršavljenje__wrap">
            <h1 className="route__blogMršavljenje__wrap__title">Kako fizička aktivnost utiče na zdravlje prostate</h1>
            <p className="route__blogMršavljenje__wrap__text">Zdravlje prostate je izuzetno važno za sve muškarce, a fizička aktivnost ima ključnu ulogu u njegovom očuvanju. Redovno {vježbanje} ne samo da doprinosi opštem zdravlju, već pruža i posebne benefite za prostatu. U nastavku ćemo objasniti kako različite vrste aktivnosti pozitivno utiču na prostatu.</p>
            <h2 className="route__blogMršavljenje__wrap__subTitle">Aerobne aktivnosti za bolju cirkulaciju</h2>
            <p className="route__blogMršavljenje__wrap__text">Aerobne aktivnosti, kao što su brzo hodanje, trčanje i plivanje, poboljšavaju cirkulaciju i smanjuju upalne procese u {tijelu}, uključujući prostatu. Redovnim aerobnim {vježbama} smanjuje se rizik od uvećanja prostate, što je čest problem kod starijih muškaraca. Ove {vježbe} održavaju optimalnu {tjelesnu} težinu i {sprječavaju} pritisak na prostatu, pomažući joj da pravilno funkcioniše.</p>
            <h2 className="route__blogMršavljenje__wrap__subTitle">{Vježbe} snage i hormonski balans</h2>
            <p className="route__blogMršavljenje__wrap__text">Pored aerobnih aktivnosti, trening snage može {doprineti} zdravlju prostate tako što podstiče prirodnu proizvodnju testosterona. {Vježbe} snage poput čučnjeva, sklekova i mrtvog dizanja jačaju mišiće donjeg {dijela} {tijela}, smanjuju {tjelesnu} masnoću i podstiču bolju funkciju prostate.</p>
            <h2 className="route__blogMršavljenje__wrap__subTitle">Smanjenje stresa kroz fizičku aktivnost</h2>
            <p className="route__blogMršavljenje__wrap__text">Stres ima negativan uticaj na zdravlje prostate, jer povećava nivo kortizola, hormona stresa, što može dovesti do upala. Redovna fizička aktivnost, joga i tehnike disanja efikasno smanjuju stres i održavaju hormonalni balans. Ovo ne samo da doprinosi zdravlju prostate, već i {cjelokupnom} mentalnom zdravlju.</p>
            <div className="route__blogMršavljenje__wrap__imageBox">
                <img src="/images/prostavital.png" />
            </div>
            <div className="route__blogMršavljenje__wrap__btn" onClick={() => {
                animateNavigate("/offers/pure-fit");
            }}>Naruči Prosta Vital</div>
            <h2 className="route__blogMršavljenje__wrap__subTitle">Podrška prostati uz Valnor® Prosta Vital</h2>
            <p className="route__blogMršavljenje__wrap__text">Pored fizičke aktivnosti, ključnu ulogu u očuvanju zdravlja prostate može imati i pravilan dodatak ishrani. Valnor® Prosta Vital je specijalno formulisan proizvod za podršku zdravlju prostate, obogaćen prirodnim ekstraktima poput testeraste palme i koprive, koji pomažu u normalnom funkcionisanju prostate i smanjenju upalnih procesa.</p>
            <p className="route__blogMršavljenje__wrap__text"><b>Posebna ponuda:</b> Iskoristite popust od 53% na <b>Valnor® Prosta Vital</b> i unapredite svoju rutinu za podršku zdravlju prostate.</p>
            <p className="route__blogMršavljenje__wrap__text">
                <b>Zaključak</b>
                <br/>
                Redovna fizička aktivnost u kombinaciji sa prirodnim dodacima poput Valnor® Prosta Vitala može značajno {doprineti} zdravlju prostate i poboljšati kvalitet života. Bilo da birate aerobne {vježbe}, trening snage ili tehnike opuštanja, briga o prostati kroz zdravu rutinu i pravilne dodatke je korak ka snažnijem i zdravijem životu.
                <br></br>
                Pridružite se Valnor zajednici i pronađite podršku na svakom koraku ka boljem zdravlju!
            </p>
        </div>
    </div>
};

export default BlogMršavljenje;