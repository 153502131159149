import React from "react";
import "./index.scss";

import PhoneInput from 'react-phone-input-2';
import { countryPrefixes } from "../../modules/countryModule";
import 'react-phone-input-2/lib/style.css'

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import rpcClientModule from "../../modules/rpcClientModule";
import animateModule from "../../modules/animateModule";
import rpcClient from "../../modules/rpcClientModule";

import * as userDataActions from "../../actions/userDataActions";
import * as timestmapActions from "../../actions/timestampActions";

import Spinner from "../../components/customComponents/Spinner";


const ResetPassword = () => {

    const curNavigate = useNavigate();
    const curDispatch = useDispatch();

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    const [loginPage, setLoginPage] = React.useState(1);
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState('');
    const [isSend, setIsSend] = React.useState(false);
    const [phoneNumber, setPhoneNumber] = React.useState('');


    const emailRef = React.useRef();
    const phoneRef = React.useRef();
    const tokenRef = React.useRef();
    const passwordRef = React.useRef();


    const generate = () => {

        let value = ''

        if(emailRef.current.value){
            value = emailRef.current.value
        }else{
            value = phoneNumber
        }
        
        setSpinner(true);

        rpcClient({
            action: "call",
            method: "auth.generateToken",
            params: {
                Email: value
            }
        }).then(res => {

            if (res.status === "error" && res.data === "RPC_CLIENT_ERROR") {
                curDispatch(userDataActions.setUserData(null, null));
                setTimeout(() => curDispatch(timestmapActions.updateTimestamp()), 2000);
            }
            if (res.status === "ok") {
                setIsSend(true)
            }

            // if (res.status === "ok") {
            //     curDispatch(timestmapActions.updateTimestamp());
            //     checkLogin()
            //     animateNavigate("/");
            // } else {
            //     setSpinner(false)
            //     return setInfoP('Pogrešno korisničko ime ili lozinka');
            // }
        })
        setSpinner(false);
    }

    const reset = () => {

        let value = ''

        if(emailRef.current.value){
            value = emailRef.current.value
        }else{
            value = phoneNumber
        }


        setSpinner(true);

        rpcClient({
            action: "call",
            method: "auth.resetPassword",
            params: {
                Email: value,
                Token: tokenRef.current.value,
                NewPassword: passwordRef.current.value
            }
        }).then(res => {

            if (res.status === "error" && res.data === "RPC_CLIENT_ERROR") {
                curDispatch(userDataActions.setUserData(null, null));
                setTimeout(() => curDispatch(timestmapActions.updateTimestamp()), 2000);
            }

            if (res.status === "ok") {
                curDispatch(timestmapActions.updateTimestamp());
                animateNavigate("/login");
            } else {
                setSpinner(false)
                return setInfoP('Informacije su pogrešno unešene');
            }
        })
        setSpinner(false);
    }






    return <div className="route__login">
        <p className="route__login__headline">Resetujte password</p>
        <input ref={emailRef} placeholder="Unesite vas email"></input>
        <p>ili</p>
        <PhoneInput
            onlyCountries={['ba', 'rs', 'me', 'hr']}
            localization={countryPrefixes}
            country={rpcClientModule.country.toLowerCase()}
            value={phoneNumber}
            onChange={phone => setPhoneNumber(phone)}
        />
        {isSend ? <>
            <input ref={tokenRef} placeholder="Unesite kod koji ste dobili"></input>
            <input ref={passwordRef} type="password" placeholder="Unesite vasu novu lozinku"></input>
            <p onClick={() => reset()} className="route__login__signIn">Resetuj password</p>
        </> : <>
            
            <p onClick={() => generate()} className="route__login__signIn">Pošalji e-mail</p>
        </>}

        <p>{infoP}</p>
    </div>
}

export default ResetPassword