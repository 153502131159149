import React from "react";
import "./index.scss";

import rpcClientModule from "../../modules/rpcClientModule";

let mail, phone, address, narudžbama, Ponedjeljak;

switch (rpcClientModule.country) {
    case 'BA':
        mail = 'podrska@valnor.ba';
        phone = '+387 35 365 130';
        address = 'Green Side d.o.o. Ćehaje bb, 75350 Srebrenik, Bosna i Hercegovina';
        narudžbama = 'narudžbama';
        Ponedjeljak = 'Ponedjeljak';
        break;
    case 'RS':
        mail = 'podrska@valnor.rs';
        phone = '+382 257 150 310';
        address = 'Green Side d.o.o. Loznica, Marije Bursać 6, Srbija.';
        narudžbama = 'porudžbinama';
        Ponedjeljak = 'Ponedeljak';
        break;
    case 'HR':
        mail = 'podrska@valnor.hr';
        phone = '+385 32 373 309';
        address = 'Županja, Trg prof. Martina Robotića 2/2, Županja, Hrvatska';
        narudžbama = 'narudžbama';
        Ponedjeljak = 'Ponedjeljak';
        break;
    case 'ME':
        mail = 'podrska@valnor.me';
        phone = '+382 204 144 70';
        address = 'Green Side d.o.o. UL. Admirala Zmajevića br. 81, Stari Aerodrom, Podgorica';
        narudžbama = 'narudžbama';
        Ponedjeljak = 'Ponedjeljak';
        break;
    default:
        mail = 'podrska@valnor.ba';
        phone = '+387 35 365 130';
        address = 'Green Side d.o.o. Ćehaje bb, 75350 Srebrenik, Bosna i Hercegovina';
        narudžbama = 'narudžbama';
        Ponedjeljak = 'Ponedjeljak';
        break;
}

const ContactUs = () => {

    return <div className="route__contactUs">
        <p className="route__contactUs__headline">Kontakrirajte nas - Valnor</p>
        <p className="route__contactUs__basic">Tu smo da vam pomognemo sa svim pitanjima ili nedoumicama u vezi s našim proizvodima, {narudžbama} ili uslugama.</p>
        <p className="route__contactUs__basic">Molimo kontaktirajte naš tim za podršku putem:</p>
        <p className="route__contactUs__basic"><b>Email:</b> <a href={`mailto:${mail}`}>{mail}</a></p>
        <p className="route__contactUs__basic"><b>Telefon:</b> {phone}</p>
        <p className="route__contactUs__basic"><b>Radni sati:</b> {Ponedjeljak} - Petak, 8:00 - 17:00</p>
        <p className="route__contactUs__basic">Naš tim je posvećen pružanju brzih i korisnih odgovora na sve upite.</p>
        <p className="route__contactUs__basic">{address}</p>
    </div>
}

export default ContactUs