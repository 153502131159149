import React from "react";
import "./index.scss";

import PhoneInput from 'react-phone-input-2';
import { countryPrefixes } from "../../modules/countryModule";
import 'react-phone-input-2/lib/style.css'

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import rpcClientModule from "../../modules/rpcClientModule";
import animateModule from "../../modules/animateModule";
import rpcClient from "../../modules/rpcClientModule";

import * as userDataActions from "../../actions/userDataActions";
import * as timestmapActions from "../../actions/timestampActions";

import Spinner from "../../components/customComponents/Spinner";

import SuccessfullRegisterModal from "../../components/Modals/SuccessfullRegisterModal";
import { animateBox } from "../../modules/componentAnimation";

let text1 = '';
let text2 = '';
let text3 = '';
let text4 = '';
let text5 = '';
let text6 = '';
let text7 = '';
let text8 = '';
let text9 = '';
let text10 = '';
let text11 = '';

switch (rpcClientModule.country) {
    case 'BA':
        text1 = 'Prijavi se';
        text2 = 'Email';
        text3 = 'Lozinka';
        text4 = 'Zaboravili ste lozinku ?';
        text5 = 'Prijavi se';
        text6 = 'Napravi račun';
        text7 = 'Ime';
        text8 = 'Prezime';
        text9 = 'Kreiraj';
        text10 = 'Email';
        text11 = 'Broj telefona';
        break;
    case 'RS':
        text1 = 'Prijavi se';
        text2 = 'Email';
        text3 = 'Lozinka';
        text4 = 'Zaboravili ste lozinku ?';
        text5 = 'Prijavi se';
        text6 = 'Napravi račun';
        text7 = 'Ime';
        text8 = 'Prezime';
        text9 = 'Kreiraj';
        text10 = 'Email';
        text11 = 'Broj telefona';
        break;
    case 'HR':
        text1 = 'Prijavi se';
        text2 = 'Email';
        text3 = 'Lozinka';
        text4 = 'Zaboravili ste lozinku ?';
        text5 = 'Prijavi se';
        text6 = 'Napravi račun';
        text7 = 'Ime';
        text8 = 'Prezime';
        text9 = 'Kreiraj';
        text10 = 'Email';
        text11 = 'Broj telefona';
        break;
    case 'ME':
        text1 = 'Prijavi se';
        text2 = 'Email';
        text3 = 'Lozinka';
        text4 = 'Zaboravili ste lozinku ?';
        text5 = 'Prijavi se';
        text6 = 'Napravi račun';
        text7 = 'Ime';
        text8 = 'Prezime';
        text9 = 'Kreiraj';
        text10 = 'Email';
        text11 = 'Broj telefona';
        break;
    default:
        break;
}

let loginTimeout = null;
const Login = () => {

    const curNavigate = useNavigate();
    const curDispatch = useDispatch();

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    const [loginPage, setLoginPage] = React.useState(1);
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState('');

    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [showPassword, setShowPassword] = React.useState(false);

    const firstNameRef = React.useRef();
    const lastNameRef = React.useRef();
    const passwordRef = React.useRef();
    const usernameRef = React.useRef();

    const checkLogin = () => {
        let data = {
            username: usernameRef.current.value ? usernameRef.current.value : phoneNumber,
            password: passwordRef.current.value
        };

        if (!data.username || (data.username.length < 8 || data.username.length > 64)) {
            return setInfoP('Korisničko ime mora biti popunjeno i duže od 8 slova ili broj telefona unešen');
        };
        if (!data.password || (data.password.length < 8 || data.password.length > 64)) {
            return setInfoP('Lozinka mora bit popunjena i duža od 8 slova');
        };

        setSpinner(true);

        rpcClient({
            action: "call",
            method: "auth.loginShop",
            params: data
        }).then(res => {

            if (res.status === "error" && res.data === "RPC_CLIENT_ERROR") {
                curDispatch(userDataActions.setUserData(null, null));
                setTimeout(() => curDispatch(timestmapActions.updateTimestamp()), 2000);

                loginTimeout = setTimeout(checkLogin, 2000);
            }

            if (res.status === "ok") {
                curDispatch(timestmapActions.updateTimestamp());
                animateNavigate("/");
            } else {
                console.log("pozvan u elseu ", res)
                setSpinner(false)
                return setInfoP('Pogrešnan email ili lozinka');
            }
        })
        setSpinner(false);
    }

    const registerUser = () => {
        let data = {
            Username: usernameRef.current.value,
            Email: usernameRef.current.value,
            Password: passwordRef.current.value,
            PhoneNumber: phoneNumber,
            FirstName: firstNameRef.current.value,
            LastName: lastNameRef.current.value,
        };

        if(!data.Username) {
            return setInfoP("Molimo unesite vaš email");
        }

        const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
        if (data.Username && !emailRegex.test(data.Username)) {
            return setInfoP("Email mora biti u odgovarajućem formatu.");
        }

        if (!data.Password || (data.Password.length < 8 || data.Password.length > 64)) {
            return setInfoP('Lozinka mora bit popunjena i duža od 8 slova');
        };

        if(!data.PhoneNumber) {
            return setInfoP("Molimo unesite vaš broj telefona");
        }

        const phoneRegex = /^\+?(387|385|381|382|06)?[ -]?\d{1,2}?[ -]?\d{3}[ -]?\d{3,4}$/;
        if (data.PhoneNumber && !phoneRegex.test(data.PhoneNumber)) {
            return setInfoP("Broj telefona mora biti u odgovarajućem formatu.");
        }


        setSpinner(true);

        rpcClient({
            action: "call",
            method: "shopUsers.f.add",
            params: data
        }).then(res => {

            if (res.status === "error" && res.data === "RPC_CLIENT_ERROR") {
                curDispatch(userDataActions.setUserData(null, null));
                setTimeout(() => curDispatch(timestmapActions.updateTimestamp()), 2000);

                loginTimeout = setTimeout(checkLogin, 2000);
            }

            if (res.status === "ok") {
                animateBox(<SuccessfullRegisterModal animateNavigate={animateNavigate}/>)
                curDispatch(timestmapActions.updateTimestamp());
                checkLogin()
                // animateNavigate("/");

            }else if(res.data === 'USERNAME_EXISTS'){
                setSpinner(false)
                return setInfoP('Račun sa navedenim emailom postoji');
            }else if(res.data === 'PHONE_EXISTS'){
                setSpinner(false)
                return setInfoP('Račun sa navedenim brojem telefona postoji');
            }else {
                console.log("pozvan res u elsu ", res)
                setSpinner(false)
                return setInfoP('Pogrešan email ili lozinka');
            }
        })
        setSpinner(false);
    }


    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            if(loginPage){
                checkLogin();
            }else{
                registerUser();
            }
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword((s) => !s);
    };


    return <div className="route__login">
        <p className="route__login__headline">{ loginPage ? `${text1}` : `${text6}`}</p>
        <input ref={usernameRef} placeholder={`${text10}*`}></input>
        <p>ili</p>
        <PhoneInput
            onlyCountries={['ba', 'rs', 'me', 'hr']}
            localization={countryPrefixes}
            country={rpcClientModule.country.toLowerCase()}
            value={phoneNumber}
            onChange={phone => setPhoneNumber(phone)}
        />
        <div style={{ position: "relative" }}>
            <input onKeyDown={(e) => handleKeyDown(e)} type={showPassword ? "text" : "password"} ref={passwordRef} placeholder={`${text3}*`}></input>
            <span onClick={togglePasswordVisibility}
                style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer"
                }}
            >
                {showPassword ? "👁️" : "👁️‍🗨️"}
            </span>
        </div>
        {!loginPage && <input ref={firstNameRef} placeholder={`${text7}`}></input>}
        {!loginPage && <input ref={lastNameRef} placeholder={`${text8}`}></input>}


        {/* {!loginPage && <PhoneInput
            onlyCountries={['ba', 'rs', 'me', 'hr']}
            localization={countryPrefixes}
            country={rpcClientModule.country.toLowerCase()}
            value={phoneNumber}
            onChange={phone => setPhoneNumber(phone)}
        />} */}

        {loginPage && <p onClick={()=>{animateNavigate("/forgotPassword")}} className="route__login__forgotPassword">{text4}</p>}


        {loginPage ? <p onClick={() => checkLogin()} className="route__login__signIn">{spinner ? <Spinner /> : text5}</p> : 
        <p onClick={() => registerUser()} className="route__login__signIn">{spinner ? <Spinner /> : text9}</p>}
        
        
        <p style={{"text-align": "center"}} className="route__login__forgotPassword" onClick={() => setLoginPage(d => !d)}
        >{ loginPage ? `${text6}` : `${text5}`}</p>

        <p>{infoP}</p>
    </div>
}

export default Login