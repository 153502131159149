import React from "react";
import "./index.scss";

import { useParams } from "react-router-dom";
import rpcClientModule from "../../modules/rpcClientModule";
import { useLocation, useNavigate } from 'react-router-dom';
import Spinner from "../../components/customComponents/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { animateBox } from "../../modules/componentAnimation";
import * as cartActions from "../../actions/cartActions";
import CartModal from "../../components/CartModal";
import Dropdown from "../../components/customComponents/Dropdown"
import moment from "moment";
import { recensionAmounts } from "../../recensionAmount";

//

let Podjelite = 'Podjelite';
let Ocjenite = 'Ocjenite';

switch (rpcClientModule.country) {
    case 'BA':
        Podjelite = 'Podjelite';
        Ocjenite = 'Ocjenite';
        break;
    case 'RS':
        Podjelite = 'Podelite';
        Ocjenite = 'Ocenite';
        break;
    case 'HR':
        Podjelite = 'Podjelite';
        Ocjenite = 'Ocjenite';
        break;
    case 'ME':
        Podjelite = 'Podjelite';
        Ocjenite = 'Ocjenite';
        break;
    default:
        break;
}

const Offer = () => {
    const [offer, setOffer] = React.useState();
    const [reviews, setReviews] = React.useState();
    const [rating, setRating] = React.useState();
    const [selectedImage, setSelectedImage] = React.useState(0);
    const [selectedDesc, setSelectedDesc] = React.useState(0);
    const [activeAcc, setActiveAcc] = React.useState();
    const [quantity, setQuantity] = React.useState(1);
    const [order, setOrder] = React.useState();
    const [advantages, setAdvatages] = React.useState();
    const [finalRecensionAmount, setFinalRecensionAmount] = React.useState('');
    const location = useLocation();
    const offerSlog = location.pathname.split("/").pop();

    let dispatch = useDispatch();
    const curNavigate = useNavigate();
    const cartData = useSelector((state) => state.cartReducer.cart ?? []);
    const curParams = useParams();



    React.useEffect(() => {
        if (!curParams?.slog) return;
        setOffer();
        setSelectedImage(0);
        setSelectedDesc(0);
        rpcClientModule({
            action: "call",
            method: "offers.f.getSlog",
            params: { Slog: curParams.slog },
            callback: res => {
                if (res.status === "ok") {
                    setOffer(res.data);

                };
            }
        }).then(offer => {
            if(offer?.data?.Adventages){
                setAdvatages(offer.data.Adventages.split("&&"));
            }
        })
    }, [curParams.slog]);

    React.useEffect(() => {
        if (!offer) return;

        let orderTmp = ["createdAt", "asc"];
        switch (order) {
            case 1:
                orderTmp = "files"
                break;
            case 2:
                orderTmp = ["createdAt", "desc"]
                break;
            case 3:
                orderTmp = ["createdAt", "asc"]
                break;
            case 4:
                orderTmp = ["Rating", "desc"]
                break;
            case 5:
                orderTmp = ["Rating", "asc"]
                break;
            default: break;
        }
        rpcClientModule({
            action: "call",
            method: "shopReviews.getAll",
            args: {
                OfferID: offer.ID,
                orders: orderTmp
            },
            callback: d => {
                if (d.status === "ok") {
                    setReviews(d.data)
                    let tmpRating = 0;
                    for (let single of d.data) {
                        tmpRating += single.Rating;
                    }
                    setRating(tmpRating / d.data.length);
                    let recAmm = recensionAmounts?.find(el => el.name === offerSlog);
                    setFinalRecensionAmount(Number(recAmm.amount) + Number(d.data.length))
                }

            }
        });
    }, [offer, order])

    const scrollToCenter = (e) => {
        if (e.target) {
            e.target.scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "center"
            });
        }
    };

    return <div className="route__offer">
        {offer ? <>
            <p className="route__offer__head">{offer.Heading}</p>
            <p className="route__offer__subhead">{offer.SubHeading}</p>
            <p className="route__offer__route">Offers&gt;{offer.Group}</p>
            <div className="route__offer__form">
                <div className="route__offer__form__images">
                    <img className="route__offer__form__images__selected" src={offer.Images[selectedImage]} />
                    <div className="route__offer__form__images__container">
                        {offer.Images.map((img, index) => {
                            return <img onClick={(e) => {
                                setSelectedImage(index);
                                scrollToCenter(e)
                            }} src={img} className={index === selectedImage ? `route__offer__form__images__container-active` : ""} />
                        })}
                    </div>
                </div>
                <div className="route__offer__form__content">
                    <p className="route__offer__form__content__head">{offer.Name}</p>
                    <p className="route__offer__form__content__subhead">{offer.SubHeading}</p>

                    {reviews ? <p className="route__offer__form__content__reviews">{"★".repeat(Math.ceil(rating))} {finalRecensionAmount} Ocjena</p> : null}
                    <div className="route__offer__form__content__buttons">
                        <button onClick={() => {
                            setSelectedDesc(0)
                        }} className={selectedDesc === 0 ? `route__offer__form__content__buttons-active` : ""}>Opis</button>
                        <button onClick={() => {
                            setSelectedDesc(1)
                        }} className={selectedDesc === 1 ? `route__offer__form__content__buttons-active` : ""}>Prednosti</button>
                        <button onClick={() => {
                            setSelectedDesc(2)
                        }} className={selectedDesc === 2 ? `route__offer__form__content__buttons-active` : ""}>Kako koristiti</button>
                    </div>
                    <p className="route__offer__form__content__desc">{selectedDesc === 0 ? offer.Description : selectedDesc === 1 ? advantages.map(el => {
                        return <span style={{display: 'block', lineHeight: '20px'}}>{el}</span>
                    }) : offer.HowToUse}</p>
                    <p className="route__offer__form__content__stock">Zaliha: {offer.Stock} dana</p>
                    <p className="route__offer__form__content__price">
                        <span>{Number(offer.OldPrice).toFixed(2)} {offer.Currency}</span> &nbsp; &nbsp;
                        {Number(offer.NewPrice).toFixed(2)} {offer.Currency} &nbsp; &nbsp;
                        <strong>Uštedi {Number(offer.OldPrice - offer.NewPrice).toFixed(2)} {offer.Currency}</strong>
                    </p>
                    <div className="route__offer__form__content__order">
                        <p>Količina</p>
                        <p>
                            <span onClick={() => { if (quantity > 1) setQuantity(prevCount => prevCount - 1) }}>-</span>
                            {quantity}
                            <span onClick={() => { setQuantity(prevCount => prevCount + 1) }}>+</span>
                        </p>
                        <p onClick={() => {
                            cartData.push({ ID: offer.ID, quantity });
                            dispatch(cartActions.updateCart(cartData));
                            if (cartData) {
                                localStorage.setItem('cart', JSON.stringify(cartData));
                                animateBox(<CartModal navigate={curNavigate} />);
                            }
                        }}>Dodaj u korpu</p>
                    </div>

                </div>
            </div>
            <div className="route__offer__accordions">
                <div className={activeAcc === 0 ? `route__offer__accordions__single-active route__offer__accordions__single` : "route__offer__accordions__single"}>
                    <p onClick={() => {
                        if (activeAcc === 0) {
                            setActiveAcc()
                        } else {
                            setActiveAcc(0)
                        }
                    }}>Informacije o proizvodu {activeAcc === 0 ? <span>-</span> : <span>+</span>}</p>
                    <div>
                        <p>{offer.Description}</p>
                        <p>Prednosti</p>
                        {offer.Benefits.map(elem => {
                            return <p>✅ {elem}</p>
                        })}
                    </div>
                </div>
                <div onClick={() => {
                    if (activeAcc === 1) {
                        setActiveAcc()
                    } else {
                        setActiveAcc(1)
                    }
                }} className={activeAcc === 1 ? `route__offer__accordions__single-active route__offer__accordions__single` : "route__offer__accordions__single"}>
                    <p>Profil sastojaka {activeAcc === 1 ? <span>-</span> : <span>+</span>}</p>
                    <div>
                        <img src={offer.IngredientsImage} />
                    </div>
                </div>
                <div onClick={() => {
                    if (activeAcc === 2) {
                        setActiveAcc()
                    } else {
                        setActiveAcc(2)
                    }
                }} className={activeAcc === 2 ? `route__offer__accordions__single-active route__offer__accordions__single` : "route__offer__accordions__single"}>
                    <p>90  dana garancija povrata novca {activeAcc === 2 ? <span>-</span> : <span>+</span>}</p>
                    <div>
                        <p>Ako iz bilo kog razloga niste uživali u iskustvu s našim proizvodima, pošaljite ih nazad u roku od 90 dana za povrat novca.</p>
                    </div>
                </div>
            </div>
            <div className="route__offer__recensions">
                <div className="route__offer__recensions__head">
                    <div className="route__offer__recensions__head__left">
                        <p className="route__offer__recensions__head__left__rc">Recenzije</p>
                        <p className="route__offer__recensions__head__left__stars">{rating? rating?.toFixed(2) : 0}
                            {[1, 2, 3, 4, 5].map((elem, index) => {
                                if (elem <= rating) {
                                    return <img src="/images/star.svg" />
                                } else if (elem < rating && elem > rating - 1) {
                                    return <img src="/images/pinkStarHalf.svg" />
                                } else {
                                    return <img src="/images/pinkStarEmpty.svg" />
                                }
                            })}

                        </p>
                        <p className="route__offer__recensions__head__left__based">
                            Bazirano na {finalRecensionAmount ?? 0} recenzija
                        </p>
                        <div className="route__offer__recensions__head__left__sort">
                            <p>Sortiraj po:</p>
                            <Dropdown selected={1} data={[
                                { name: "Sa file-ovima", value: 1 },
                                { name: "Najnoviji", value: 2 },
                                { name: "Najstariji", value: 3 },
                                { name: "Najbolja ocjena", value: 4 },
                                { name: "Najlošija ocjena", value: 5 }
                            ]} onChange={(e) => {
                                setOrder(e.value);
                            }} />
                        </div>

                    </div>
                    <button className="route__offer__recensions__head__button" onClick={(e) => {
                        animateBox(e, <AddRecension ID={offer.ID} />)
                    }}>Ostavi recenziju</button>
                </div>
                <div className="route__offer__recensions__content">
                    {reviews && reviews.map(review => {
                        if(!review.IsApproved) return;
                        return <div className="route__offer__recensions__content__comment" bis_skin_checked="1">
                            <div className="route__offer__recensions__content__comment__left" bis_skin_checked="1">
                                <p className="route__offer__recensions__content__comment__left__first">
                                    <img src="./images/verified.svg" /> Verifikovan kupac
                                </p>
                                <p className="route__offer__recensions__content__comment__left__second">{review._User.FirstName} {review._User.LastName}</p>
                            </div>
                            <div className="route__offer__recensions__content__comment__main" bis_skin_checked="1">
                                <div className="route__offer__recensions__content__comment__main__stars" bis_skin_checked="1">
                                    {[1, 2, 3, 4, 5].map((elem, index) => {
                                        if (elem <= review.Rating) {
                                            return <img src="/images/star.svg" />
                                        } else if (elem < review.Rating && elem > review.Rating - 1) {
                                            return <img src="/images/pinkStarHalf.svg" />
                                        } else {
                                            return <img src="/images/pinkStarEmpty.svg" />
                                        }
                                    })}
                                </div>
                                <p className="route__offer__recensions__content__comment__main__head">{review.ReviewHead}</p>
                                <p className="route__offer__recensions__content__comment__main__text">{review.Review}</p>
                                {review.Attachments.length > 0 && <img className="route__offer__recensions__content__comment__main__image" src={review.Attachments[0]} />}
                                {/* <p className="route__offer__recensions__content__comment__main__help">
                                    Da li je ova recenzija bila od pomoći?
                                    <span>
                                        <img className="likeImg " src="./images/like.svg" onclick="incrementLike(event, 1)" />
                                        <span id="likeNum-1" className="likeNum">
                                            {moment(review.createdAt).isAfter(moment().add(-5, "days"))}
                                            </span> osoba smatra ovu recenziju korisnom
                                    </span>
                                </p> */}
                                <p className="route__offer__recensions__content__comment__main__date dateToday">{moment(review.createdAt).format("DD.MM.YYYY HH:mm")}</p>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </> : <Spinner />}
    </div>
};


const AddRecension = (props) => {

    const userDataSelector = useSelector(state => state?.userData ?? {});

    const [selectedStar, setSelectedStar] = React.useState(0);
    const [selectedImage, setSelectedImage] = React.useState();
    const [errorMsg, setErrorMsg] = React.useState();
    const commentRef = React.useRef();
    const headRef = React.useRef();
    const checkboxRef = React.useRef();

    const onFileSelected = e => {
        if (e.target.files.length === 0) return;
        let file = e.target.files[0];
        let fileReader = new FileReader();
        // fileReader.onload = fr => {
        //     setSelectedImage(fr.target.result);
        // };
        fileReader.readAsDataURL(e.target.files[0]);
        uploadImage(file)
    };

    const uploadImage = (file) => {
        setErrorMsg("");

        let data = {
            ImageName: file.name,
            ImageTag: "offer",
            ImageFile: file
        };
        if (!data.ImageName) return setErrorMsg("Ime ne može biti prazno");
        if (!data.ImageFile) return setErrorMsg("Odaberite privitak");

        rpcClientModule({
            action: "call",
            method: "staticContent.images.f.upload",
            args: { ...data },
            callback: d => {
                if (d.status === "ok") {
                    setSelectedImage(d.data)
                }

            }
        });
    };

    const leaveComment = () => {
        setErrorMsg("");
        let data = {
            OfferID: props.ID,
            Rating: selectedStar,
            Review: commentRef.current.value,
            ReviewHead: headRef.current.value,
            Attachments: [selectedImage].filter(f => f)
        };
        if (!data.Review) return setErrorMsg("Unesite vaš kometar");

        rpcClientModule({
            action: "call",
            method: "shopReviews.add",
            args: { ...data },
            callback: d => {
                if (d.status === "ok") {
                    props.onClose();
                } else {
                    setErrorMsg("Došlo je do greške prilikom spremanja kometara, molimo pokušajte ponovo kasnije")
                }

            }
        });
    };
    const renderStars = () => {
        return Array(5).fill(null).map((_, index) => (
            <svg
                key={index}
                data-star={index + 1}
                className="yotpo-star-rating-icon yotpo-sr-star-empty yotpo-star-rating-icon"
                width="30"
                height="30"
                viewBox="0 0 33 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                transform="scale(1.2, 1.2)"
                tabIndex="0"
                role="radio"
                aria-checked={selectedStar >= index + 1}
                aria-label={`Score ${index + 1}`}
                onClick={() => setSelectedStar(index + 1)}
            >
                <defs>
                    <linearGradient id={`yotpo_stars_gradient_${index}`}>
                        <stop offset="0%" stopColor={selectedStar >= index + 1 ? "#61498D" : "white"}></stop>
                        <stop offset="100%" stopColor={selectedStar >= index + 1 ? "#61498D" : "white"} stopOpacity="1"></stop>
                    </linearGradient>
                </defs>
                <path
                    d="M17.0919 25.4549L16.8335 25.299L16.5751 25.4549L7.39263 30.9971L9.82942 20.5516L9.89798 20.2577L9.66988 20.0601L1.55658 13.0315L12.2393 12.1252L12.5397 12.0997L12.6574 11.8221L16.8335 1.9688L21.0096 11.8221L21.1273 12.0997L21.4277 12.1252L32.1104 13.0315L23.9971 20.0601L23.769 20.2577L23.8376 20.5516L26.2744 30.9971L17.0919 25.4549Z"
                    stroke={selectedStar >= index + 1 ? "#61498D" : "rgba(97,73,141,1)"}
                    fill={`url(#yotpo_stars_gradient_${index})`}
                />
            </svg>
        ));
    };

    return <div className="route__offer__recensions__add">
        {/* //OVDJE NASTAVI */}
        {userDataSelector.isLoggedIn ? <div className="route__offer__recensions__add__content">
            <p className="route__offer__recensions__add__content__close" onClick={() => {
                props.onClose();
            }}>
                &#10005;
            </p>
            <p className="route__offer__recensions__add__content__head">
                {Podjelite} svoje mišljenje
            </p>

            <p className="route__offer__recensions__add__content__subhead">
                {Ocjenite} Vaše iskustvo *
            </p>
            <div className="route__offer__recensions__add__content__stars">
                {renderStars()}
            </div>

            <p className="route__offer__recensions__add__content__subhead" >
                Napišite recenziju *
            </p>
            <textarea ref={commentRef} placeholder="Recite nam šta vam se svidjelo ili nije svidjelo"></textarea>
            <p className="route__offer__recensions__add__content__subhead">
                Dodajte naslov *
            </p>
            <input ref={headRef} placeholder="Sumirajte svoje iskustvo" />

            <p className="route__offer__recensions__add__content__subhead">
                Dodajte privitak
            </p>
            <input type="file" onChange={onFileSelected} accept="image/*" />

            <div className="route__offer__recensions__add__content__footer" bis_skin_checked="1">
                <div className="route__offer__recensions__add__content__footer__checkbox" bis_skin_checked="1">
                    <input ref={checkboxRef} data-review-required="" type="checkbox" />
                    <span>Slažem se sa Uslovima korištenja *</span>
                </div>

                <div className="route__offer__recensions__add__content__footer__bottom" bis_skin_checked="1">
                    <div className="route__offer__recensions__add__content__footer__bottom__left" bis_skin_checked="1">
                        <span>* obavezna polja</span>
                        <span style={{textTransform: 'lowercase'}}>*Vaša recenzija će biti poslana moderaciji na pregled te pregledana u što kraćem i bržem roku</span>
                    </div>
                    <div className="route__offer__recensions__add__content__footer__bottom__right" bis_skin_checked="1">
                        <button onClick={leaveComment}>Pošalji</button>
                    </div>
                </div>
            </div>
            <p>{errorMsg}</p>
        </div> : 
            <div className="route__offer__recensions__add__content">
            <p className="route__offer__recensions__add__content__close" onClick={() => {
                props.onClose();
            }}>
                &#10005;
            </p>
            <p className="route__offer__recensions__add__content__subhead" style={{textAlign: 'center'}}>
                Molimo napravite račun ili se logujte u postojeći da bi ostavili recenziju
            </p>
        </div>}

    </div>
}



export default Offer;