import React from "react";
import "./index.scss";
import '@splidejs/react-splide/css';

import { useNavigate } from "react-router-dom";
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import animateModule from "../../modules/animateModule";
import { groups_images } from "../../modules/miscModule";
import { animateBox } from "../../modules/componentAnimation";

import rpcClientModule from "../../modules/rpcClientModule";
import CartModal from "../../components/CartModal";

import { socialMedia } from "../Footer";

import { useDispatch, useSelector } from "react-redux";

const Header = () => {
    const [offers, setOffers] = React.useState([]);
    const [mobileMenuOpen, setMobileMenuOpen] = React.useState();
    const [mobileStickyHeader, setMobileStickyHeader] = React.useState();
    const searchState = React.useState(false);
    const dropdownShopState = React.useState(false);

    const userDataSelector = useSelector(state => state?.userData ?? {});

    const curNavigate = useNavigate();
    const isPhone = window.innerWidth <= 1020 ? true : false;

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    const getOffers = () => {
        rpcClientModule({
            action: "call",
            method: "offers.f.getAll",
            args: {
                filters: [
                    {name: "ChildOffers", op: "eq", value: null}
                ]
            },
            callback: res => {
                if (res.status === "ok") setOffers(res.data);
            }
        });
    };

    const goToFirstOffer = () => {
        if (!offers?.length) return;

        animateNavigate(`/offers/${offers[0].Slog}`);
    };

    const navigateToProfile = p => {
        if (!socialMedia[p]) return;
        if (!socialMedia[p][rpcClientModule.country]) return;

        window.open(socialMedia[p][rpcClientModule.country], "_blank");
    };

    React.useEffect(() => {
        const handler = () => {
            if (window.scrollY > 150) {
                if (!mobileStickyHeader) setMobileStickyHeader(true);
            } else {
                if (mobileStickyHeader) setMobileStickyHeader(false);
            };
        };

        window.addEventListener("scroll", handler);

        return () => {
            try {
                window.removeEventListener("scroll", handler);
            } catch {};
        };
    }, [mobileStickyHeader]);
    React.useEffect(() => {
        getOffers();
    }, []);

    return <>
        <Header_search state={searchState} offers={offers} />

        {isPhone && <div className="component__header">
            <div className="component__header__banner" style={{fontSize: "10px"}}>BLACK FRIDAY EKSKLUZIVNA PONUDA SA 53% POPUSTA!</div>
            <div className="component__header__wrapPhone" style={{...(mobileStickyHeader ? {
                position: "fixed",
                top: 0,
                zIndex: 98,
                backgroundColor: "#fff",
                boxShadow: "0 0 6px 3px rgba(0,0,0,0.3)"
            } : {})}}>
                <img className="component__header__wrapPhone__menu" src="/images/menuIcon.svg" onClick={() => setMobileMenuOpen(m => !m)} />
                <img className="component__header__wrapPhone__logo" src="/images/logo.svg" onClick={() => animateNavigate("/")} />
                <div className="component__header__wrap__right__searchMenu">
                    <div className="component__header__wrap__right__search component__header__wrap__right__search--primary" style={{backgroundImage: `url("/images/searchIcon.svg")`}} onClick={() => searchState[1](true)}></div>
                    <div className="component__header__wrap__right__search" style={{backgroundImage: `url("/images/cart.svg")`}} onClick={() => animateBox(<CartModal navigate={curNavigate} />)}></div>
                </div>

                <div className={`component__header__wrapPhone__openMenu ${mobileMenuOpen ? "component__header__wrapPhone__openMenu--active" : ""}`}>
                    <div className="component__header__wrapPhone__openMenu__items">
                        <p className="component__header__wrapPhone__openMenu__items__item" onClick={(e) => {
                            e?.stopPropagation?.();
                            userDataSelector?.isLoggedIn ? animateNavigate("/account") : animateNavigate("/login");
                            setMobileMenuOpen(false);
                        }}>{userDataSelector?.isLoggedIn ? <><img src="./images/profileIcon_menu.svg"></img> <p>Profil</p> </> : <><img src="./images/profileIcon_menu.svg"></img> <p>Prijava</p> </>}</p>
                        <p className="component__header__wrapPhone__openMenu__items__item" onClick={(e) => {
                            e?.stopPropagation?.();
                            animateNavigate("/most-popular");
                            setMobileMenuOpen(false);
                        }}>
                            <img src="/images/star.svg" />
                            <span>Najpopularniji</span>
                        </p>
                        <Header_phoneCategories offers={offers} onClose={() => setMobileMenuOpen(false)} />
                    </div>
                    <div className="component__header__wrapPhone__openMenu__footer">
                        <img onClick={() => navigateToProfile("facebook")} src="/images/Social/facebook.svg" />
                        <img onClick={() => navigateToProfile("pinterest")} src="/images/Social/pinterest.svg" />
                        <img onClick={() => navigateToProfile("instagram")} src="/images/Social/instagram.svg" />
                        <img onClick={() => navigateToProfile("tiktok")} src="/images/Social/tiktok.svg" />
                        <img onClick={() => navigateToProfile("youtube")} src="/images/Social/youtube.svg" />
                    </div>
                </div>
            </div>
        </div>}

        {!isPhone && <div className="component__header">
            <div className="component__header__banner">BLACK FRIDAY EKSKLUZIVNA PONUDA SA 53% POPUSTA!</div>
            <div className="component__header__wrap">
                <div className="component__header__wrap__left">
                    <img src="/images/logo.svg" onClick={() => animateNavigate("/")} />
                    
                    <div className="component__header__wrap__left__items">
                        <p style={{fontWeight: 700}} onClick={goToFirstOffer}>Black Friday Ponuda <img src="/images/downArrow.svg" /></p>
                        <p onClick={(e) => {
                            e?.stopPropagation?.();
                            dropdownShopState[1](d => !d);
                        }}>Shop <img src="/images/downArrow.svg" /></p>
                        <p onClick={() => animateNavigate("/most-popular")}>Najpopularniji <img src="/images/downArrow.svg" /></p>
                        <p onClick={() => animateNavigate("/bundles")}>Paketi <img src="/images/downArrow.svg" /></p>
                        <p onClick={() => animateNavigate("/club")}>Klub Valnor <img src="/images/downArrow.svg" /></p>
                    </div>
                </div>
                <div className="component__header__wrap__right">
                    <div className="component__header__wrap__right__quizz" onClick={() => animateNavigate("/quiz")}>Započnite kviz</div>
                    <div className="component__header__wrap__right__account" onClick={(e) => {
                            e?.stopPropagation?.();
                            userDataSelector?.isLoggedIn ? animateNavigate("/account") : animateNavigate("/login");
                        }}>{userDataSelector?.isLoggedIn ? <img src="./images/profileIcon.svg"></img> : 'Prijava'}</div>
                    <div className="component__header__wrap__right__searchMenu">
                        <div className="component__header__wrap__right__search component__header__wrap__right__search--primary" onClick={() => searchState[1](true)}>
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M15 10.5C15 12.9853 12.9853 15 10.5 15C8.01472 15 6 12.9853 6 10.5C6 8.01472 8.01472 6 10.5 6C12.9853 6 15 8.01472 15 10.5ZM14.1793 15.2399C13.1632 16.0297 11.8865 16.5 10.5 16.5C7.18629 16.5 4.5 13.8137 4.5 10.5C4.5 7.18629 7.18629 4.5 10.5 4.5C13.8137 4.5 16.5 7.18629 16.5 10.5C16.5 11.8865 16.0297 13.1632 15.2399 14.1792L20.0304 18.9697L18.9697 20.0303L14.1793 15.2399Z" fill="#4F88B9"></path> </g></svg>
                        </div>
                        <div className="component__header__wrap__right__search" style={{backgroundImage: `url("/images/cart.svg")`}} onClick={() => animateBox(<CartModal navigate={curNavigate} />)}></div>
                    </div>
                </div>
            </div>
            {offers?.length > 0 && <Header_dropdownShop isOpen={dropdownShopState[0]} offers={offers} onClose={() => {
                if (dropdownShopState[0]) dropdownShopState[1](false);
            }} />}
        </div>}
    </>;
};

const Header_search = props => {
    const [offers, setOffers] = React.useState([]);
    const [search, setSearch] = React.useState("");

    const mainRef = React.useRef();
    const firstTimeRef = React.useRef(true);
    const curNavigate = useNavigate();

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    React.useLayoutEffect(() => {
        if (!mainRef.current) return;
        if (firstTimeRef.current) {
            firstTimeRef.current = false;
            return;
        };
        let banner = document.querySelector(".component__header__banner");
        let curTop = 37;
        if (banner) curTop = banner.getBoundingClientRect().height;

        if (props.state[0]) {
            mainRef.current.style.display = "flex";
            let cmp = getComputedStyle(mainRef.current);
            mainRef.current.animate([
                {opacity: cmp.opacity, top: cmp.top, height: cmp.height},
                {opacity: 1, top: `${curTop}px`, height: `calc(100% - ${curTop}px)`}
            ], {duration: 300, iterations: 1, fill: "both", easing: "ease"});
        } else {
            let cmp = getComputedStyle(mainRef.current);
            mainRef.current.animate([
                {opacity: cmp.opacity},
                {opacity: 0}
            ], {duration: 300, iterations: 1, fill: "both", easing: "ease"}).onfinish = () => {
                mainRef.current.style.display = null;
            };
        };
    }, [props.state[0]]);

    React.useEffect(() => {
        setOffers(props.offers);
    }, [props.offers]);

    return <div className="component__header__search" ref={mainRef}>
        <div className="component__header__search__top">
            <input type="text" placeholder="Search..." value={search} onChange={e => setSearch(e?.target?.value)} />
            <img src="/images/iconClose.svg" onClick={() => props.state[1](false)} />
        </div>
        <div className="component__header__search__bottom">
            <div className="component__header__search__bottom__searchWrap" style={{padding: search ? "10px" : null}}>{(()=>{
                if (!search) return null;
                let out = [];


                for (let offer of offers) {
                    if (search) {
                        if (!offer.Name.toLowerCase().includes(search.toLowerCase())) continue;
                    };

                    out.push(<div className="component__header__search__bottom__searchWrap__article" onClick={() => {
                        animateNavigate(`/offers/${offer.Slog}`);
                        props.state[1](false);
                        setSearch("");
                    }}>
                        <div className="component__header__search__bottom__searchWrap__article__left">
                            <img src={offer.Images[0] ?? "#"} />
                        </div>
                        <div className="component__header__search__bottom__searchWrap__article__right">
                            <p>{offer.Name}</p>
                            <span>{offer.NewPrice} {offer.Currency}</span>
                        </div>
                    </div>);
                };

                if (out.length > 0) {
                    return <>
                        <p>Artikli</p>
                        <div className="component__header__search__bottom__searchWrap__line"></div>
                        {out}
                    </>
                } else {
                    return <p>Nije pronađen ni jedan artikal</p>
                };
            })()}</div>
        </div>
    </div>
};

const Header_dropdownShop = props => {
    const [selectedGroup, setSelectedGroup] = React.useState();

    const curNavigate = useNavigate();
    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    const getGroups = () => {
        let out = [];
        for (let offer of props.offers) {
            if (!out.includes(offer.Group)) out.push(offer.Group);
        };

        return out;
    };
    const getArticlesForGroup = g => {
        let out = [];

        for (let item of props.offers) {
            if (item.Group === g) out.push(item);
        };

        return out;
    };

    React.useEffect(() => {
        let handler = () => {
            props.onClose();
        };

        window.addEventListener("click", handler);

        return () => {
            try {
                window.removeEventListener("click", handler);
            } catch {};
        };
    }, [props.onClose]);

    React.useEffect(() => {
        if (!selectedGroup) {
            let allGroups = getGroups();
            if (allGroups.length > 0) setSelectedGroup(allGroups[0]);
        };
    }, []);

    return <div className="component__header__dropdown" style={{
        maxHeight: props.isOpen ? "340px" : 0,
        padding: props.isOpen ? "20px" : null,
        boxShadow: props.isOpen ? "0 0 5px #00000080" : null
    }} onClick={e => e?.stopPropagation?.()}>
        <div className="component__header__dropdown__wrap">
            <div className="component__header__dropdown__wrap__left">
                <div className="component__header__dropdown__wrap__left__nav">
                    {getGroups().map(g => <p onMouseOver={() => setSelectedGroup(g)} className={selectedGroup === g ? "component__header__dropdown__wrap__left__nav__itemActive" : ""}>
                        <img src={groups_images[g] ?? "#"} />
                        <span>{g}</span>
                    </p>)}
                </div>
            </div>
            <div className="component__header__dropdown__wrap__right">
                {selectedGroup && <Splide aria-label={selectedGroup} hasTrack={false}>
                    <SplideTrack>
                        {getArticlesForGroup(selectedGroup).map(art => {
                            return <SplideSlide>
                                <div className="component__header__dropdown__wrap__right__slide" onClick={(e) => {
                                    e?.stopPropagation?.();
                                    animateNavigate(`/offers/${art.Slog}`);
                                    props.onClose();
                                }}>
                                    <img src={art.Images[0] ?? "#"} />
                                    <div>{art.Name}</div>
                                </div>
                            </SplideSlide>
                        })}
                    </SplideTrack>
                    <div className="splide__arrows splide__arrows--ltr">
                        <button className="splide__arrow splide__arrow--prev" aria-label="Previous slide" aria-controls="splide02-track">
                            <svg style={{transform: "rotate(360deg)"}} className="dropdownArrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 504C119 504 8 393 8 256S119 8 256 8s248 111 248 248-111 248-248 248zm116-292H256v-70.9c0-10.7-13-16.1-20.5-8.5L121.2 247.5c-4.7 4.7-4.7 12.2 0 16.9l114.3 114.9c7.6 7.6 20.5 2.2 20.5-8.5V300h116c6.6 0 12-5.4 12-12v-64c0-6.6-5.4-12-12-12z"></path></svg>
                        </button>
                        <button className="splide__arrow splide__arrow--next" aria-label="Next slide" aria-controls="splide02-track">
                            <svg style={{transform: "rotate(180deg"}} className="dropdownArrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 504C119 504 8 393 8 256S119 8 256 8s248 111 248 248-111 248-248 248zm116-292H256v-70.9c0-10.7-13-16.1-20.5-8.5L121.2 247.5c-4.7 4.7-4.7 12.2 0 16.9l114.3 114.9c7.6 7.6 20.5 2.2 20.5-8.5V300h116c6.6 0 12-5.4 12-12v-64c0-6.6-5.4-12-12-12z"></path></svg>
                        </button>
                    </div>
                </Splide>}
            </div>
        </div>
    </div>
};

const Header_phoneCategories = props => {
    const [offers, setOffers] = React.useState([]);

    const curNavigate = useNavigate();

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    const getGroups = () => {
        let out = [];
        for (let offer of offers) {
            if (!out.includes(offer.Group)) out.push(offer.Group);
        };

        return out;
    };
    const getArticlesForGroup = g => {
        let out = [];

        for (let item of offers) {
            if (item.Group === g) out.push(item);
        };

        return out;
    };

    const renderGroups = () => {
        let out = [];

        for (let g of getGroups()) {
            let articles = getArticlesForGroup(g);

            let totalMaxHeight = 306 * articles.length;
            totalMaxHeight += 20 * (articles.length-1);
            totalMaxHeight += 50;

            out.push(<p className="component__header__wrapPhone__openMenu__items__item" onClick={e => {
                e.currentTarget.nextSibling.classList.toggle("component__header__wrapPhone__openMenu__items__itemDropdown--active");
                if (e.currentTarget.nextSibling.style.maxHeight !== `${totalMaxHeight}px`) {
                    e.currentTarget.nextSibling.style.maxHeight = `${totalMaxHeight}px`;
                } else {
                    e.currentTarget.nextSibling.style.maxHeight = `0px`;
                };
            }}>
                <img src={groups_images[g] ?? "#"} />
                <span>{g}</span>
                <span className="component__header__wrapPhone__openMenu__items__item__plus">+</span>
            </p>);
            out.push(<div className="component__header__wrapPhone__openMenu__items__itemDropdown">
                {articles.map(art => {
                    return <div className="component__header__wrapPhone__openMenu__items__itemDropdown__article" onClick={() => {
                        animateNavigate(`/offers/${art.Slog}`);
                        props.onClose();
                    }}>
                        <img className="component__header__wrapPhone__openMenu__items__itemDropdown__article__image" src={art.Images[0] ?? "#"} />
                        <span>{art.Name}</span>
                        <img className="component__header__wrapPhone__openMenu__items__itemDropdown__article__btn" src="/images/side-menu-arrow.svg" />
                    </div>
                })}
            </div>);
        };
        
        return out;
    };

    React.useEffect(() => {
        setOffers(props.offers);
    }, [props.offers]);

    return renderGroups();
};

export default Header;