import React from "react";
import "./index.scss";

import rpcClientModule from "../../modules/rpcClientModule";

let text = '';

switch (rpcClientModule.country) {
    case 'BA':
        text = 'U Valnoru, naš cilj je redefinisati muško zdravlje i snagu kroz proizvode koji kombinuju kvalitet sa najboljim prirodnim sastojcima. Posvećeni smo unapređenju života muškaraca širom svijeta, pružajući rješenja za potenciju, zdravlje prostate, energiju i cjelokupnu vitalnost. Naša misija je osnažiti svakog muškarca da prigrli svoje zdravlje i snagu uz proizvode koji su djelotvorni, bezbjedni i napravljeni prema najvišim standardima. Pridružite nam se na ovom putovanju ka boljem zdravlju, gdje je svaki proizvod korak ka snažnijem, vitalnijem sebi.';
        break;
    case 'RS':
        text = 'U Valnoru, naš cilj je redefinisati muško zdravlje i snagu kroz proizvode koji kombinuju kvalitet sa najboljim prirodnim sastojcima. Posvećeni smo unapređenju života muškaraca širom sveta, pružajući rešenja za potenciju, zdravlje prostate, energiju i celokupnu vitalnost. Naša misija je osnažiti svakog muškarca da prigrli svoje zdravlje i snagu uz proizvode koji su delotvorni, bezbedni i napravljeni prema najvišim standardima. Pridružite nam se na ovom putovanju ka boljem zdravlju, gde je svaki proizvod korak ka snažnijem, vitalnijem sebi.';
        break;
    case 'HR':
        text = 'U Valnoru, naš cilj je redefinirati muško zdravlje i snagu kroz proizvode koji spajaju kvalitetu s najboljim prirodnim sastojcima. Posvećeni smo unapređenju života muškaraca diljem svijeta, pružajući rješenja za potenciju, zdravlje prostate, energiju i cjelokupnu vitalnost. Naša misija je osnažiti svakog muškarca da prihvati svoje zdravlje i snagu uz proizvode koji su učinkoviti, sigurni i napravljeni prema najvišim standardima. Pridružite nam se na ovom putovanju prema boljem zdravlju, gdje je svaki proizvod korak prema snažnijem, vitalnijem sebi.';
        break;
    case 'ME':
        text = 'U Valnoru, naš cilj je redefinisati muško zdravlje i snagu kroz proizvode koji spajaju kvalitet sa najboljim prirodnim sastojcima. Posvećeni smo unapređenju života muškaraca širom svijeta, pružajući rješenja za potenciju, zdravlje prostate, energiju i cjelokupnu vitalnost. Naša misija je osnažiti svakog muškarca da prigrli svoje zdravlje i snagu uz proizvode koji su djelotvorni, bezbjedni i napravljeni prema najvišim standardima. Pridružite nam se na ovom putovanju ka boljem zdravlju, gdje je svaki proizvod korak ka snažnijem, vitalnijem sebi.';
        break;
    default:
        text = 'U Valnoru, naš cilj je redefinisati muško zdravlje i snagu kroz proizvode koji kombinuju kvalitet sa najboljim prirodnim sastojcima. Posvećeni smo unapređenju života muškaraca širom svijeta, pružajući rješenja za potenciju, zdravlje prostate, energiju i cjelokupnu vitalnost. Naša misija je osnažiti svakog muškarca da prigrli svoje zdravlje i snagu uz proizvode koji su djelotvorni, bezbjedni i napravljeni prema najvišim standardima. Pridružite nam se na ovom putovanju ka boljem zdravlju, gdje je svaki proizvod korak ka snažnijem, vitalnijem sebi.';
        break;
}

const AboutUs = () => {
    return <div className="route__aboutUs">
        <p className="route__aboutUs__headline">Valnor - O Nama</p>
        <p className="route__aboutUs__basic">{text}</p>
    </div>
}

export default AboutUs