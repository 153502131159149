import React from "react";
import "./index.scss";

import axios from "axios";

import { animateBox } from "../../modules/componentAnimation";
import Spinner from "../../components/customComponents/Spinner";

import rpcClient from "../../modules/rpcClientModule";


let narudžbu = 'narudžbu';
let prije = 'prije';
let Sedmični = 'Sedmični';
let Sedmične = "Sedmične";
let sedmičnu = 'sedmičnu';
let sedmice = "sedmice";
let primijeniti = 'primijeniti';
let clanstvoCijena = '';
let obaviješteni = 'obaviješteni';
let djelimično = "djelimično";
let provjerite = 'provjerite';
let primjenjuje = "primjenjuje";

switch (rpcClient.country) {
    case 'BA':
        narudžbu = 'narudžbu';
        prije = 'prije';
        Sedmični = 'Sedmični';
        sedmičnu = 'sedmičnu';
        primijeniti = 'primijeniti';
        clanstvoCijena = '199 KM';
        obaviješteni = 'obaviješteni';
        Sedmične = "Sedmične";
        sedmice = "sedmice";
        djelimično = "djelimično";
        provjerite = 'provjerite';
        primjenjuje = "primjenjuje";
        break;
    case 'RS':
        narudžbu = 'porudžbinu';
        prije = 'pre';
        Sedmični = 'Nedeljni';
        sedmičnu = 'nedeljnu';
        primijeniti = 'primeniti';
        clanstvoCijena = '11950 RSD';
        obaviješteni = 'obavešteni';
        Sedmične = "Nedeljne";
        sedmice = "nedelje";
        djelimično = "delimično";
        provjerite = 'proverite';
        primjenjuje = "primenjuje";
        break;
    case 'HR':
        narudžbu = 'narudžbu';
        prije = 'prije';
        Sedmični = 'Sedmični';
        sedmičnu = 'sedmičnu';
        primijeniti = 'primijeniti';
        clanstvoCijena = '100 €';
        obaviješteni = 'obaviješteni';
        Sedmične = "Sedmične";
        sedmice = "sedmice";
        djelimično = "djelimično";
        provjerite = 'provjerite';
        primjenjuje = "primjenjuje";
        break;
    case 'ME':
        narudžbu = 'narudžbu';
        prije = 'prije';
        Sedmični = 'Sedmični';
        sedmičnu = 'sedmičnu';
        primijeniti = 'primijeniti';
        clanstvoCijena = '100 €';
        obaviješteni = 'obaviješteni';
        Sedmične = "Sedmične";
        sedmice = "sedmice";
        djelimično = "djelimično";
        provjerite = 'provjerite';
        primjenjuje = "primjenjuje";
        break;
    default:
        break;
}


const ClubAccess = () => {
    const [faqs, setFaqs] = React.useState([]);

    return <div className="route__clubAccess">
        <div className="route__clubAccess__head">
            <div className="route__clubAccess__head__text">
                <p className="route__clubAccess__head__text__head">Dobrodošli u Klub Valnor</p>
                <p className="route__clubAccess__head__text__text">Uz najveće popuste i najbolje nagrade, očigledno je zašto biste trebali postati član našeg kluba. Za samo {clanstvoCijena}, otključajte svoj puni potencijal s Valnorom – pogledajte kako ispod. </p>
                <button className="route__clubAccess__head__text__button" onClick={() => {
                    let tmp = document.querySelector(".route__clubAccess__join");
                    if (tmp) tmp.scrollIntoView({behavior: "smooth", block: "center"});
                }}>Pridruži se klubu</button>
            </div>
            <div className="route__clubAccess__head__image">
                <img src="/images/clubAccess.svg" />
            </div>
        </div>

        <div className="route__clubAccess__perks">
            <img className="route__clubAccess__perks__img" src="/images/swirl.png" />
            <p className="route__clubAccess__perks__head">Pogodnosti kluba Valnor</p>
            <p className="route__clubAccess__perks__subhead">Klub Valnor članstvo</p>

            <div className="route__clubAccess__perks__content">
                <div className="route__clubAccess__perks__content__item">
                    <img src="/images/Perks/1.png" />
                    <p>25% popusta na sve</p>
                    <span>Tačno ste pročitali - odobravamo 25% popusta na svaku {narudžbu}. Automatski se {primjenjuje} prilikom plaćanja, bez potrebe za kodovima.</span>
                </div>

                <div className="route__clubAccess__perks__content__item">
                    <img src="/images/Perks/2.png" />
                    <p>Besplatna dostava</p>
                    <span>Dobijte besplatnu dostavu na svaku {narudžbu}, svaki put.</span>
                </div>

                <div className="route__clubAccess__perks__content__item">
                    <img src="/images/Perks/3.png" />
                    <p>Rani Pristup Novim Proizvodima</p>
                    <span>Otključajte rani pristup novim proizvodima {prije} svih drugih. Nikada ne rizikujte da bude rasprodano! </span>
                </div>

                <div className="route__clubAccess__perks__content__item">
                    <img src="/images/Perks/4.png" />
                    <p>Ekskluzivni Prodajni Događaji</p>
                    <span>{Sedmični} ekskluzivni popusti za članove kluba.</span>
                </div>

                <div className="route__clubAccess__perks__content__item">
                    <img src="/images/Perks/5.png" />
                    <p>Povrat Novca</p>
                    <span>Članovi kluba dobijaju priliku za povrat novca tokom promotivnih događaja tokom godine.</span>
                </div>

                <div className="route__clubAccess__perks__content__item">
                    <img src="/images/Perks/6.png" />
                    <p>Besplatan Poklon pri Prijavi</p>
                    <span>Dobijte besplatan poklon kada se prijavite za Klub Valnor.</span>
                </div>

                <div className="route__clubAccess__perks__content__item">
                    <img src="/images/Perks/7.png" />
                    <p>{Sedmične} Nagradne Igre</p>
                    <span>Svake {sedmice} organizujemo nagradne igre s nagradama za članove Klub Valnor.</span>
                </div>
            </div>
        </div>

        <div className="route__clubAccess__perks">
            <img className="route__clubAccess__perks__img" src="/images/swirl.png" />
            <p className="route__clubAccess__perks__head">Dobrodošli u Klub</p>
            <p className="route__clubAccess__perks__subhead">Postoji toliko razloga koji jasno pokazuju zašto se trebate pridružiti klubu!</p>
        </div>

        <div className="route__clubAccess__join">
            <p className="route__clubAccess__join__head">Klub Valnor članstvo</p>
            <p className="route__clubAccess__join__price">{clanstvoCijena} godišnje</p>

            <p className="route__clubAccess__join__text">✓ 25% popusta na svaku pojedinačnu {narudžbu}.</p>
            <p className="route__clubAccess__join__text">✓ Besplatna dostava – bez minimalnog iznosa za kupovinu!</p>
            <p className="route__clubAccess__join__text">✓ Prvi pristup novim proizvodima i ukusima</p>
            <p className="route__clubAccess__join__text">✓ Ekskluzivni {Sedmični.toLocaleLowerCase()} popusti</p>
            <p className="route__clubAccess__join__text">✓ Ulaz u {sedmičnu} nagradnu igru</p>

            <button className="route__clubAccess__join__button" onClick={() => {
                animateBox(<ClubAccess__form />);
            }}>Pridruži se danas</button>
        </div>

        <div className="route__clubAccess__faq">
            <p className="route__clubAccess__faq__head">Da li trebam imati račun</p>
            <p className="route__clubAccess__faq__text">Da! Nakon što se prijavite, bit ćete upitani da kreirate račun ako ga već nemate.</p>

            <div className="route__clubAccess__faq__item">
                <div className="route__clubAccess__faq__item__top" onClick={() => {
                    setFaqs(f => {
                        if (f.includes(1)) {
                            return f.filter(ff => ff !== 1);
                        } else {
                            return [...f, 1];
                        };
                    })
                }}>
                    <p className="route__clubAccess__faq__item__top__text">Kako funkcioniše popust na proizvode?</p>
                    <span className="route__clubAccess__faq__item__top__plus">{faqs.includes(1) ? "-" : "+"}</span>
                </div>
                <div className={`route__clubAccess__faq__item__bottom ${faqs.includes(1) ? "route__clubAccess__faq__item__bottom--active" : ""}`}>
                    <p>Popust iz Kluba Valnor automatski će se {primijeniti} pri plaćanju - nije potreban kod!</p>
                </div>
            </div>

            <div className="route__clubAccess__faq__item">
                <div className="route__clubAccess__faq__item__top" onClick={() => {
                    setFaqs(f => {
                        if (f.includes(2)) {
                            return f.filter(ff => ff !== 2);
                        } else {
                            return [...f, 2];
                        };
                    })
                }}>
                    <p className="route__clubAccess__faq__item__top__text">Kako funkcioniše popust na proizvode?</p>
                    <span className="route__clubAccess__faq__item__top__plus">{faqs.includes(2) ? "-" : "+"}</span>
                </div>
                <div className={`route__clubAccess__faq__item__bottom ${faqs.includes(2) ? "route__clubAccess__faq__item__bottom--active" : ""}`}>
                    <p>Popust iz Kluba Valnor automatski će se {primijeniti} pri plaćanju - nije potreban kod!</p>
                </div>
            </div>

            <div className="route__clubAccess__faq__item">
                <div className="route__clubAccess__faq__item__top" onClick={() => {
                    setFaqs(f => {
                        if (f.includes(3)) {
                            return f.filter(ff => ff !== 3);
                        } else {
                            return [...f, 3];
                        };
                    })
                }}>
                    <p className="route__clubAccess__faq__item__top__text">Kako funkcioniše popust na dostavu?</p>
                    <span className="route__clubAccess__faq__item__top__plus">{faqs.includes(3) ? "-" : "+"}</span>
                </div>
                <div className={`route__clubAccess__faq__item__bottom ${faqs.includes(3) ? "route__clubAccess__faq__item__bottom--active" : ""}`}>
                    <p>Besplatna dostava iz Kluba Valnor automatski će se {primijeniti} pri plaćanju - nije potreban kod!</p>
                </div>
            </div>

            <div className="route__clubAccess__faq__item">
                <div className="route__clubAccess__faq__item__top" onClick={() => {
                    setFaqs(f => {
                        if (f.includes(4)) {
                            return f.filter(ff => ff !== 4);
                        } else {
                            return [...f, 4];
                        };
                    })
                }}>
                    <p className="route__clubAccess__faq__item__top__text">Da li je članarina stalna naknada?</p>
                    <span className="route__clubAccess__faq__item__top__plus">{faqs.includes(4) ? "-" : "+"}</span>
                </div>
                <div className={`route__clubAccess__faq__item__bottom ${faqs.includes(4) ? "route__clubAccess__faq__item__bottom--active" : ""}`}>
                    <p>Da, članovi plaćaju jednom godišnje za puni pristup našem programu članstva. Nema skrivenih troškova niti naknada za raskid!</p>
                </div>
            </div>

            <div className="route__clubAccess__faq__item">
                <div className="route__clubAccess__faq__item__top" onClick={() => {
                    setFaqs(f => {
                        if (f.includes(5)) {
                            return f.filter(ff => ff !== 5);
                        } else {
                            return [...f, 5];
                        };
                    })
                }}>
                    <p className="route__clubAccess__faq__item__top__text">Kako mogu otkazati članstvo?</p>
                    <span className="route__clubAccess__faq__item__top__plus">{faqs.includes(5) ? "-" : "+"}</span>
                </div>
                <div className={`route__clubAccess__faq__item__bottom ${faqs.includes(5) ? "route__clubAccess__faq__item__bottom--active" : ""}`}>
                    <p>Članstvo možete otkazati u bilo kojem trenutku putem vašeg portala za članove. Zadržat ćete svoje pogodnosti do kraja obračunskog perioda. Ne vraćamo novac ili kredite, uključujući {djelimično} korištene obračunske periode.</p>
                </div>
            </div>

            <div className="route__clubAccess__faq__item">
                <div className="route__clubAccess__faq__item__top" onClick={() => {
                    setFaqs(f => {
                        if (f.includes(6)) {
                            return f.filter(ff => ff !== 6);
                        } else {
                            return [...f, 6];
                        };
                    })
                }}>
                    <p className="route__clubAccess__faq__item__top__text">Kako postajem član?</p>
                    <span className="route__clubAccess__faq__item__top__plus">{faqs.includes(6) ? "-" : "+"}</span>
                </div>
                <div className={`route__clubAccess__faq__item__bottom ${faqs.includes(6) ? "route__clubAccess__faq__item__bottom--active" : ""}`}>
                    <p>Dodajte članstvo u košaricu s ove stranice i nastavite s plaćanjem! Zatim ćete biti upitani da kreirate račun ili se prijavite na postojeći.</p>
                </div>
            </div>

            <div className="route__clubAccess__faq__item">
                <div className="route__clubAccess__faq__item__top" onClick={() => {
                    setFaqs(f => {
                        if (f.includes(7)) {
                            return f.filter(ff => ff !== 7);
                        } else {
                            return [...f, 7];
                        };
                    })
                }}>
                    <p className="route__clubAccess__faq__item__top__text">Moje pogodnosti ne rade - zašto?</p>
                    <span className="route__clubAccess__faq__item__top__plus">{faqs.includes(7) ? "-" : "+"}</span>
                </div>
                <div className={`route__clubAccess__faq__item__bottom ${faqs.includes(7) ? "route__clubAccess__faq__item__bottom--active" : ""}`}>
                    <p>Da biste primili svoje pogodnosti, {provjerite} da li ste prijavljeni na isti e-mail kojim ste kupili članstvo.</p>
                </div>
            </div>

            <div className="route__clubAccess__faq__item">
                <div className="route__clubAccess__faq__item__top" onClick={() => {
                    setFaqs(f => {
                        if (f.includes(8)) {
                            return f.filter(ff => ff !== 8);
                        } else {
                            return [...f, 8];
                        };
                    })
                }}>
                    <p className="route__clubAccess__faq__item__top__text">Da li se članarina automatski obnavlja?</p>
                    <span className="route__clubAccess__faq__item__top__plus">{faqs.includes(8) ? "-" : "+"}</span>
                </div>
                <div className={`route__clubAccess__faq__item__bottom ${faqs.includes(8) ? "route__clubAccess__faq__item__bottom--active" : ""}`}>
                    <p>Da. Članovi plaćaju {clanstvoCijena} svake godine za pristup programu članstva. Članstvo će se automatski obnoviti dok ne odlučite otkazati. Članstvo možete otkazati u bilo kojem trenutku putem portala vašeg računa ili kontaktiranjem korisničke podrške. Nema naknada za raskid ili skrivenih troškova povezanih s otkazivanjem, ali sve kupovine članstva su konačne. Ako otkažete, vaše članstvo će ostati aktivno do kraja trenutnog obračunskog perioda.</p>
                </div>
            </div>

            <div className="route__clubAccess__faq__item">
                <div className="route__clubAccess__faq__item__top" onClick={() => {
                    setFaqs(f => {
                        if (f.includes(9)) {
                            return f.filter(ff => ff !== 9);
                        } else {
                            return [...f, 9];
                        };
                    })
                }}>
                    <p className="route__clubAccess__faq__item__top__text">Mogu li pauzirati svoje članstvo?</p>
                    <span className="route__clubAccess__faq__item__top__plus">{faqs.includes(9) ? "-" : "+"}</span>
                </div>
                <div className={`route__clubAccess__faq__item__bottom ${faqs.includes(9) ? "route__clubAccess__faq__item__bottom--active" : ""}`}>
                    <p>Ne, trenutno ne podržavamo pauziranje ili preskakanje članstva. Međutim, članstvo možete otkazati i ponovo se pridružiti u bilo kojem trenutku.</p>
                </div>
            </div>

            <div className="route__clubAccess__faq__item">
                <div className="route__clubAccess__faq__item__top" onClick={() => {
                    setFaqs(f => {
                        if (f.includes(10)) {
                            return f.filter(ff => ff !== 10);
                        } else {
                            return [...f, 10];
                        };
                    })
                }}>
                    <p className="route__clubAccess__faq__item__top__text">Kako ažurirati način plaćanja?</p>
                    <span className="route__clubAccess__faq__item__top__plus">{faqs.includes(10) ? "-" : "+"}</span>
                </div>
                <div className={`route__clubAccess__faq__item__bottom ${faqs.includes(10) ? "route__clubAccess__faq__item__bottom--active" : ""}`}>
                    <p>Način plaćanja možete ažurirati u bilo kom trenutku putem vašeg portala računa.</p>
                </div>
            </div>

            <div className="route__clubAccess__faq__item">
                <div className="route__clubAccess__faq__item__top" onClick={() => {
                    setFaqs(f => {
                        if (f.includes(11)) {
                            return f.filter(ff => ff !== 11);
                        } else {
                            return [...f, 11];
                        };
                    })
                }}>
                    <p className="route__clubAccess__faq__item__top__text">Kako mogu iskoristiti rani pristup za prodaje i lansiranja?</p>
                    <span className="route__clubAccess__faq__item__top__plus">{faqs.includes(11) ? "-" : "+"}</span>
                </div>
                <div className={`route__clubAccess__faq__item__bottom ${faqs.includes(11) ? "route__clubAccess__faq__item__bottom--active" : ""}`}>
                    <p>Bićete {obaviješteni} putem e-maila odvojeno od ostatka naših kupaca kako biste prvi saznali za sve veće rasprodaje i nove proizvode. Ne trebate ništa posebno učiniti osim otvaranja e-mailova od nas.</p>
                </div>
            </div>

        </div>
    </div>
};

const ClubAccess__form = props => {
    const [infoP, setInfoP] = React.useState({
        inputs: [],
        text: "test",
        hadError: false
    });
    const [spinner, setSpinner] = React.useState(false);

    const nameRef = React.useRef();
    const phoneRef = React.useRef();

    async function getCountry() {
        try {
            const response = await fetch('https://ipapi.co/json/');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            return data.country; // Vraća ime države
        } catch (error) {
            console.error('Greška prilikom dobijanja države:', error);
            return null;
        }
    }

    const sendForm = async () => {
        if (spinner) return;
        setInfoP(ip => {return {...ip, text: "", inputs: []}});

        let data = {
            FirstLastName: nameRef.current.value,
            PhoneNumber: phoneRef.current.value,
            ClubName: "Valnor",
            Country: null
        };

        if (!data.FirstLastName || !data.PhoneNumber) return setInfoP(ip => {return {...ip, hadError: true, text: "Molimo unesite Vaše podatke"}});
        try {
            let c = await getCountry();
            data.Country = c;
        } catch {
            return setInfoP(ip => {return {...ip, hadError: true, text: "Došlo je do greške"}});
        };
        if (!data.Country) return setInfoP(ip => {return {...ip, hadError: true, text: "Došlo je do greške"}});

        setSpinner(true);
        axios({
            method: "POST",
            url: `https://backend.scale-crm.com:5000/api/leads/clubJoin`,
            data
        }).then(res => {
            if (res.data.status === "ok") {
                alert(`${rpcClient.country === 'RS' ? 'Uspešno' : 'Uspješno'} ste se pridružili klubu!`);
                return props.onClose();
            } else {
                return setInfoP(ip => {return {...ip, hadError: true, text: "Došlo je do greške"}});
            };
        }).catch(() => {
            return setInfoP(ip => {return {...ip, hadError: true, text: "Došlo je do greške"}});
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="route__clubAccess__form">
        <div className="route__clubAccess__form__wrap">
            <div className="route__clubAccess__form__wrap__head">
                <div className="route__clubAccess__form__wrap__head__left">Pridruži se klubu</div>
                <div className="route__clubAccess__form__wrap__head__right" onClick={props.onClose}>
                    <img src="/images/iconClose.svg" />
                </div>
            </div>

            <div className="route__clubAccess__form__wrap__content">
                <img src="/images/clubAccess.svg" />

                <div className={`route__clubAccess__form__wrap__content__input ${infoP.inputs.includes("name") ? "route__clubAccess__form__wrap__content__input--error" : ""}`}>
                    <p>Ime i prezime</p>
                    <input ref={nameRef} type="text" />
                </div>
                <div className={`route__clubAccess__form__wrap__content__input ${infoP.inputs.includes("phone") ? "route__clubAccess__form__wrap__content__input--error" : ""}`}>
                    <p>Broj telefona</p>
                    <input ref={phoneRef} type="text" />
                </div>

                <button onClick={sendForm}>{spinner ? <Spinner style={{width: "32px", height: "32px"}} color="white" /> : "Prijavi se"}</button>

                {infoP.hadError && <p className="route__clubAccess__form__wrap__content__infoP">{infoP.text}</p>}
            </div>
        </div>
    </div>
};

export default ClubAccess;