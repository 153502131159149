import React from "react";
import "./index.scss";

import rpcClient from "../../modules/rpcClientModule";

import { useParams, useNavigate } from "react-router-dom";

import animateModule from "../../modules/animateModule";

let narudžbi = 'narudžbi';
let narudžba = "narudžba";
let narudžbe = "narudžbe";
let uspješno = "uspješno";

switch (rpcClient.country) {
    case 'BA':
        narudžbi = 'narudžbi';
        narudžba = "narudžba";
        narudžbe = "narudžbe";
        uspješno = "uspješno";
        break;
    case 'RS':
        narudžbi = 'porudžbini';
        narudžba = "porudžbina";
        narudžbe = "porudćbine";
        uspješno = "uspešno";
        break;
    case 'HR':
        narudžbi = 'narudžbi';
        narudžba = "narudžba";
        narudžbe = "narudžbe";
        uspješno = "uspješno";
        break;
    case 'ME':
        narudžbi = 'narudžbi';
        narudžba = "narudžba";
        narudžbe = "narudžbe";
        uspješno = "uspješno";
        break;
    default:
        break;
}

const Thankyou = () => {
    const curParams = useParams();
    const curNavigate = useNavigate();

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    return <div className="route__thankyou">
        <div className="route__thankyou__wrap">
            <img src="/images/logo.svg" />
            <h2>Hvala Vam na {narudžbi}!</h2>
            <p>Vaša {narudžba} je {uspješno} primljena. Naš tim će Vas kontaktirati u narednih 30-60 minuta.</p>
            <p>Iskoristite priliku i postavite nekoliko pitanja našem timu kako bi mogli što efikasnije iskoristiti naše proizvode!</p>

            <p style={{marginTop: "50px"}}>ID Vaše narudžbe je:</p>
            <p style={{fontWeight: 800, fontSize: "1.4rem"}}>{curParams?.id ?? "-"}</p>
            <p>Spremite ovaj ID za daljnje praćenje Vaše narudžbe. Vašu narudžbu također možete pratiti na Vašem profilu</p>
            <button onClick={() => animateNavigate("/account")}>Prati narudžbu</button>
        </div>
    </div>
};

export default Thankyou;