import React from "react";
import "./index.scss";

import rpcClientModule from "../../modules/rpcClientModule";

let text1 = '';
let text2 = '';
let text3 = '';
let text4 = '';
let text5 = '';
let text6 = '';
let text7 = '';
let text8 = '';

switch (rpcClientModule.country) {
    case 'BA':
        text1 = 'Svi žele osjećaj snage i vitalnosti – osjećaj energije, samopouzdanja i kontrole, sve izraženo kroz snažan stav i spokoj.';
        text2 = 'Zapitali smo se: zašto svi proizvodi koji navodno pomažu muškarcima da se osjećaju snažno i vitalno djeluju tako hladno, klinički i dosadno? Ovi zastarjeli proizvodi pretvaraju brigu o sebi u rutinsku obavezu.';
        text3 = 'Nije ni čudo što mnogi muškarci odustaju. Gdje je inspiracija u tome?';
        text5 = 'Na početku smo mislili da kreiramo vrhunske, temeljno istražene proizvode za poboljšanje muške vitalnosti. Ispostavilo se da pomažemo muškarcima svih doba da otkriju zdravlje, pravu snagu i mladalački duh u sebi. ';
        text6 = 'Preko 40.000 muškaraca';
        text7 = 'I JOŠ VIŠE';
        text8 = 'Više od 40.000 muškaraca pronalazi podršku i motivaciju u Valnor zajednici na Facebooku. Ispunjava nas ponosom – jer su u manje od dvije godine naši proizvodi korišćeni 25 miliona puta, pomažući muškarcima da osjete ono što svi želimo...';
        break;
    case 'RS':
        text1 = 'Svi žele osećaj snage i vitalnosti – osećaj energije, samopouzdanja i kontrole, sve izraženo kroz snažan stav i spokoj.';
        text2 = 'Zapitali smo se: zašto svi proizvodi koji navodno pomažu muškarcima da se osećaju snažno i vitalno deluju tako hladno, klinički i dosadno? Ovi zastareli proizvodi pretvaraju brigu o sebi u rutinsku obavezu.';
        text3 = 'Nije ni čudo što mnogi muškarci odustaju. Gde je inspiracija u tome?';
        text5 = 'Na početku smo mislili da kreiramo vrhunske, temeljno istražene proizvode za poboljšanje muške vitalnosti. Ispostavilo se da pomažemo muškarcima svih doba da otkriju zdravlje, pravu snagu i mladalački duh u sebi. ';
        text6 = 'Preko 40.000 muškaraca';
        text7 = 'I JOŠ VIŠE';
        text8 = 'Više od 40.000 muškaraca pronalazi podršku i motivaciju u Valnor zajednici na Facebooku. Ispunjava nas ponosom – jer su u manje od dve godine naši proizvodi korišćeni 25 miliona puta, pomažući muškarcima da osete ono što svi želimo...';
        break;
    case 'HR':
        text1 = 'Svi žele osjećaj snage i vitalnosti – osjećaj energije, samopouzdanja i kontrole, sve izraženo kroz snažan stav i spokoj.';
        text2 = 'Zapitali smo se: zašto svi proizvodi koji navodno pomažu muškarcima da se osjećaju snažno i vitalno djeluju tako hladno, klinički i dosadno? Ovi zastarjeli proizvodi pretvaraju brigu o sebi u rutinsku obavezu.';
        text3 = 'Nije ni čudo što mnogi muškarci odustaju. Gdje je inspiracija u tome?';
        text5 = 'Na početku smo mislili da kreiramo vrhunske, temeljno istražene proizvode za poboljšanje muške vitalnosti. Ispostavilo se da pomažemo muškarcima svih doba da otkriju zdravlje, pravu snagu i mladalački duh u sebi. ';
        text6 = 'Preko 40.000 muškaraca';
        text7 = 'I JOŠ VIŠE';
        text8 = 'Više od 40.000 muškaraca pronalazi podršku i motivaciju u Valnor zajednici na Facebooku. Ispunjava nas ponosom – jer su u manje od dvije godine naši proizvodi korišćeni 25 miliona puta, pomažući muškarcima da osjete ono što svi želimo...';
        break;
    case 'ME':
        text1 = 'Svi žele osjećaj snage i vitalnosti – osjećaj energije, samopouzdanja i kontrole, sve izraženo kroz snažan stav i spokoj.';
        text2 = 'Zapitali smo se: zašto svi proizvodi koji navodno pomažu muškarcima da se osjećaju snažno i vitalno djeluju tako hladno, klinički i dosadno? Ovi zastarjeli proizvodi pretvaraju brigu o sebi u rutinsku obavezu.';
        text3 = 'Nije ni čudo što mnogi muškarci odustaju. Gdje je inspiracija u tome?';
        text5 = 'Na početku smo mislili da kreiramo vrhunske, temeljno istražene proizvode za poboljšanje muške vitalnosti. Ispostavilo se da pomažemo muškarcima svih doba da otkriju zdravlje, pravu snagu i mladalački duh u sebi. ';
        text6 = 'Preko 40.000 muškaraca';
        text7 = 'I JOŠ VIŠE';
        text8 = 'Više od 40.000 muškaraca pronalazi podršku i motivaciju u Valnor zajednici na Facebooku. Ispunjava nas ponosom – jer su u manje od dvije godine naši proizvodi korišćeni 25 miliona puta, pomažući muškarcima da osjete ono što svi želimo...';
        break;
    default:
        break;
}

const OurStory = () => {
    return <div className="route__ourStory">
        <div className="route__ourStory__top">
            <img className="route__ourStory__top__marshmallos" src="./images/marshmallos.webp"></img>
            <div className="route__ourStory__top__inner">
                <img className="route__ourStory__top__inner__img" src="./images/nasaprica1.webp"></img>
                <div className="route__ourStory__top__inner__right">
                    <p>{text1}</p>
                    <p>{text2}</p>
                    <p>{text3}</p>
                    <p>{text4}</p>
                </div>
            </div>
        </div>
        <div className="route__ourStory__bot">
            <p className="route__ourStory__bot__normal">{text5}</p>
            <p className="route__ourStory__bot__head">{text6}</p>
            <p className="route__ourStory__bot__subhead">{text7}</p>
            <p className="route__ourStory__bot__normal">{text8}</p>
        </div>
    </div>
}

export default OurStory