import React from "react";
import "./index.scss";

import { useNavigate } from "react-router-dom";
import animateModule from "../../modules/animateModule";
import rpcClientModule from "../../modules/rpcClientModule";

let Lijepe = 'Lijepe';
let Njegu = "Njegu";
let Njega = "Njega";
let lijepo = "lijepo";
let zahtjeva = "zahtjeva";
let njegu = "njegu";
let njege = "njege";
let rješenje = "rješenje";
let djeluje = "djeluje";
let tijelu = "tijelu";
let porijeklom = "porijeklom";
let korijen = "korijen";
let vijekovima = "vijekovima";
let doprineti = "doprineti";
let osjećaju = "osjećaju";
let dijelu = "dijelu";
let tijela = "tijela";

switch (rpcClientModule.country) {
    case 'BA':
        Lijepe = 'Lijepe';
        Njegu = "Njegu";
        Njega = "Njega";
        lijepo = "lijepo";
        zahtjeva = "zahtjeva";
        njegu = "njegu";
        njege = "njege";
        rješenje = "rješenje";
        djeluje = "djeluje";
        tijelu = "tijelu";
        porijeklom = "porijeklom";
        korijen = "korijen";
        vijekovima = "vijekovima";
        doprineti = "doprinijeti";
        osjećaju = "osjećaju";
        dijelu = "dijelu";
        tijela = "tijela";
        break;
    case 'RS':
        Lijepe = 'Lepe';
        Njegu = "Negu";
        Njega = "Nega";
        lijepo = "lijepo";
        zahtjeva = "zahteva";
        njegu = "negu";
        njege = "nege";
        rješenje = "rešenje";
        djeluje = "deluje";
        tijelu = "telu";
        porijeklom = "poreklom";
        korijen = "koren";
        vijekovima = "vekovima";
        doprineti = "doprineti";
        osjećaju = "osećaju";
        dijelu = "delu";
        tijela = "tela";
        break;
    case 'HR':
        Lijepe = 'Lijepe';
        Njegu = "Njegu";
        Njega = "Njega";
        lijepo = "lijepo";
        zahtjeva = "zahtjeva";
        njegu = "njegu";
        njege = "njege";
        rješenje = "rješenje";
        djeluje = "djeluje";
        tijelu = "tijelu";
        porijeklom = "porijeklom";
        korijen = "korijen";
        vijekovima = "vijekovima";
        doprineti = "doprinijeti";
        osjećaju = "osjećaju";
        dijelu = "dijelu";
        tijela = "tijela";
        break;
    case 'ME':
        Lijepe = 'Lijepe';
        Njegu = "Njegu";
        Njega = "Njega";
        lijepo = "lijepo";
        zahtjeva = "zahtjeva";
        njegu = "njegu";
        njege = "njege";
        rješenje = "rješenje";
        djeluje = "djeluje";
        tijelu = "tijelu";
        porijeklom = "porijeklom";
        korijen = "korijen";
        vijekovima = "vijekovima";
        doprineti = "doprinijeti";
        osjećaju = "osjećaju";
        dijelu = "dijelu";
        tijela = "tijela";
        break;
    default:
        break;
}

const BlogKosa = () => {
    const curNavigate = useNavigate();

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    return <div className="route__blogKosa">
        <div className="route__blogKosa__wrap">
            <h1>Koji to biljni dodaci mogu poboljšati potenciju</h1>
            <p>Muška potencija je važan aspekt opšteg zdravlja, samopouzdanja i kvaliteta života. Sve veći broj muškaraca traži prirodne dodatke za poboljšanje seksualne funkcije, a biljni sastojci se često ističu kao sigurno i efikasno {rješenje}. U nastavku istražujemo biljne dodatke koji mogu pomoći u poboljšanju potencije.</p>

            <h2>1. Ginseng – Energija i Izdržljivost</h2>
            <p>Ginseng, posebno korejski crveni ginseng, poznat je po tome što poboljšava seksualnu funkciju i izdržljivost. Ovaj moćan biljni sastojak pomaže u boljem protoku krvi i jačanju energije, što doprinosi boljoj potenciji. Osim toga, ginseng {djeluje} kao adaptogen, smanjujući nivo stresa i omogućavajući {tijelu} da bolje reaguje na fizičke i mentalne izazove.</p>

            <h2>2. Maca – Biljka za Vitalnost</h2>
            <p>Maca {korijen} je {porijeklom} iz Perua i {vijekovima} se koristi kao prirodni afrodizijak. Bogata vitaminima, mineralima i amino kiselinama, maca je poznata po svojim svojstvima za povećanje libida i seksualne funkcije. Naučna istraživanja pokazuju da maca može poboljšati seksualnu želju i {doprineti} opštem {osjećaju} vitalnosti kod muškaraca.</p>

            <h2>3. Tribulus Terrestris – Podsticaj Testosterona</h2>
            <p>Tribulus terrestris je biljka koja je veoma popularna u tradicionalnoj medicini za povećanje libida i podršku proizvodnji testosterona. Tribulus {djeluje} tako što stimuliše lučenje luteinizirajućeg hormona, koji je ključan za proizvodnju testosterona. Povećanjem nivoa ovog hormona, tribulus može {doprineti} boljoj seksualnoj funkciji i izdržljivosti.</p>

            <img className="route__blogKosa__wrap__article" src="/images/alphapotent.png" />
            <button onClick={() => animateNavigate("/offers/alpha-potent")}>Naruči Alpha Potent</button>

            <h2>4. Ginkgo Biloba – Bolji Protok Krvi</h2>
            <p>Ginkgo biloba je poznata po svojoj sposobnosti da poboljša cirkulaciju, posebno u donjem {dijelu} {tijela}, što može značajno pomoći kod problema sa potencijom. Bolji protok krvi prema genitalijama doprinosi jačoj i dužoj erekciji. Uz to, ginkgo biloba je bogat antioksidansima koji podržavaju opšte zdravlje kardiovaskularnog sistema.</p>

            <h2>Podrška uz Valnor® Alpha Potent</h2>
            <p>Ako tražite prirodno {rješenje} koje kombinuje sve ove moćne sastojke, <b>Valnor® Alpha Potent</b> je odličan izbor. Ovaj klinički ispitan dodatak sadrži pažljivo odabrane sinergijske biljke poput suvog ekstrata africke šljive, piskavice i ginka, obogaćen vitaminima, formulisan za podršku potenciji, poboljšanje cirkulacije i povećanje energije.</p>
            <p><b>Iskoristite specijalni popust od 53%</b> i otkrijte sve prednosti Alpha Potenta za poboljšanje vaše potencije i opšte vitalnosti.</p>
            <br />
            <p><b>Zaključak</b></p>
            <p>Korišćenjem <b>Valnor® Alpha Potent</b>, koji sadrži sinergijske biljne ekstrakte, može efikasno poboljšati vašu potenciju ali i zdravlje i energiju. Održavanje potencije je korak ka zdravijem, srećnijem životu.</p>
            <p>Pridružite se Valnor zajednici i pronađite podršku na svakom koraku ka boljem zdravlju!</p>
        </div>
    </div>
};

export default BlogKosa;