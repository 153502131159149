import React from "react";
import "./index.scss";

import { useNavigate } from "react-router-dom";
import animateModule from "../../modules/animateModule";
import rpcClientModule from "../../modules/rpcClientModule";

import rpcClient from "../../modules/rpcClientModule";

import Spinner from "../../components/customComponents/Spinner";

let ocijenjene = '';
let lijekove = '';
let drzava = '';
let namijenjeni = '';
let liječenje = '';
let zamjena = '';
let savjet = '';
let korištenja = '';
let narudžbu = '';
let zamjenu = '';
let izlječenje = '';

switch (rpcClientModule.country) {
    case 'BA':
        ocijenjene = 'ocijenjene';
        lijekove = 'lijekove';
        drzava = 'BiH';
        namijenjeni = 'namijenjeni';
        liječenje = 'liječenje';
        zamjena = 'zamjena';
        savjet = 'savjet';
        korištenja = 'korištenja';
        narudžbu = 'narudžbu';
        zamjenu = 'zamjenu';
        izlječenje = 'izlječenje';
        break;
    case 'RS':
        ocijenjene = 'ocenjene';
        lijekove = 'lekove';
        drzava = 'RS';
        namijenjeni = 'namenjeni';
        liječenje = 'lečenje';
        zamjena = 'zamena';
        savjet = 'savet';
        korištenja = 'korišćenja';
        narudžbu = 'porudžbinu';
        zamjenu = 'zamenu';
        izlječenje = 'izlečenje';
        break;
    case 'HR':
        ocijenjene = 'ocijenjene';
        lijekove = 'lijekove';
        drzava = 'HR';
        namijenjeni = 'namijenjeni';
        liječenje = 'liječenje';
        zamjena = 'zamjena';
        savjet = 'savjet';
        korištenja = 'korištenja';
        narudžbu = 'narudžbu';
        zamjenu = 'zamjenu';
        izlječenje = 'izlječenje';
        break;
    case 'ME':
        ocijenjene = 'ocijenjene';
        lijekove = 'lijekove';
        drzava = 'CG';
        namijenjeni = 'namijenjeni';
        liječenje = 'liječenje';
        zamjena = 'zamjena';
        savjet = 'savjet';
        korištenja = 'korištenja';
        narudžbu = 'narudžbu';
        zamjenu = 'zamjenu';
        izlječenje = 'izlječenje';
        break;
    default:
        break;
}

export const socialMedia = {
    "facebook": {
        "BA": "https://www.facebook.com/valnorba/",
        "RS": "https://www.facebook.com/valnorrs/",
        "HR": "https://www.facebook.com/valnorhr/",
        "ME": "https://www.facebook.com/valnorme/"
    },
    "pinterest": {
        "BA": ""
    },
    "instagram": {
        "BA": "https://www.instagram.com/valnor.ba/",
        "RS": "https://www.instagram.com/valnor.rs/",
        "HR": "https://www.instagram.com/valnor.hr/",
        "ME": "https://www.instagram.com/valnor.me/"
    },
    "tiktok": {},
    "youtube": {
        "BA": ""
    }
};


const Footer = () => {
    const [offerGroups, setOfferGroups] = React.useState();

    const curNavigate = useNavigate();

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    const navigateToProfile = p => {
        if (!socialMedia[p]) return;
        if (!socialMedia[p][rpcClientModule.country]) return;

        window.open(socialMedia[p][rpcClientModule.country], "_blank");
    };

    React.useEffect(() => {
        rpcClientModule({
            action: "call",
            method: "offers.f.getAll",
            args: {
                filters: [
                    {name: "ChildOffers", op: "eq", value: null}
                ]
            },
            callback: res => {
                if (res.status !== "ok") return setOfferGroups([]);
                let g = [];
                for (let o of res.data) {
                    if (!g.includes(o.Group)) g.push(o.Group);
                };
                setOfferGroups(g);
            }
        });
    }, []);

    return <div className="component__footer">
        <div className="component__footer__first">
            <img src="/images/Social/instagram_black.svg" />
            Budite u toku. Pratite
            <span onClick={() => navigateToProfile("instagram")}>@Valnor</span>
        </div>
        <div className="component__footer__second">
            <img className="component__footer__second__logo" src="/images/footerLogo.svg" />

            <div className="component__footer__second__menu">
                <div>
                    <p className="component__footer__second__menu__head">Shop</p>
                    {offerGroups ? offerGroups.map(g => <span className="component__footer__second__menu__text" onClick={() => animateNavigate(`/shop/${g}`)}>{g}</span>) : <Spinner style={{width: "16px", height: "16px"}} color="white" />}
                </div>
                <div>
                    <p className="component__footer__second__menu__head">Naučite</p>
                    <span className="component__footer__second__menu__text" onClick={() => animateNavigate("/nasa-prica")}>Naša priča</span>
                    <span className="component__footer__second__menu__text" onClick={() => animateNavigate("/club")}>Pridružite se zajednici</span>
                    <span className="component__footer__second__menu__text" onClick={() => animateNavigate("/blog/mršavljenje")}>Blog</span>
                    {/* <span className="component__footer__second__menu__text">Recepti</span>
                    <span className="component__footer__second__menu__text">FAQ</span> */}
                </div>
                <div>
                    <p className="component__footer__second__menu__head">Podrška</p>
                    <span className="component__footer__second__menu__text" onClick={() => animateNavigate("/kontaktiraje-nas")}>Kontaktirajte nas</span>
                    <span className="component__footer__second__menu__text" onClick={() => animateNavigate("/o-nama")}>O nama</span>
                    <span className="component__footer__second__menu__text" onClick={() => animateNavigate("/kontaktiraje-nas")}>Karijere</span>
                    <span className="component__footer__second__menu__text" onClick={() => animateNavigate("/track")}>Pratite svoju {narudžbu}</span>
                    <span className="component__footer__second__menu__text" onClick={() => animateNavigate("/kontaktiraje-nas")}>Započnite povrat ili {zamjenu}</span>
                </div>
                <div>
                    <div className="component__footer__second__menu__social">
                        <span onClick={() => navigateToProfile("facebook")}><img src="/images/Social/facebook_white.png" /></span>
                        <span onClick={() => navigateToProfile("pinterest")}><img src="/images/Social/pinterest_white.png" /></span>
                        <span onClick={() => navigateToProfile("instagram")}><img src="/images/Social/instagram_white.png" /></span>
                        <span onClick={() => navigateToProfile("tiktok")}><img src="/images/Social/tiktok_white.png" /></span>
                        <span onClick={() => navigateToProfile("youtube")}><img src="/images/Social/youtube_white.png" /></span>
                    </div>
                    <p className="component__footer__second__menu__textLong">
                        Izjave na ovoj web stranici nisu ocijenjene od strane Agencije za lijekove i medicinska sredstva {drzava}. Ovi proizvodi nisu {namijenjeni} za dijagnostikovanje, {liječenje}, {izlječenje} ili prevenciju bilo koje bolesti. Informacije koje pruža ova web stranica ili ova kompanija nisu {zamjena} za individualni medicinski {savjet}.
                    </p>
                </div>
            </div>
            <div className="component__footer__second__tos">
                <span onClick={() => animateNavigate("/politika-dostave")}>Politika dostave</span>
                <span onClick={() => animateNavigate("/politika-povrata")}>Politika povrata novca</span>
                <span onClick={(e) => {
                    e.stopPropagation();
                    animateNavigate("/terms-of-use");
                }}>Uslovi {korištenja}</span>
                <span onClick={(e) => {
                    e.stopPropagation();
                    animateNavigate("/privacy-policy");
                }}>Politika privatnosti</span>
            </div>
            <div className="component__footer__second__copy">©2024 Valnor sva prava zadržana.</div>
        </div>
    </div>
};

export default Footer;